/* src/app/account/delete.component.css */
.content {
  padding: 20px;
}
.child-content {
  width: 95%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 20vh;
}
.text-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 11pt;
}
.center-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.login-button {
  color: white;
  background-color: #3744AF;
}
.delete-button {
  color: white;
  background-color: #3744AF;
}
.cancel-button {
  color: white;
  background-color: #8d8d8d;
}
.delete-confirm-button {
  color: white;
  background-color: #b11414;
}
/*# sourceMappingURL=delete.component.css.map */
