import { Pipe, PipeTransform } from "@angular/core";

// display link removes protocol and some other pretty formatting
@Pipe({
    name: 'displaylink',
    standalone: false
})
export class DisplaylinkPipe implements PipeTransform 
{
  transform(url: string): string 
  {
    if (url == null || url.length == 0) return "";

    // remove protocol information for displaying
    url = url.replace(/^\/\/|^.*?:(\/\/)?/, '');

    // remove slash from end
    url = url.replace(/\/$/, "");
    
    // remove www. from beginning
    url = url.replace(/^(www\.)/,""); 
    
    return url;
  }
}

// clickable weblink should have the protocol
@Pipe({
    name: 'weblink',
    standalone: false
})
export class WeblinkPipe implements PipeTransform 
{
  transform(url: string): string 
  {
    if (url == null || url.length == 0) return "";

    // prepend protocol for the link if it's missing
    if (url.startsWith("http") == false)
    {
        url = "https://" + url;
    }

    return url;
  }
}