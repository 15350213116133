import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

// services
import { Tag } from './services/tag';
import { MarketService } from './services/market.service';
import { MarketAuth } from './services/marketauth.service';
import { MetaService } from './services/meta.service';

@Component({
    selector: 'market-category',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.css'],
    standalone: false
})

export class TagComponent
{
  // information displayed in html
  title: string;                 // optional title displayed at top of page
  subtitle: string;              // optional subtitle displayed at top of page
  avatar : string;               // optional avatar displayed at top of page
  description: string;           // optional description displayed at top of page
  webLink : string;              // optional web link to follow subtitle
  displayLink : string;          // optional web link for displaying (requires webLink)

  // following / flag support
  followTag : string;            // tag to follow when hearting a category or user
  isFollowed = false;            // true if subscribed to topic (local check)
  
  // tag to display
  tagObj: Tag;
  tag : string; 

  constructor(public marketService: MarketService,
    protected marketAuth: MarketAuth,
    private metaService: MetaService,
    //private messagingService: MessagingService,
    //private router: Router,
    private route: ActivatedRoute)
  {
    // no longer needed right because GO server correctly handles this initial meta data
    //const title = "Sounds by Category";
    //const description = "Download categories of sounds to White Noise for free.";
    //const image = "";
    //this.metaService.updateMetadata(title, description, image);

    // parse tag from route
    this.route.params.subscribe((params: Params) => { 
      this.setTag(params['tag'] as string) });
  }

  // called after ngInit
  private userSubscription: any; // keep track of observable so it can be removed later
  public ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(() => this.refreshFollow());
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) 
    {
      this.userSubscription.unsubscribe();
    }
  }

  public setTag(tag: string)
  {
    console.log("Tag Component is requesting tag: " + tag);
    this.tag = tag;
    this.followTag = tag; // categories use tag value for flags
    
    // setup title
    this.marketService.getTag(tag)
        .then( tag => this.setupTag(tag));
  }

  private setupTag(tag : Tag): void
  {
    this.tagObj = tag;
    this.title = tag.Label + " Category";
    this.avatar = tag.ImageUrl;

    const title = this.title;
    var description = tag.Label + " category for White Noise Market sounds.";
    if (tag.Description && tag.Description.length > 0)
    {
      // add to the meta description
      this.subtitle = tag.Description;
      description += " " + tag.Description;
    }
    else
    {
      // add sub title
      this.subtitle = "White Noise Market sounds in the " + tag.Label + " category";
    }

    // add additional info for description meta
    description += " Download these sounds to the White Noise app for free.  Official Market for White Noise Sounds by TMSOFT.";

    // update title and meta data
    this.metaService.updateTitle(title);
    this.metaService.updateDescription(description);

    // sync follow state when tag is loaded
    this.refreshFollow();
  }

  refreshFollow() 
  {
    if (!this.tagObj) 
    {
      // tag is not finished setting up
      return;
    }
    this.isFollowed = this.marketService.isFollowed(this.tagObj, 2);
  }

  onFollow(tag: Tag) 
  {
    this.marketService.toggleFollow(tag, 2)
        .then( (result) => this.isFollowed = Boolean(result.Value))
  }
}
