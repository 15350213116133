/* src/app/avatar/avatars.component.css */
.avatar-select-img {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px;
  outline: blue;
  cursor: pointer;
}
.avatars-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/*# sourceMappingURL=avatars.component.css.map */
