<mat-card appearance="outlined">
	<mat-card-header>
  		<mat-card-title><strong>MP3 Store</strong></mat-card-title>
		<mat-card-subtitle>Browse sounds available for MP3 purchase, manage your cart, or review your orders.</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="this.tmapiService.isStoreEnabled()" class="store-buttons">			
			<button mat-stroked-button routerLink="/store" routerLinkActive="active" title="Browse Purchaseable Sounds">Catalog</button>
			<button mat-flat-button routerLink="/store/checkout" routerLinkActive="active" title="View your cart or checkout" color="primary">Cart &amp; Checkout</button>
			<button mat-stroked-button routerLink="/store/orders" routerLinkActive="active" title="View orders and downloads">Orders &amp; Downloads</button>			
		</div>
	</mat-card-content>
</mat-card>

<!-- loading content progress bar-->
<mat-progress-bar mode="indeterminate" *ngIf="this.working"></mat-progress-bar>

<!-- store is disabled-->
<div class="content" *ngIf="this.statusMessage && this.statusMessage.length > 0">
	<div class="child-content">
		<div class="center-container">
			<p>{{this.statusMessage}}</p>
		</div>
	</div>
</div>

<!-- store is enabled-->
<div class="content" *ngIf="this.tmapiService.isStoreEnabled()">
	<div class="child-content" *ngIf="!working && empty">
		<div class="center-container">
			<p>Your cart is empty.</p>
			<p><a routerLink="/store">Browse</a> sounds available for MP3 purchase.</p>
		</div>
	</div>

	<div class="child-content" *ngIf="!working && !empty">

		<div class="table-container">

			<table mat-table [dataSource]="cartData.items">
				<!-- Name Column -->
				<ng-container matColumnDef="name">
				  <th mat-header-cell *matHeaderCellDef class="cart-hdr-cell">Name</th>
				  <td mat-cell class="title-cell" *matCellDef="let sound"><img src="{{sound.ImageUrl}}" class="cart-item-image" />{{sound.Label}}</td>
				  <td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- duration Column -->
				<ng-container matColumnDef="duration">
					<th mat-header-cell *matHeaderCellDef class="cart-hdr-cell">  Duration  </th>
					<td mat-cell *matCellDef="let sound">{{this.tmapiService.getFileInfo(sound)}}</td>
					<td mat-footer-cell *matFooterCellDef class="cart-hdr-cell">Total</td>
				</ng-container>

				<!-- price Column -->
				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef class="cart-hdr-cell">Total</th>
					<td mat-cell *matCellDef="let sound">{{sound.DisplayTotal}}</td>
					<td mat-footer-cell *matFooterCellDef class="cart-hdr-cell">
						<span *ngIf="this.cartData.promoValue"><del>{{getItemTotal()}}</del> {{getTotal()}}</span>
						<span *ngIf="!this.cartData.promoValue">{{getTotal()}}</span>
					</td>
				</ng-container>

				<!-- remove Column -->
				<ng-container matColumnDef="remove">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let sound"><button mat-icon-button (click)="onRemove(sound.Uid)"><mat-icon color="warn">cancel</mat-icon></button></td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnIds" class="cart-hdr"></tr>
				<tr mat-row *matRowDef="let row; columns: columnIds;" class="details-row"></tr>
				<tr mat-footer-row *matFooterRowDef="columnIds; sticky:false;" class="cart-hdr"></tr>
			</table>
		</div>

		<div class="promo-container">
			<div class="promo-info" *ngIf="!this.promoError && this.cartData.promoValue">
				<mat-chip-listbox aria-label="">
					{{getDiscount()}} OFF with code:
					<mat-chip-option color="primary" selectable="false" removable="true" (removed)="onPromoRemove()">
						{{this.cartData.promoCode}}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip-option>
				</mat-chip-listbox>
			</div>
			<div class="promo" *ngIf="!this.cartData.promoCode && !this.cartData.promoValue">
				<mat-form-field style="margin-right:10px;">
					<input matInput placeholder="Promo Code:" value="" [(ngModel)]="this.promoCode" (input)="onPromoInput()" maxlength="20">
				</mat-form-field>
				<button mat-flat-button color="primary" (click)="onPromoApply()" [disabled]="this.promoWorking">Apply</button>
				<br />
				<div class="promo-error" *ngIf="this.promoError">
					<span>{{this.promoError}}</span>
				</div>
			</div>
		</div>
		<div class="checkout-container">
			<div *ngIf="!authorized">
				<p>Login to purchase.</p>
				<button mat-button class="blue-button" (click)="marketAuth.showLogin()">Login</button>
			</div>
			<div *ngIf="authorized">
				<p>By placing your order, you agree to the <a [routerLink]="[]" (click)="onTerms()">Personal Use License Agreement</a>.</p>
			</div>
			<br />
			<div id="paypal-container" *ngIf="authorized">
				<ngx-paypal [config]="payConfig"></ngx-paypal>
			</div>
		</div>
	</div>
</div>