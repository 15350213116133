import { Component, Input, OnInit } from '@angular/core';
import { MarketService } from '../market.service';
import { MarketAuth } from '../marketauth.service';
import { Setting } from './setting';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AvatarsComponent } from '../avatar/avatars.component';

// used to track various states of a setting for UI feedback
export class SettingState
{
  Setting:       Setting;
  OriginalValue: string;
  Checked:       boolean;
  Busy:          boolean;
  Status:        string;
  Error:         string;
  Dirty:         boolean;
  FormControl:   FormControl;
}

@Component({
  selector: 'account-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {

  saving = false;
  saveMsg = "";
  saveError = "";

  // when footer is enabled, save / reset buttons are displayed at the bottom
  // and this also enables saving in form mode, which is all changed values at once
  footer = false;

  @Input() user: any = null;

  _settings: SettingState[] = [];
  get settings(): SettingState[] { 
    return this._settings; 
  }

  @Input() set settings(settings: Setting[]) {

    this._settings = [];
    settings.forEach(setting => 
    {
      let state = new SettingState();
      state.Setting = setting;
      state.OriginalValue = setting.Value;
      state.Busy = false;
      state.Status = "";
      state.Error = "";
      state.Dirty = false;
      state.Checked = setting.Value === "true";

      // setup form controls
      state.FormControl = new FormControl({value: setting.Value, disabled: false});
      state.FormControl.valueChanges.subscribe(value => this.onChange(state, value));

      this.checkErrors(state);
      this._settings.push(state);
    });
  }
  
  constructor(
    public market: MarketService,
    private auth: MarketAuth,
    private dialog: MatDialog) 
  { 
    // no-op
  }

  ngOnInit()
  {
    // no-op
  }

  ngOnDestroy()
  {
    // no-op
  }

  onAvatar(st: SettingState): void
  {
    let ref = this.dialog.open(AvatarsComponent, { 
      width: "75vh", 
      height: "90vh", 
      data: this.user
    });

    ref.afterClosed()
      .subscribe(result => {
        //console.log("Avatar dialog result: ", result);
        if (result) {
          this.onChange(st, result);
          if (!this.footer) {
            this.onSave(st);
          }
        }
      });
  }

  onChange(st: SettingState, value: string)
  {
    //console.log("onChange: ", st.Setting.Name, value);
    st.Setting.Value = String(value);
    st.Checked = st.Setting.Value === "true";

    // hack to convert "null" number values to 0 since the input doesn't handle it
    if (st.Setting.Type === "number") {
      st.Setting.Value = String(Number(value));
    }

    st.Dirty = st.Setting.Value !== st.OriginalValue;
    st.Busy = false;
    st.Status = "";
    this.checkErrors(st);
    this.saveMsg = "";
  }

  onSave(st: SettingState): void
  {
    // ignored if footer save button is enabled
    if (this.footer)  {
      return;
    }

    console.log("onSave: ", st.Setting);
    st.Busy = true;
    st.Status = "Saving...";

    this.market.putUserSetting(st.Setting)
      .then(() => {
        st.Busy = false;
        st.Status = "Saved!";
        this.updateUserIfNeeded(st.Setting);

        // refreshes the profile
        //this.auth.updateLoginState();
      })
      .catch(error => {
        console.error("Error saving setting: ", st.Setting, error);
        st.Busy = false;
        st.Status = "";
        st.Error = error;
        st.FormControl.setErrors(st.Error ? { 'invalid': true } : null);
      });
  }

  onSaveDirty(): void
  {
    // ignored if footer save button is disabled
    if (!this.footer) {
      return;
    }
    const dirty = this.settings
      .filter(st => st.Dirty)
      .map(st => st.Setting);

    if (dirty.length == 0) {
      return;
    }

    const error = this.settings
      .filter(st => st.Dirty)
      .some(st => st.Error.length > 0);
    
    if (error) {
      this.saving = false;
      this.saveError = "Error: Please correct the invalid settings";
      return;
    }

    this.saving = true;
    this.saveMsg = "Saving...";
    this.saveError = "";
    this.market.putUserSettings(dirty)
        .then(() => {
          this.settings.forEach(st => {
            st.OriginalValue = st.Setting.Value;
            st.Dirty = false
            st.Busy = false;
            st.Error = "";
            this.checkErrors(st);
            this.updateUserIfNeeded(st.Setting);
          });

          this.saving = false;
          this.saveMsg = "Settings updated!";
          this.saveError = "";
        })
        .catch(error => this.handleError(error));
  }

  updateUserIfNeeded(st: Setting): void {
    if (this.user) {
      if (st.Name === "UserAvatarUrl") {
        this.setUserValueIfChanged("avatarUrl", st.Value);
      } else if (st.Name === "UserName") {
        this.setUserValueIfChanged("displayName", st.Value);
      } else if (st.Name === "UserDescription") {
        this.setUserValueIfChanged("userDescription", st.Value);
        this.user.userDescription = st.Value;
      } else if (st.Name === "UserWebsite") {
        this.setUserValueIfChanged("userWebsite", st.Value);
      }
    }
  }

  setUserValueIfChanged(key: string, value: string): void {
    // update user from /me profile
    if (this.user[key] !== value) {
      this.user[key] = value;
    }

    // also update global auth user
    let authUser: any = this.auth.getUser();
    if (authUser && authUser[key] !== value) {
      authUser[key] = value;
    }
  }

  onReset(): void {
    // resets all dirty settings back to original values and then clear dirty list
    this.settings.forEach(st => {
      st.FormControl.setValue(st.OriginalValue);

      this.saveMsg = "";
      this.saveError = "";
      this.checkErrors(st);
    });
  }

  getAvatarUrl(st: SettingState): string {

    let avatarUrl = st.Setting.Value;
    if (avatarUrl == "") {
      avatarUrl = this.user.avatarUrl;
    }

    if (avatarUrl == "") {
      avatarUrl = "/assets/images/default_user.jpg";
    }

    return avatarUrl;
  }

  isDirty(): boolean {
    return this.settings.some(st => st.Dirty);
  }

  getOptions(st: SettingState): string[]
  {
    if (st.Setting.Options) {
      return st.Setting.Options.split(",");
    }

    return [];
  }

  private checkErrors(st: SettingState): void {
    st.Error = this.getError(st);
    st.FormControl.setErrors(st.Error ? { 'invalid': true } : null);
    
    // check if form should be disabled on zen requirement
    if (this.user && st.Setting.Zen > 0) {
      if (this.user.stats.zen < st.Setting.Zen) {
        st.FormControl.disable({ emitEvent: false });
      }
    }
  }

  private getError(st: SettingState): string {

    if (this.user && st.Setting.Zen > 0) {
      if (this.user.stats.zen < st.Setting.Zen) {
        return `Requires ${st.Setting.Zen.toLocaleString("en-US")} Zen`;
      }
    }

    if (st.Setting.Regex && st.Setting.Regex.length > 0) {
      let re = new RegExp(st.Setting.Regex);
      if (!re.test(st.Setting.Value)) {
        return `Invalid value`;
      }
    }

    if (st.Setting.Type === "text") {

      if (st.Setting.Value.length < st.Setting.Min) {
        return `Value must be a minimum of ${st.Setting.Min} characters`;
      }
      if (st.Setting.Value.length > st.Setting.Max) {
        return `Value must be no greater than ${st.Setting.Max} characters`;
      }
    }

    if (st.Setting.Type === "number" || st.Setting.Type === "range") {
      if (Number(st.Setting.Value) < st.Setting.Min) {
        return `Value must be no less than ${st.Setting.Min}`;
      }
  
      if (Number(st.Setting.Value) > st.Setting.Max) {
        return `Value must be no greater than ${st.Setting.Max}`;
      }
    }

    if (st.Setting.Type === "list") {
      let opts = st.Setting.Options.split(",");
      if (opts.indexOf(st.Setting.Value) < 0) {
        return `Invalid value selected`;
      }
    }

    // no errors found
    return "";
  }

  handleError(error: any) : void
  {
    this.saving=false;
    this.saveMsg = "";
    this.saveError = "There was a problem saving the settings. Please try again later.";
    console.error("ERROR: ", error);
  }
}