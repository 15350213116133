import { Component } from '@angular/core';

// services
import { MetaService } from './services/meta.service';

@Component({
    selector: 'market-download',
    templateUrl: './download.component.html',
    styleUrls: ['./content.css', './download.component.css'],
    standalone: false
})

// Native App Download Component
export class DownloadComponent
{
  constructor(private metaService: MetaService)
  {
    const title = "Native App for iOS & Android";
    const description = "Download the White Noise Market App and experience the social network of sounds on your Android or iOS device.  Available from the Apple App Store, Google Play Store, and Amazon Appstore.";
    const image = "https://img.tmsoft.com/market/app.jpg";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}
