import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarketService } from '../services/market.service';

interface AvatarData {
  avatars: any[];
  sounds: any[];
}

@Component({
    selector: 'market-avatars',
    templateUrl: './avatars.component.html',
    styleUrls: ['./avatars.component.css'],
    standalone: false
})

export class AvatarsComponent implements OnInit
{
    title = "Avatars";
    subtitle = "White Noise Market User Avatars"
    avatar = "/assets/images/default_user.jpg";
    working = false;
    tab: number = 0;
    data: AvatarData = null;

    constructor(private market: MarketService,
                @Inject(MAT_DIALOG_DATA) public user: any,
                private ref: MatDialogRef<AvatarsComponent>)
    {}

    ngOnInit(): void 
    {
      this.working = true;
      this.market.getAvatars(this.user.userId)
        .then(data => this.setData(data))
        .catch(err => this.setError(err));
    }

    setData(data: AvatarData)
    {
      this.working = false;
      this.data = data;
    }

    getData()
    {
      var data;
      if (this.tab == 0) data = this.data.avatars;
      else if (this.tab == 1) data = this.data.sounds;

      if (!data) data = [];
      return data;
    }

    onTabChange(event: any) : void
    {
      console.log("Tab changed to: ", event);
      this.tab = event.index;
    }

    /*
    setAvatar(url : string)
    {
      let options = {
        data: {
          title: "Change Avatar",
          message: "Do you want this image to be your avatar?",
        }
      };

    this.dialog.open(ConfirmDialog, options)
               .afterClosed()
               .subscribe(result => {
                    if (!result) return;

                    console.info("Set Avatar to URL: " + url)
                    var config = {};
                    config["userAvatarUrl"] = url;
                    console.log("Set user configuration:", config);

                    this.market.setUserConfig( config )
                               .then(result => {
                                   console.log("Set config result: " , result);

                                   // refresh user profile
                                   this.auth.updateLoginState();
                               })
                               .catch(err => this.setError(err));
                });

    }
    */

    onAvatar(url: string): void 
    {
      this.ref.close(url);
    }

    onCancel(): void
    {
      // pass null for no selection
      this.ref.close(null);
    }

    avatarError(image : any): void
    {
      image.onerror = null;
      image.src = "/assets/images/default_user.jpg";
    }

    setError(err: any)
    {
      console.error("Error during query!", err);
      this.working = false;
    }
}

