<mat-toolbar color="primary">
  <button mat-button (click)="sidenavToggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <button mat-button routerLink="/" routerLinkActive="active" title="White Noise Market">
    <img src="./assets/images/white-noise-icon.png" width="32" height="32" class="material-icons">
    <span class="app-title hide-lt-700">{{title}}</span>
    <span class="app-title show-lt-700 hide-lt-538">{{label}}</span>
  </button>
  <span class="hide-lt-440">
    <button mat-button routerLink="/app" routerLinkActive="active" title="Download White Noise Market App">
      <mat-icon>phone_android</mat-icon>
      <span class="hide-lt-960"> App</span>
    </button>
    <button mat-button routerLink="/chatter" routerLinkActive="active" title="User Chatter">
      <mat-icon>chat</mat-icon>
      <span class="hide-lt-960"> Chatter</span>
    </button>
    <button mat-button routerLink="/map" routerLinkActive="active" title="World Map of Sounds">
      <mat-icon>place</mat-icon>
      <span class="hide-lt-960"> Map</span>
    </button>
    <button mat-button routerLink="/users" routerLinkActive="active" title="Top User Leadboard">
      <mat-icon>supervised_user_circle</mat-icon>
      <span class="hide-lt-960"> Users</span>
    </button>
    <button mat-button routerLink="/search" routerLinkActive="active" title="Search Sounds">
      <mat-icon>search</mat-icon>
      <span class="hide-lt-960"> Search</span>
    </button>
  </span>

  <span class="app-toolbar-filler"> </span>

  <span>
    <button mat-button routerLink="/store" routerLinkActive="active" title="MP3 Store">
      <mat-icon>shopping_cart</mat-icon>
      <!--<span class="hide-lt-960"> Store </span>-->
      <span class="number-badge" *ngIf="!this.tmapiService.isCartEmpty()">{{this.tmapiService.cartCount()}}</span>
    </button>
  </span>

  <span>
    <!-- User Logged In -->
    <span *ngIf="this.marketAuth.user | async; let user; else noUser">
      <button mat-button routerLink="/me" >
        <app-avatar [url]="user.avatarUrl" [size]=32 [uid]="user.userId" [description]="user.displayName" [isAdmin]="user.isAdmin" [isSubscriber]="user.isSubscriber" [isModerator]="user.isModerator"></app-avatar>        
        <span class="hide-lt-440" style="vertical-align: middle;"> {{ user.displayName }}</span>
      </button>
      <button *ngIf="user.isAdmin" mat-button (click)="marketService.openAdminSite()" class="login-button">
          <mat-icon class="material-lock" fontIcon="lock_outline" aria-hidden="true"></mat-icon>
      </button>
    </span>
    <!-- User Not Logged In -->
    <ng-template #noUser>
        <button mat-button (click)="marketAuth.showLogin()" class="login-button">
          <mat-icon>account_circle</mat-icon>
          <span class="hide-lt-700">Login</span>
        </button>
    </ng-template>

    <!-- Overflow Menu (always visible and configured below) -->
    <button mat-button [matMenuTriggerFor]="toolbarMenu">
        <!-- <i class="material-icons">more_vert</i> -->
         <mat-icon>more_vert</mat-icon>
    </button>
  </span>

  <mat-menu #toolbarMenu="matMenu">
    <span class="show-lt-440">
      <button mat-menu-item routerLink="/app" routerLinkActive="active">
        <mat-icon class="material-icons" fontIcon="phone_android"></mat-icon>App
      </button>
      <button mat-menu-item routerLink="/chatter" routerLinkActive="active">
        <mat-icon class="material-icons" fontIcon="chat" aria-hidden="true"></mat-icon>Chatter
      </button>
      <button mat-menu-item routerLink="/map" routerLinkActive="active">
        <mat-icon class="material-icons" fontIcon="place" aria-hidden="true"></mat-icon>Map
      </button>
      <button mat-menu-item routerLink="/users" routerLinkActive="active">
        <mat-icon class="material-icons" fontIcon="supervised_user_circle" aria-hidden="true"></mat-icon>Users
      </button>
      <button mat-menu-item routerLink="/search" routerLinkActive="active">
        <mat-icon class="material-icons" fontIcon="search" aria-hidden="true"></mat-icon>Search
      </button>
      <hr>
    </span>
    <!-- Overflow menu uses dark icons -->
    <span *ngIf="this.marketAuth.user | async; let user; else noLogin">
      <button mat-menu-item routerLink="/me">
        <mat-icon fontIcon="account_circle" aria-hidden="true"></mat-icon>My Profile
      </button>
      <button mat-menu-item routerLink="/user/{{ user.userId }}">
        <mat-icon fontIcon="account_circle" aria-hidden="true"></mat-icon>Public Profile
      </button>      
      <hr>
      <button mat-menu-item (click)="marketAuth.logoff()">
        <mat-icon fontIcon="close" aria-hidden="true"></mat-icon>Logoff
      </button>
    </span>
    <ng-template #noLogin>
        <button mat-menu-item routerLink="/notifications">
          <mat-icon fontIcon="notifications" aria-hidden="true"></mat-icon>Notifications
        </button>
        <hr>
    </ng-template>
    <button mat-menu-item routerLink="/help" routerLinkActive="active">
      <mat-icon class="material-icons-dark" fontIcon="help_outline" aria-hidden="true"></mat-icon>Help
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav mode="over" opened="false" #sidenav #sidenavHtml class="app-sidenav" (opened)="sidenavOpenClose(true)" (closed)="sidenavOpenClose(false)">
    <market-tags (navigatingToTag)="sidenav.mode == 'over' ? sidenav.close() : null" (tagsLoaded)="initSidenav()"></market-tags>
    <div class="app-sidenav-footer">
      <ad-market></ad-market>
    </div>
  </mat-sidenav>
  <div class="app-sidenav-content">
    <div class="app-content">
        <router-outlet></router-outlet>
    </div>
  </div>

  <div class="app-footer">
    <p>
      <a href="https://x.com/whitenoiseapp" target="_blank"><img src="/assets/images/social-x.png" alt="X" height="32" width="32" /></a> &nbsp;
      <a href="https://www.youtube.com/user/TMSoftVideos" target="_blank"><img src="/assets/images/social-google-youtube.png" alt="YouTube" height="32" width="32" /></a> &nbsp;
      <a href="https://www.facebook.com/WhiteNoiseApp" target="_blank"><img src="/assets/images/social-facebook.png" alt="Facebook" height="32" width="32" /></a> &nbsp;
      <a href="https://www.instagram.com/whitenoiseapp/" target="_blank"><img src="/assets/images/social-instagram.png" alt="Instagram" height="32" width="32" /></a> &nbsp;
      <a href="https://www.reddit.com/r/whitenoiseapp" target="_blank"><img src="/assets/images/social-reddit.png" alt="Reddit" height="32" width="32" /></a> &nbsp;
    </p>
    <p class="text-muted text-center">
      <a href="/app" routerLink="/app">App Download</a> |      
      <a href="/help" routerLink="/help">Support</a> |
      <a href="/privacy" routerLink="/privacy">Privacy</a> |
      <a href="/terms" routerLink="/terms">Terms</a><br>
      &copy; 2008-2025 TMSOFT, LLC.  All Rights Reserved.<br>
    </p>
  </div>
</mat-sidenav-container>