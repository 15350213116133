/* src/app/content.css */
.content {
  padding: 20px;
}
.padding {
  padding-left: 10pt;
  padding-right: 10pt;
}
hr {
  margin: 25px;
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  border: none;
}
/*# sourceMappingURL=content.css.map */
