import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform 
{
  transform(value: string, searchTerm: string): string 
  {
    if (!searchTerm) 
    {      
      return value;
    }
    //const re = new RegExp(searchTerm, 'gi');
    //return value.replace(re, `<span class="highlight">$&</span>`);
     // Split the searchTerm into an array of terms
     const terms = searchTerm.split(' ').filter(term => term);
     // Create a regular expression that matches any of the terms
     const re = new RegExp(`(${terms.join('|')})`, 'gi');
     // Replace the matched terms with a span element
     return value.replace(re, `<span class="highlight">$&</span>`);
  }
}