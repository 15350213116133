/* src/app/me/me.component.css */
.main {
  min-height: 20vh;
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.padded-content {
  padding: 20px;
}
.flex-content {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.tiles-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 300px;
  max-width: 80vw;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  outline: blue;
  cursor: pointer;
}
.ad-container {
  height: 475px;
  width: 100%;
  display: block;
}
.stats {
  display: inline-flex;
  vertical-align: middle;
  line-height: 20px;
}
.stat {
  min-width: 30px;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
}
.user-content {
  margin-left: 56px;
}
.stat-user {
  min-width: 50px;
  text-align: left;
  margin-left: 0px;
  margin-right: 15px;
  font-size: 16px;
}
.stats-icon {
  vertical-align: middle;
  font-size: 24px;
}
.spinner {
  margin: 0 auto;
}
.tiles-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.spacer {
  width: 300px;
  padding: 0 24px;
  margin: 0 10px;
  height: 0px;
}
.search-form {
  width: 90%;
  max-width: 500px;
  border: 1px solid #CCC;
  padding: 10px 6px;
  margin-right: 10px;
  display: flex;
  text-align: center;
  overflow: hidden;
  margin: auto;
  margin-top: 10px;
}
.search-input {
  text-align: center;
  width: 80%;
  max-width: 400px;
}
.badge {
  border-radius: 50%;
}
.small-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
}
.small {
  display: inline-block;
  width: auto;
  width: 250px;
  height: auto;
  min-height: max-content;
  margin: 10px;
  vertical-align: middle;
  outline: blue;
  cursor: pointer;
}
.small img {
  vertical-align: middle;
}
.small .title {
  vertical-align: middle;
  margin-left: 8px;
  font-size: 11pt;
}
.small .stat {
  display: inline-block;
  font-size: 10pt;
}
.empty {
  display: block;
  width: 100%;
  text-align: start;
  margin: 30px 0px;
}
/*# sourceMappingURL=me.component.css.map */
