/* src/app/token.component.css */
.card {
  width: 80%;
  max-width: 80vw;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  outline: blue;
}
.content {
  padding: 15px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
/*# sourceMappingURL=token.component.css.map */
