// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-field
{
    color: #000;
}

.config-container 
{
    display: flex;
    flex-direction: column;
    min-height: 150px;
}
  
.config-container > * 
{
    width: 80%;
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/config.dialog.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;;IAEI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".value-field\n{\n    color: #000;\n}\n\n.config-container \n{\n    display: flex;\n    flex-direction: column;\n    min-height: 150px;\n}\n  \n.config-container > * \n{\n    width: 80%;\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
