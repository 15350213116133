import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params }   from '@angular/router';

import { Sound } from './sound';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';

@Component({
  selector: 'market-search',
  templateUrl: './search.component.html',
  styleUrls: [ './search.component.css' ],
  encapsulation: ViewEncapsulation.None // highlights will not work without specifying this  
})

export class SearchComponent implements OnInit
{  
  title : string = "Search for Sounds";
  search: string = "";
  results: Sound[] | null = null;
  trends: any[] = [];
  columns: string[] = ["image", "sound"];

  constructor(private metaService : MetaService, 
            private marketService : MarketService, 
            private router: Router,
            private route: ActivatedRoute)
  { 
    const title = "Search for Sounds - White Noise Market";
    const description = "Search the entire sound catalog and download sounds into White Noise for free.";
    const image = "/assets/images/white-noise-market.png";
  
    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  // called after ngInit
  public ngOnInit()
  {
    // get search parameter from route
    this.route.params.subscribe((params: Params) => { this.performSearch(params['search'] as string) });

    // get search trends
    this.marketService.getSearches().then((trends) => { this.trends = trends; });
  }

  public onSearch()
  {
    console.log("SearchComponent onSearch: " + this.search);    
    this.performSearch(this.search);
  }

  public performSearch(search: string)
  {
    if (search == undefined || search.length == 0)
    {      
      this.search = "";
      this.results = null;
      return;
    }

    console.log("Search Component requesting search results for: " + search);
    this.search = search;    
    
    // setup meta (image set by base class)
    this.title = "Search results for " + this.search;
    const title = this.title + " - White Noise Market";
    const description = "Search the White Noise Market catalog for '" + this.search + "' sounds and download to White Noise for free.";
    this.metaService.updateTitle(title);
    this.metaService.updateDescription(description);

    // pull in sounds for this search
    this.marketService.getSoundsBySearch(this.search, 25, 0)
        .then((sounds) => { 
          console.log("Search results:", sounds);
          this.results = sounds; });
  }

  // goto sound
  goSound(sound: any): void {
    let url = `/sound/${sound.Slug}?id=${sound.Uid}`;
    this.router.navigateByUrl(url);
  }


}
