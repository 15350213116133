// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line
{
    min-height: 56px;
}

.picture
{
    display: inline-block;
    min-width: 52px;
    text-align: left;
}

.name
{
    display: inline-block;
    min-width: 125px;
    text-align: left;
}

.stat
{
    display: inline-block;
    min-width: 80px;
    text-align: left;
    margin-left: 10px;
}

.stats-icon
{
    vertical-align: middle;
    font-size: 24px;
    padding-right: 5px;
}

.spinner
{
    margin:0 auto;
}

.users
{
    margin-left: 20px;
}

.spacer
{
    width: 300px;
    padding: 0 24px;
    margin: 0 10px;
    height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/users.component.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,WAAW;AACf","sourcesContent":[".line\n{\n    min-height: 56px;\n}\n\n.picture\n{\n    display: inline-block;\n    min-width: 52px;\n    text-align: left;\n}\n\n.name\n{\n    display: inline-block;\n    min-width: 125px;\n    text-align: left;\n}\n\n.stat\n{\n    display: inline-block;\n    min-width: 80px;\n    text-align: left;\n    margin-left: 10px;\n}\n\n.stats-icon\n{\n    vertical-align: middle;\n    font-size: 24px;\n    padding-right: 5px;\n}\n\n.spinner\n{\n    margin:0 auto;\n}\n\n.users\n{\n    margin-left: 20px;\n}\n\n.spacer\n{\n    width: 300px;\n    padding: 0 24px;\n    margin: 0 10px;\n    height: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
