// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.content
{
  padding: 20px;
}

.padding 
{
  padding-left: 10pt;
  padding-right: 10pt;
}

hr
{ 
  margin: 25px;
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  border: none;
}

*
{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

`, "",{"version":3,"sources":["webpack://./src/app/content.css"],"names":[],"mappings":";AACA;;EAEE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;EACZ,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,+CAA+C;AACjD","sourcesContent":["\n.content\n{\n  padding: 20px;\n}\n\n.padding \n{\n  padding-left: 10pt;\n  padding-right: 10pt;\n}\n\nhr\n{ \n  margin: 25px;\n  height: 1px;\n  color: #ccc;\n  background-color: #ccc;\n  border: none;\n}\n\n*\n{\n  font-family: Roboto,\"Helvetica Neue\",sans-serif;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
