/* src/app/account/settings.component.css */
.content {
  padding: 20px;
}
.child-content {
  width: 90%;
  height: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  min-height: 20vh;
}
.footer {
  display: block;
  width: max-content;
  margin: 12px auto;
}
.progress {
  display: flex;
  justify-content: center;
  column-gap: 4px;
  margin: 12px 0;
}
.footer .buttons {
  display: flex;
  justify-content: center;
  column-gap: 4px;
  margin: 12px 0;
}
.footer .button {
  margin: 0 8px;
}
.footer .status {
  display: block;
  text-align: center;
  color: #3744AF;
}
.footer .error {
  display: block;
  text-align: center;
  color: #f44336;
}
.section {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.section .title {
  display: block;
  font-size: large;
  font-weight: bold;
  text-align: left;
}
.setting {
  display: block;
  width: 75%;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: small;
}
.setting .title {
  display: block;
  font-size: medium;
  font-weight: bold;
}
.setting .desc {
  display: block;
  font-size: small;
  color: #999;
}
.setting mat-form-field {
  width: 100%;
  margin: 10px 0;
}
.setting mat-form-field.number {
  width: 50%;
  margin: 10px 0;
}
.setting mat-slider {
  width: 100%;
  margin: 10px 0;
}
.setting mat-radio-group {
  margin: 10px;
}
.setting mat-radio-button {
  display: block;
  padding: 4px;
}
.setting mat-slide-toggle {
  margin: 10px;
}
.setting mat-hint.error {
  color: #f44336;
}
.setting mat-hint.status {
  color: #3744AF;
}
.setting input[matInput] {
  width: 100%;
  max-width: 600px;
}
.setting textarea[matInput] {
  width: 100%;
  max-width: 600px;
  height: 100px;
  resize: vertical;
}
.setting input[matInput][type=number] {
  margin: 10px 0;
  width: 75px;
}
.setting .action {
  display: block;
  margin: 24px auto;
}
.setting .avatar {
  display: block;
  margin: 24px auto;
  text-align: center;
}
.avatar .rounded {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
/*# sourceMappingURL=settings.component.css.map */
