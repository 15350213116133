import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

@Injectable()
export class MetaService
{
  constructor(private titleService: Title,
              private metaService: Meta) 
  {}

  // sets the title and other metadata using the strings provided
  updateMetadata(title: string, description: string, image: string)
  {
      this.updateTitle(title);
      this.updateDescription(description);
      this.updateImage(image);
      
      // // update title and meta data
      // this.titleService.setTitle(title);
      // this.metaService.updateTag({ name: 'description', content: description });
      // this.metaService.updateTag({ property: 'og:title', content: title });
      // this.metaService.updateTag({ property: 'og:description', content: description });
      // this.metaService.updateTag({ property: 'og:image', content: image });
      // this.metaService.updateTag({ name: 'twitter:title', content: title });
      // this.metaService.updateTag({ name: 'twitter:description', content: description });
      // this.metaService.updateTag({ name: 'twitter:image', content: image });
      
      // todo: add og:url and twitter:url?
  }

  updateTitle(title: string)
  {
    if (title == null || title.length == 0)
    {
      title = "White Noise Market";
    }
    else
    {
      // add branding
      title = title + " | White Noise Market";
    }
    this.titleService.setTitle(title);
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
  }

  updateDescription(description: string)
  {
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag({ property: 'og:description', content: description });
      this.metaService.updateTag({ name: 'twitter:description', content: description });        
  }

  updateImage(image: string) 
  {
    if (image == null || image.length == 0) // check if default image needed
    {
      // alsways use a default image if none provided
      image = "https://img.tmsoft.com/market/market.jpg";
    }
    else if (image.startsWith("/")) // check for relative path and make absolute
    {            
      if (environment.host.startsWith("http")) 
      {
        // http/https protocol already specified
        image = `${environment.host}${image}`;
      }
      else 
      {
        // localhost should use http otherwise assume https
        let proto = environment.host.includes("localhost") ? "http:" : "https:";
        image = `${proto}${environment.host}${image}`;
      }
    }
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ name: 'twitter:image', content: image });
  }
}
