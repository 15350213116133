import { Component, OnInit } from '@angular/core';
import { MarketService } from '../market.service';
import { MarketAuth } from '../marketauth.service';

@Component({
  selector: 'account-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DeleteComponent implements OnInit {

  working=false;
  authorized=false;
  requested=false;
  statusMessage="";

  // keep track of observable so it can be removed later
  private userSubscription: any;

  constructor(
    public marketService: MarketService,
    private marketAuth: MarketAuth,
    ) { }

  ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(user => this.userUpdated(user));
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  userUpdated(user: any)
  {
    this.authorized = (user != null);
  }

  onCancel()
  {
    // resets the view back to default
    this.statusMessage = "";
    this.requested = false;
  }

  onDelete()
  {
    if (!this.requested)
    {
      // switches view to confirmation mode
      this.requested = true;
    }
    else
    {
      // it was confirmed, so proceed with deletion
      this.working = true;
      this.marketService.terminateAccount()
        .then(res => {
          this.working = false;
          this.requested = false;

          // if the account was deleted, then log the user out
          if (res) {
            this.authorized = false;
            this.marketAuth.logoff();
            this.statusMessage = "Your account has been scheduled for deletion and you have been logged out."
          } else {
            this.statusMessage = "There was an error deleting the account. Please wait a few minutes and try again.";
          }
        })
        .catch(err => this.handleError(err));
    }
  }

  handleError(error: any) : void
  {
    this.working=false;
    this.statusMessage = "There was an error deleting the account. Please wait a few minutes and try again.";
    console.error("ERROR: Problem deleting account: " + error);
  }
}
