<h1 mat-dialog-title>Choose Avatar</h1>
<mat-dialog-content class="mat-typography">
  <div>Click on an avatar from the list below to select it.</div>
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Avatars">
      <div class="avatars-flex">
        <div *ngFor="let url of data?.avatars">
          <img class="avatar-select-img" [src]="url" (click)="onAvatar(url)">
        </div>
      </div>   
    </mat-tab>
    <mat-tab label="Sounds">
      <div class="avatars-flex">
        <div *ngFor="let url of data?.sounds">
          <img class="avatar-select-img" [src]="url" (click)="onAvatar(url)">
        </div>
      </div>   
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
</mat-dialog-actions>

<!--
<mat-card *ngIf="title">
    <mat-card-header>
        <mat-card-title><strong>{{title}}</strong></mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>
        <img mat-card-avatar [src]="avatar" *ngIf="avatar" (error)="avatarError($event.target);">
    </mat-card-header>
</mat-card>

<div class="tiles-flex">
  <div *ngFor="let url of data">
    <mat-card class="card" (click)="setAvatar(url)">
      <img mat-card-image [src]="url">
    </mat-card>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
-->