import { Component, ChangeDetectorRef } from '@angular/core';

// services
import { Sound } from '../services/sound';
import { Tag } from '../services/tag';
import { User } from '../services/user';
import { Setting } from '../account/setting';
import { MarketService } from '../services/market.service';
import { MetaService } from '../services/meta.service';
import { MarketAuth } from '../services/marketauth.service';
//import { MessagingService } from '../messaging.service';
//import { CookieService } from 'ngx-cookie';

import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-me',
    templateUrl: './me.component.html',
    styleUrls: ['./me.component.css'],
    standalone: false
})

export class MeComponent {

  user: null | any = null;
  working: boolean = false;
  authorized=false;  
  badges: any[] = [];
  tabIndex: number = 0;
  
  // other profile data
  favSounds: Sound[] = [];
  favTags: Tag[] = [];
  favUsers: User[] = [];
  settings: Setting[] = [];
  
  private userSubscription: any; // keep track of observable so it can be removed later
  
  constructor(protected marketService: MarketService,
            protected marketAuth: MarketAuth,
            private metaService: MetaService,
            private router: Router,
            private route: ActivatedRoute,
            private sanitizer: DomSanitizer,            
            private cdRef: ChangeDetectorRef,
            private snackBar: MatSnackBar)
  {
    const title = "My White Noise";   
    const description = "My White Noise feed, uploaded sounds, and settings.";
    
    // update title and meta data
    this.metaService.updateMetadata(title, description, "");
  }

  // called after ngInit
  public ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(user => this.setUser(user));
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) 
    {
      this.userSubscription.unsubscribe();
    }
  }

  private setUser(user : any)
  {
      this.user = user;
      this.authorized = (user != null);

      // pull profile, favorites, and settings
      if (this.authorized)
      {
        let data = this.marketAuth.getUserProfile();
        this.setProfile(data.Profile);
        this.settings = data.Settings as Setting[];
        this.favTags = data.Favorites.Tags as Tag[];
        this.favSounds = data.Favorites.Sounds as Sound[];
        this.favUsers = data.Favorites.Users as User[];
        
        /*
        this.marketService.getMeProfile()
          .then(data => {
            console.log("Received me data: ", data);
            this.setProfile(data.Profile);
            this.settings = data.Settings as Setting[];
            this.favTags = data.Favorites.Tags as Tag[];
            this.favSounds = data.Favorites.Sounds as Sound[];
            this.favUsers = data.Favorites.Users as User[];
          })
          .catch(err => {
            this.logError(err)
            this.setProfile(null);
          });
        */
      }
  }

  private setProfile(user: any): void
  {
    console.log("User profile:", user);

    // parse user badges
    if (user?.badges)
    {
        // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
        this.marketService.getBadges()
          .then(data => this.parseBadges(data, user.badges))
          .catch(err => this.handleBadgeError(err));
    }
  }

  private parseBadges(all : any[], badges : string) : void
  {
      if (all == null || all.length == 0 || badges == null || badges.length == 0) return;

      let result = [];
      
      for (var i = 0; i < all.length; ++i) 
      {
          let a : any = all[i];
          // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
          if (badges.includes(a.Id))
          {
              // add user badge to front of array as the items at the end are more important badges (tougher to get)
              result.unshift(a);
          }
      }
      
      this.badges = result;
      //console.info("Returning total user badges: " + this.badges.length);
  }

  private handleBadgeError(error : any) : void
  {
    console.info("Server had error requesting badges: " + JSON.stringify(error));
  }

  onTabChange($event: any) : void
  {
    let clickedIndex = $event.index;
    console.log("Tab Change: ", clickedIndex);
  }

  showMessage(msg : string): void 
  {
    this.snackBar.open(msg, null, { duration: 3000, verticalPosition: "bottom" });
  }

  goSound(sound: Sound): void
  {
    let url = this.getSoundLink(sound);
    this.router.navigateByUrl(url);
  }

  getSoundLink(sound: Sound) : string
  {
    let url = "/sound/" + sound.Slug + "?id=" + sound.Uid;
    return url;
  }

  onAvatarError(image : any): void
  {
      //console.warn("Failed to load avatar for url " + this.url);
      image.onerror = null;
      image.src = "/assets/images/default_user.jpg";
  }

  logError(err: any)
  {
    console.error("Error: ", err);
  }

}