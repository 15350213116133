// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-content
{
    margin-left: 54px;
}

.stat
{
    min-width: 50px;
    text-align: left;
    margin-left: 0px;
    margin-right: 15px;
    font-size: 16px;    
}

.stat-icon
{
    vertical-align: middle;
    font-size: 24px;    
}

.badge
{
    border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/app/user.component.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;;IAEI,kBAAkB;AACtB","sourcesContent":[".user-content\n{\n    margin-left: 54px;\n}\n\n.stat\n{\n    min-width: 50px;\n    text-align: left;\n    margin-left: 0px;\n    margin-right: 15px;\n    font-size: 16px;    \n}\n\n.stat-icon\n{\n    vertical-align: middle;\n    font-size: 24px;    \n}\n\n.badge\n{\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
