<div class="content">
  <h1>TERMS OF USE</h1>
  <p>
  This is an agreement between you and TMSOFT, LLC ("TMSOFT"). TMSOFT has published a set of software programs collectively known as the White Noise Market.
  These programs may be presented through a website, or through an application for use on a mobile phone, such as an iOS application, an Android application, or an application written for some other computer operating system.
  By using an application to which this agreement applies, or by creating a user account that grants you access to the White Noise Market, you agree to abide by the terms of this agreement.
  <p>
  Any application to which this agreement applies will so indicate either by a hyperlink, if the application is web-based, or by a link on the "About" or "Help" screen, if a mobile application, or by whatever manner is customary for that application type.
  <p>
  Regardless of the technology by which you access the White Noise Market, the following terms govern your use of the service and your relationship with TMSOFT.
  <p>

  <h2>GENERAL TERMS APPLICABLE TO YOUR USE OF THE WHITE NOISE MARKET</h2>
  <p>

  <h3>Privacy Policy</h3>
  <p>
  Any personally identifiable information you submit to the White Noise Market will be processed, stored, and used in accordance with our Privacy Policy. You may find a copy of our Privacy Policy at http://whitenoisemarket.com/privacy.
  <p>

  <h3>Changes To This Agreement</h3>
  <p>
  TMSOFT may amend this agreement at any time. TMSOFT will post such amendment to the White Noise website. An amendment to this agreement becomes effective upon TMSOFT posting it to the website, and supersedes all other agreements between you and TMSOFT for your use of the White Noise Market.
  <p>


  <h3>License To Use The White Noise Market</h3>
  <p>
  TMSOFT grants you an individual, non-transferable license to use the White Noise Market to browse, purchase, record, upload, create, mix, and play sounds to help you relax or sleep.
  You may not use the service for any other purpose.
  <p>
  TMSOFT may revoke this license and terminate your account with the White Noise Market at any time for any reason, without notifying you.
  <p>

  <h3>User Content</h3>
  <p>
  All content added, created, uploaded, submitted, distributed, posted or shared to the White Noise Market by users, including without
  limitation any and all comments, ratings, hearts, descriptions, posts, information, data, meta data, text, images, photographs, recordings, audio previews, and audio files (collectively "User Content"), whether publicly
  posted or privately transmitted, is the sole responsibility of the person who originated such User Content.
  <p>
  You represent that all User Content provided by you is in compliance with all applicable laws, rules and regulations.
  You acknowledge that all Content, including User Content, accessed by you using the White Noise Market is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting
  therefrom. We do not guarantee that any Content you access on or through the White Noise Market is or will continue to be available.
  <p>
  By submitting User Content through the White Noise Market, you hereby do and shall grant us a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify,
  truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Content in connection with the White Noise Market and our (and our successors' and assigns')
  businesses, including without limitation for promoting and redistributing part or all of the White Noise Market (and derivative works thereof) in any media formats and through any media channels
  (including, without limitation, third party websites and feeds), and including after your removal or termination of your account. You also hereby do and shall grant each user of the White Noise Market, a non-exclusive,
  perpetual license to access your User Content through the White Noise Market, and to use, edit, mix, modify, reproduce, distribute, prepare derivative works of, display and perform such User Content, including after the
  removal or termination of your account.

  <h3>Child Sexual Abuse and Exploitation (CSAE) Policy</h3>
  <p>
  We have a zero-tolerance policy towards child sexual abuse material (CSAM) and exploitation. TMSOFT is committed to maintaining a safe environment for all users, especially minors. 
  We take immediate and decisive action to prevent, detect, and remove any CSAM or exploitation-related activities.
    
  <h4>CSAE Point of Contact</h4>
  <p>
  For urgent concerns or to report potential child sexual abuse or exploitation, please contact us at the following:
  <p>
  Email: support [at] tmsoft.com
  
  <h4>Reporting Process</h4>
  <p>
  If you come across any content that you believe may involve child sexual abuse or exploitation, please report it immediately. Reports will be handled in accordance with our internal policies and in cooperation with law enforcement. 
  When submitting a report, please provide as much detail as possible, including links, screenshots, or other relevant information.
    
  <h4>Prevention and Detection</h4>
  <p>
  All content submitted to the White Noise Market is monitored and reviewed by humans for potential violations of this policy.

  <h3>White Noise Market Credit System</h3>
  <p>
  The White Noise Market allocates the amount of data transferred to and from TMSOFT's servers using a system of credits. If you register with the White Noise Market
  you are eligible to be awarded additional credits or to purchase credits. These credits may be used to increase your data transfer limit. Credits are only redeemable for data transfer increases
  and have no cash value. You may be awarded credits for certain actions you take, such as registering your email address with TMSOFT, and you may purchase credits. The price of credits and the
  amount of additional data transfer a single credit may be redeemed for will be set at the sole discretion of TMSOFT, and may be changed at any time, and without notice to you.

  <h2>TERMS ADDRESSING COPYRIGHT AND OTHER INTELLECTUAL PROPERTY</h2>

  <h3>Your Warranty of Non-Infringement</h3>
  <p>
  You warrant that you are either the owner of all rights in material you submit to TMSOFT, or that you are authorized by the owner of the material to publish or distribute material you submit to TMSOFT for processing. You further warrant that your intended use of such material will not infringe any right of the owner of such material.

  <h3>Ownership of Submitted Material</h3>
  <p>
  You may submit material to TMSOFT through the White Noise Market. You grant TMSOFT a non-exclusive, worldwide, perpetual, irrevocable license to use material you submit in any way TMSOFT wishes.

  <h3>Claims of Copyright Infringement</h3>
  <p>
  If you believe your work has been copied in a way that constitutes copyright infringement, are aware of any infringing material available through the White Noise Market please notify us at the address specified below of your concern by submitting a signed written notice and containing the following:
  <p>
  (i) A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright interest;
  <p>
  (ii) Identification of the copyrighted work claimed to have been infringed;
  <p>
  (iii) Identification of the material within the White Noise Market that is claimed to be infringing, and information reasonably sufficient to permit us to locate the material;
  <p>
  (iv) Information reasonably sufficient to permit us to contact you, such as your address, telephone number, and, if available, your e-mail address;
  <p>
  (v) A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
  <p>
  (vi) A statement that the above information is accurate, and under penalty of perjury, that you are, or are authorized to act on behalf of, the owner of the copyright that is allegedly infringed.
  <p>
  The email address for notice is copyright&#64;tmsoft.com. Please note, however, that in order for your notice to us to be effective under the Digital Millennium Copyright Act, your notice must be in writing and contain the elements described above. We will not respond to any emails sent to this address other than formal copyright infringement claims or counter-claims under the DMCA.

  <h3>DMCA Counter Notification</h3>
  <p>
  If you are the owner or a person authorized to act on behalf of the owner of the material, which was removed from our service or disabled after we received a copyright infringement notice, and you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material, you may ask that the material be restored by sending us a counter-notification. Section 512(g) of the Copyright Act requires that your counter-notification include all of the following:
  <p>
  (i) Your physical signature.
  <p>
  (ii) Identification of the material that had been removed or disabled and the specific URLs where the material appeared before it was removed or disabled.
  <p>
  (iii) A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.
  <p>
  (iv) Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located and that you will accept service of process from the person who provided the copyright infringement notification or an agent of such person.
  <p>
  Please note that under Section 512(f) of the Copyright Act, any person who knowingly materially misrepresents that material was removed or disabled by mistake or misidentification may be subject to liability.
  <p>
  Once we have received your counter-notification, we will forward it in its entirety to the party who submitted the original claim of copyright infringement. The party who submitted the original claim must then notify us with ten days that he or she has filed an action seeking a court order to restrain you from engaging in infringing activity relating to the material, which was the subject of the original claim of copyright infringement. If we receive notice of such an action, we will not restore the material. If we do not receive such notification, we will replace the removed material and cease disabling access to it.
  <p>

  <h2>WARRANTIES, INDEMNIFICATION AND DISPUTE RESOLUTION</h2>
  <p>
  This section contains important limitations to your rights to recover damages from TMSOFT in the event of a dispute. Certain sections are required by law to be made conspicuous. Those sections are presented in bold, italic type. Please read this section carefully.

  <h3>You Indemnify TMSOFT and Its Agents</h3>
  <p>You shall indemnify TMSOFT and its agents against all claims arising out of or related to your breach of this agreement. You shall indemnify TMSOFT and its agents against all claims arising out of or related to any content you submitted for printing. You shall pay reasonable legal fees and expenses in connection with the above indemnification. TMSOFT's agents include its directors, officers, shareholders, and employees.

  <h3>TMSOFT's Disclaimer of Warranties Of Functionality of the White Noise Market</h3>
  <p>TMSOFT disclaims any warranty that your use of the website will be uninterrupted, timely, secure, or free from error.
  TMSOFT disclaims any warranty that information of the website will be accurate or reliable.
  TMSOFT disclaims any warranty that defects in the website will be corrected.

  <h3>Limitation of Liability</h3>
  <p>You use the White Noise Market at your own risk. TMSOFT will not be liable for damages arising from or related to your use of the service, regardless of the form of action, whether in contract, tort, strict product liability or any other theory of damages, even if you have been advised of the possibility of such damages.

  <h3>Arbitration</h3>
  <p>As the exclusive means of resolving any disputes arising out of or relating to this agreement, you may submit any such dispute to arbitration administered by the American Arbitration Association under its commercial arbitration rules, and you consent to any such dispute being so submitted. Judgment on the award rendered in any such arbitration may be entered in any court having jurisdiction.

  <h3>Injunctive Relief</h3>
  <p>As an alternative or supplement to arbitration, TMSOFT, at its option, may obtain in any court of competent jurisdiction injunctive relief against conduct or threatened conduct for which no adequate remedy at law may be available or which may cause TMSOFT irreparable harm. In seeking injunctive relief, TMSOFT is not limited by the terms of this contract addressing forum selection.

  <h3>Attorney's Fees</h3>
  <p>TMSOFT is entitled to recover from you reasonable costs and attorney's fees it may expend in representing itself in a dispute with you arising out of or relating to this agreement, whether such dispute is adjudicated in a court or arbitrated.

  <h3>Claim Limitation</h3>
  <p>Any claim you may have against TMSOFT arising out of or related to this agreement is barred if not initiated within one year of the act that you claim caused the damage that leads to the dispute, irregardless of when such act or such damage is discovered.


  <h2>GENERAL AGREEMENT CLAUSES</h2>
  <p>The following clauses address miscellaneous issues that may arise during the existence of this contract.

  <h3>Assignment of Rights and Delegation of Duties</h3>
  <p>You may not assign the rights granted to you by this agreement or delegate the duties assigned to you by this agreement without TMSOFT's prior written consent. In the event you attempt to assign your rights or delegate your duties, any purported assignment or delegation is void. TMSOFT may assign the rights granted in this Agreement or delegate the duties it assumes under this Agreement at any time without notifying you or obtaining your consent.

  <h3>Waiver</h3>
  <p>No provision in this Agreement may be waived. Neither trade usage nor a course of dealings between TMSOFT and you will operate as a waiver of a provision of this agreement.

  <h3>Applicable Law</h3>
  <p>The laws of Commonwealth of Virginia, without regard to its law about the conflict of laws, governs this agreement and any dispute arising out of or related to it.

  <h3>Entire Agreement</h3>
  <p>
  This document constitutes the sole expression of your agreement with TMSOFT The provisions of this agreement cannot be explained or modified orally or through evidence of trade usage or a course of dealings. No provisions of this agreement may be added or deleted orally or through evidence of trade usage or a course of dealings.

  <h3>Severability</h3>
  <p>If any provision of this agreement is judged unenforceable the remaining provisions of this agreement remain in full force, if the essential terms of this agreement remain enforceable.

  <h3>Venue</h3>
  <p>The courts in Commonwealth of Virginia, either Federal Courts or State Courts, are the only venues in which a suit arising out of or related to this agreement may be adjudicated, with the exceptions that judgment on an award in arbitration of this agreement may be entered in any court having jurisdiction.

  <h3>Contact TMSOFT</h3>
  <p>
  TMSOFT, LLC<br>
  91220 Overseas Hwy Unit 1038<br>
  Tavernier, FL 33070-5052<br>
  <p>
  Email: support [at] tmsoft.com

</div>
