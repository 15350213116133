<mat-card appearance="outlined">
  <mat-card-title><strong>White Noise Market Help</strong></mat-card-title>
</mat-card>
<div class="content">
  <p>
    Browse through the White Noise Market for any sounds you wish to add to any of our
    <a href="https://www.tmsoft.com/white-noise/#download" target="_blank">White Noise</a> apps.
    Tap on any sound tile to bring up the sound details page. You can preview any sound before choosing to download.
    Tap the "Download to White Noise" button to download the sound. You will have to be logged in to download sounds.
    You can use Facebook, Google, or an e-mail address to create an account. Once logged in you'll be able to download
    sounds, send hearts, and write comments.
  </p>
  <h3>Common Questions</h3>
  <ul>
    <li>Is there a native White Noise Market app? <a href="{{linkRoot}}#native">Show Answer</a></li>
    <li>Why do I need to login? <a href="{{linkRoot}}#login">Show Answer</a></li>
    <li>Can I trust you with my personal info? <a href="{{linkRoot}}#trust">Show Answer</a></li>
    <li>How do I download sounds? <a href="{{linkRoot}}#download">Show Answer</a></li>
    <li>Can I download mp3 files for playback in traditional media players? <a href="{{linkRoot}}#mp3">Show Answer</a></li>
    <li>Can I listen to these sounds as a podcast? <a href="{{linkRoot}}#podcast">Show Answer</a></li>
    <li>How do I download sounds to older mobile devices? <a href="{{linkRoot}}#old">Show Answer</a></li>
    <li>Can I suggest a sound for the Market? <a href="{{linkRoot}}#suggest">Show Answer</a></li>
    <li>How can I create better recordings with White Noise? <a href="{{linkRoot}}#record">Show Answer</a></li>
    <li>Can I upload mixes and sounds to the Market? <a href="{{linkRoot}}#upload">Show Answer</a></li>
    <li>How do I get my sound featured on the Market? <a href="{{linkRoot}}#feature">Show Answer</a></li>
    <li>What is Zen and the other statistics displayed on my user profile page? <a href="{{linkRoot}}#zen">Show Answer</a></li>
    <li>What are the badges that are displayed on user profile pages and avatars? <a href="{{linkRoot}}#badges">Show Answer</a></li>
    <li>Why is the website blank? <a href="{{linkRoot}}#cookies">Show Answer</a></li>
    <li>Why do I see ads? <a href="{{linkRoot}}#ads">Show Answer</a></li>
    <li>How do I delete my account? <a href="{{linkRoot}}#delete">Show Answer</a></li>
    <li>How do I contact support? <a href="{{linkRoot}}#contact">Show Answer</a></li>
  </ul>

  <a name="native"></a>
  <h3>Is there a native White Noise Market app?</h3>
  <p>Yes! We launched our official White Noise Market app for both Android
    (<a href="http://app.tmsoft.com/whitenoisemarket/?google">Google Play Store</a>, <a href="http://app.tmsoft.com/whitenoisemarket/?amazon">Amazon
      Appstore</a>)
    and iOS (<a href="http://app.tmsoft.com/whitenoisemarket/?ios">Apple App Store</a>) that makes it much easier to
    download sounds directly into White Noise.
    Learn more details about the <a routerLink="/app" href="/app">White Noise Market App</a> or pick up a FREE copy
    from your favorite app store:
  </p>
  <p>
    <a href="http://app.tmsoft.com/whitenoisemarket/?apple" target=_blank><img src="/assets/images/download-app-store.png" alt="Download Apple App Store"></a>
    <a href="http://app.tmsoft.com/whitenoisemarket/?google" target=_blank><img src="/assets/images/download-google-play.png" alt="Download Google Play Store"></a>
    <a href="http://app.tmsoft.com/whitenoisemarket/?amazon" target=_blank><img src="/assets/images/download-amazon.png" alt="Download Amazon Appstore"></a>
  </p>

  <a name="login"></a>
  <hr>
  <h3>Why do I need to login?</h3>
  <p>Sound files are very large and it costs money to serve them up for free. Our previous site was shutdown
    because it generated too much traffic.
    We found that sounds were getting downloaded by search engines, bots, crawlers, and users that didn't even
    use our White Noise apps.
    The old website was also getting spammed by bots leaving inappropriate comments. It was a mess.
    In order to scale to demand, stop the spam, and reduce costs we completely redesigned the website and now
    require a verified account to download free sounds and leave comments.
  </p>
  <p>We now support creating an account with an e-mail address if you don't want to login with Facebook or Google.
     After you signup you'll receive an e-mail to verify you are human. Once you click the link then you'll have
     full access to the White Noise Market.
  </p>

  <a name="trust"></a>
  <hr>
  <h3>Can I trust you with my personal info?</h3>
  <p>Yes! Let's first discuss what personal information we receive from logging in with Google and Facebook.
    We get your name, e-mail address, and a URL to your public photo if you made one available.
    This information gets saved to a secure database. The e-mail address is used as a unique identifier for our
    system and helps us recover your account if any problems occur.
    Your first name and avatar (an icon or image used to represent you) are displayed when you post comments or upload sounds. The website and native
    app allow changing the name that is displayed and allows changing your avatar to a different image.
    You can also sign up using an e-mail address if you don't want to login using a social network. We never
    share your e-mail address with others.
  </p>

  <h4>Additional information about Social Networks</h4>
  <p>Social networks do not give us your password when you login. We do not get a list of your friends.
    We do not have access to post on your wall.
    We understand trust is hard to gain and easy to lose. After 10 years, White Noise is still one of the highest rated
    sleeping apps across all the app stores and we have thousands of awesome customer reviews.
    We just want to create awesome sounds for you to enjoy and we hope you appreciate our hard work.
  </p>

  <a name="download"></a>
  <hr>
  <h3>How do I download sounds?</h3>
  <p>
    Tap the "Download to White Noise" button (see below).
    This will download a White Noise Audio (WNA) file (ex: Sound.wna) which can be opened by any of the White
    Noise apps if installed on your device.
    We highly recommend using Safari for iOS devices and Chrome for Android devices.
    <br><br>
    <img src="/assets/images/button-white-noise-app.png" height="50%">
  </p>

  <a name="mp3"></a>
  <hr>
  <h3>Can I download mp3 files for playback in traditional media players?</h3>
  <p>
    Yes! We have launched a new feature allowing the purchase and download of mp3 files for select sounds for playback in your favorite media players.
    Sounds that are available for purchase will have a "BUY MP3" button (see below) on the sound details page.
    Alternatively, you can view a list of sounds available for purchase by browsing the mp3 category <a routerLink="/tag/mp3">here</a>.
    More will be added regularly so check back often for your favorite sounds.
  </p>
  <p>
    <a routerLink="/tag/mp3"><img src="/assets/images/button-buy-mp3.png"></a>
    <br><br>
    <span style="font-size: 9pt;">
      Purchase and use of mp3 files is subject to the <a [routerLink]="[]" (click)="onMP3Terms()">Personal Use License Agreement</a>.
    </span>
  </p>

  <a name="podcast"></a>
  <hr>
  <h3>Can I listen to these sounds as a podcast?</h3>
  <p>
    Yes, TMSOFT created the White Noise Sleep Sounds podcast that allows you to listen to our latest creations.
    New episodes are published weekly and you can use your favorite podcasting app to listen for free.
    Subscribe to <a href="https://anchor.fm/tmsoft">TMSOFT’s White Noise Sleep Sounds</a> using your favorite app or visit <a href="https://sleepsounds.fm">SleepSounds.FM</a> to listen now with your browser.
  </p>

  <a name="old"></a>
  <hr>
  <h3>How do I download sounds to older mobile devices?</h3>
  <p>
    We highly recommend using the native White Noise Market app if you're using a mobile device for easy
    download and management of sounds.
    However, if you are using an older iOS or Android device that may not be an option.
    In that case, you'll need to download sounds using a web browser.
  </p>

  <h4>iOS Download Instructions</h4>
  <p>Once the download finishes, Safari will display the file icon along with options for opening it.
    If you have multiple White Noise apps installed or other apps that will import any file, you may
    need to select the “Open In…” option to select the correct application.
    Otherwise, select “Open in White Noise”.
  </p>
  <p>White Noise will automatically import and begin playing the sound.
    If it does not automatically play, you can select the sound in the Catalog or you can navigate to
    it using the on-screen media controls.
  </p>

  <h4>Android Download Instructions</h4>
  <p>Once the download starts, a message in the notification bar will show the progress of the download.
    When the download is complete, tap the file or progress notification and pick the White Noise app to
    import the sound.
  </p>

  <h4>Android Alternative Instructions #2</h4>
  <p>If the above instructions don't work you can follow these directions from within the application.
    As of White Noise 4.1, there is a built-in mechanism and interface for locating and importing new
    sounds.
    From the main screen, click the Catalog icon located on the left side of the bottom toolbar.
    From here, open the menu and select “Import Sounds.”
    A view will appear listing all the sounds that were found in the Downloads folder.
    Simply click on each sound you wish to import and White Noise will ask if you would like to continue.
    For your convenience, after a successful import the file is automatically removed from the Downloads
    folder.
    You can also manually remove sounds by long pressing the desired sound and then selecting “Remove”
    from the context menu that appears.
  </p>

  <h4>Android Alternative Instructions #3</h4>
  <p>Devices running Android 3.0 or higher will have a shortcut on the home screen / launcher menu that
    says “Downloads”.
    Select it and you should see a list of files that have been recently downloaded.
    Select the sound you wish to import and White Noise will launch.
  </p>
  <p>Devices running Android 2.x should launch the “Browser” application.
    Tap the menu button, select “More” to open a sub menu, and then select the "Downloads" option.
    A window should open showing a list of recent downloads. Select the sound you wish to import and
    White Noise will launch.
  </p>
  <p>Note: If White Noise does not automatically launch and a list of applications is presented
    instead, scroll until you see White Noise and select it.
    Once White Noise has launched after selecting a sound, White Noise will ask if you would like to
    import the sound.
    As noted, if the imported sound already exists in your library it will be overwritten.
    Selecting 'Yes' will begin the import process and White Noise will automatically begin playing the
    sound when the process is completed.
    The sound should automatically play, but if it does not, you can select the sound in the Catalog or
    you can navigate to it using the on-screen media controls.
  </p>

  <h4>Android Alternative Instructions #4</h4>
  <p>Some Android devices, such as the older Nook devices, might be locked down from downloading files
    using the browser.
    The workaround is to download the file to a desktop computer.
    Connect the device to the computer using a USB cable and then copy the downloaded file into the
    "My Files" folder.
    On the device, try to open that file from the "My Files" folder in your library.
    It will give you a message that says the file type isn’t supported and it can’t be opened, however,
    the next time you run White Noise, it shows up as an available sound to import.
    You can also tap the + button on the bottom bar to bring up the import window.
  </p>

  <a name="suggest"></a>
  <hr>
  <h3>Can I suggest a sound for the Market?</h3>
  <p>
    Yes. First, check if your sound already exists using the Search bar at the top of the website.
    If you can't find what you're looking for then visit our
    <a href="https://docs.google.com/a/tmsoft.com/forms/d/1StvnJftx5cUzVC7Gx4OREjvUQURgUEfSaQcRHWpYT_Y/viewform" target="_blank">sound request form</a> to send us your idea.
  </p>

  <a name="record"></a>
  <hr>
  <h3>How can I create better recordings with White Noise?</h3>
  <p>
    Please watch our video and read our tips below on creating the best recordings with the White
    Noise App:
  </p>
  <p>
    <iframe class="youtube-player" type="text/html" width="640" height="360" src="https://www.youtube.com/embed/Hes7xetfqQE"
      frameborder="0" allowfullscreen></iframe>
  </p>
  <p>
    Always try to record at a location that doesn't have unwanted sounds such as people talking.
    It's best to place the device on a solid surface if possible and
    point the microphone (bottom of device) towards the sound you want to record. Do not make any
    noise while recording. If you hear an unwanted sound just hit the
    reset button and try again. Try to record at least 20 seconds, if not more. Once your sound is
    perfectly looped listen to it to make sure there are no unwanted
    sounds, clicks, or wind blowing into the mic. It's best to use high quality headphones when
    reviewing your recordings. Once you are satisfied with your recording,
    take a great photo to go along with it. Seriously, a great photo from where the sound was recorded
    really adds to the experience. You'll also need to make sure the
    recording does not contain copyright material such as music. Next, drop the location
    pin around where the sound was recorded. It doesn't have to be precise and
    shouldn't be if it was recorded at a private residence. You could drop the pin at a nearby
    intersection or in the middle of town. You can edit the photo and location
    even if you already have saved the recording using the sound catalog. Now you can share your
    recording via e-mail or upload to the White Noise Market as explained
    below.
  </p>

  <a name="upload"></a>
  <hr>
  <h3>Can I upload mixes and sounds to the Market?</h3>
  <p>
    Yes, but only with the latest White Noise Market App installed on your iOS or Android device
    along with the latest version of White Noise, White Noise Free, or White Noise Pro.
    Specifically you need the White Noise Market 5 (or later) app combined with either White
    Noise Pro 7, White Noise 7, or White Noise Free 7 (or later) apps for Android/iOS.
    Once you have verified the versions you are running (available in settings), you will be
    able to upload your recordings and mixes to the Market by tapping the share button in any
    of the
    White Noise apps and selecting "Share to Market." This will launch the Market app and your
    sound will start uploading. If the upload is successful,
    your sound will be available to friends with whom you share the specific link that is provided
    by the app. You can also navigate to "My Uploads" on the Transfers tab,
    tap on a sound, and then tap the share button to get the URL. Uploaded sounds are not be
    immediately made public and available to other Market users until it has been reviewed by the TMSOFT team.
    However, you can share your specific link to Facebook, Twitter, etc.
    And that brings us to the next question on how to get your sound featured on the Market.
  </p>

  <a name="feature"></a>
  <hr>
  <h3>How do I get my sound featured on the Market? </h3>
  <p>
    We feature unique recordings with unique photos from unique places in the world. We do have
    to review all sounds/mixes before featuring them on the Market because we want the
    Market to be a safe place for all age groups. We do our best to keep the dark things on
    the Internet out of it. We can't publish sounds with copyright material such as music or
    photos that are inappropriate for kids.
  </p>
  <p>
    Here are tips to get your sound featured:
    <br>1. Review <a href="{{linkRoot}}#record"> how to create better recordings</a> before
    you upload. We want sounds that are pure. No talking. No clicks. No wind in the mic.
    <br>2. Make sure you really take a great photo of the location you recorded at.
    <br>3. Specify the location of the recording on the map.
    <br>4. Type a short distinctive title along with a longer description of the sounds that users
    will hear in the experience.
    <br>5. Encourage your friends to download and heart your sound using the URL that is
    provided once you have uploaded. You can get the URL from the sound details page of
    your upload, too. The more downloads and hearts your sound gets will put it higher up on our
    list of sounds to review.
  </p>
  <p>Check out the <a href="/tag/recorder" routerLink="/tag/recorder">User Recordings</a>
    category for user contributed sounds that we have featured in the past.
  </p>

  <a name="zen"></a>
  <hr>
  <h3>What is Zen and the other statistics displayed on my user profile page?</h3>
  <p>
    Every user profile page displays statistics of all their uploaded sounds which includes the total hearts, comments, downloads, and minutes listened.
    Zen is a single number that represents various contributions made by the user.  The formula is secret but includes the sum of various stats and bonuses for
    things like earning badges.  Zen is the number used to rank users on the Top User leaderboard.
  </p>

  <a name="badges"></a>
  <hr>
  <h3>What are the badges that are displayed on user profile pages and avatars?</h3>
  <p>
    Users can be earn Achievement Badges for various accomplishments.  Badges are displayed on every user profile page for others to see.
    Visit the White Noise Market <a href="/badges" routerLink="/badges">Badges</a> page for a complete list that you can earn!  Earning badges also gives you a Zen bonus!
  </p>
  <p>
    Avatar Badges are displayed at the top right of a user's avatar.
    The blue checkmark badge is for White Noise Market admins.  The red 'M' badge is for moderators.
    The gold star badge is for premium subscribers.  Visit the Subscription page from the side menu of the
    White Noise Market app for additional details about becoming a premium subscriber.
  </p>

  <a name="cookies"></a>
  <hr>
  <h3>Why is the website blank?</h3>
  <p>
    You need to enable cookies in your device settings in order to see sounds. Also
    make sure Javascript is enabled and private browsing is off.
  </p>

  <a name="ads"></a>
  <hr>
  <h3>Why do I see ads?</h3>
  <p>We have always had advertisements on the White Noise Market website and in the
    native app.
    If you paid for a White Noise app, we really appreciate your support. The
    one-time fee for paid apps covers the included sounds, removal of ads from the
    paid app, additional features,
    and over 10 years of free updates and love. It doesn't pay for the ongoing
    bandwidth costs required for this site and the native app.
  </p>

  <a name="delete"></a>
  <hr>
  <h3>How do I delete my account?</h3>
  <p>You can delete your account from within the iOS or android app.
    To do so, open White Noise Market and ensure you are logged into the account you wish to delete.
    After logging in, navigate to settings and scroll down to the "About" section.
    Locate and tap the "Delete Account" option and follow the instructions.
    Please contact support if you have any questions or need assistance.
  </p>
  <br><br>
  <img src="/assets/images/help-delete.png">
  <a name="contact"></a>
  <hr>
  <h3>How do I contact support?</h3>
  <p>Contact us from within the Market app by tapping settings, scroll down, and tap
    "Send Feedback" or "Report Problem" if it's a bug. This adds the device and
    operating system version you are using and attaches a log file if reporting a problem.
    Also, please let us know the issue you encountered and attach a screenshot if
    possible. We'll do our best to help.
  </p>
  <p>If you are using the website then please contact support at tmsoft.com and let
    us know the exact problem, operating system, and browser version you are using.
  </p>
</div>