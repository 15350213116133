<h2 mat-dialog-title>
  <!-- wrapping login icon in divs like this was only way I could find to properly center and align the icon with the header text -->
  <div style="display: inline-block; position: relative; width: 29px; height: 24px;">
    <div style="position: absolute; left: 0; top: 2px; font-size: 30px" class="material-icons">edit</div>
  </div>
  {{title}}
</h2>
<mat-dialog-content>
  {{description}}
  <form class="config-container" fxLayout="column" fxLayoutAlign="center center">
    <!--fxLayout.gt-xs="row"-->
    <mat-form-field class="value-field">
      <input matInput id="value" name="value" [placeholder]="placeholder" [(ngModel)]="value" tabindex="0" [maxlength]="maxlength" [disabled]="working">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
<div *ngIf="info">
  <span style="color: blue">{{info}}</span>
</div>
<div *ngIf="error">
  <span style="color: red">{{error}}</span>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close #cancelButton class="mat-raised-button" tabindex="-1" [disabled]="working">Cancel</button>
  <button mat-button class="mat-raised-button mat-primary" tabindex="-1" [disabled]="working || original == value" (click)="commit()">Apply</button>
</mat-dialog-actions>