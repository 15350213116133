import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-terms-dialog',
    templateUrl: './terms-dialog.html',
    standalone: false
})
export class TermsDialog implements OnInit {

  
  constructor(private dialogRef: MatDialogRef<TermsDialog>)
   { }

  ngOnInit() {
  }

}
