import { Component } from '@angular/core';

// services
import { MetaService } from './services/meta.service';

// dialogs
import { MatDialog } from '@angular/material/dialog';
import { TermsDialog } from './store/terms-dialog/terms-dialog';

@Component({
    selector: 'market-help',
    templateUrl: './help.component.html',
    styleUrls: ['./content.css'],
    standalone: false
})
export class HelpComponent
{
  // should match the route for this component, so #pagelinks work properly
  linkRoot = "help";

  constructor(private metaService: MetaService, private dialog: MatDialog) 
  {
    const title = "Help, FAQ, Support";
    const description = "Frequently asked questions and support for the White Noise Market website and app.";
    const image = "";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  onMP3Terms(): void
  {
    this.dialog.open(TermsDialog);
  }
}
