import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkify',
    standalone: false
})
export class LinkifyPipe implements PipeTransform 
{
  // regex for http:// https:// urls
  expression = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  regex = new RegExp(this.expression);
  // regex for www. (without // before it, or it'd re-link the ones done above)
  expression2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  regex2 = new RegExp(this.expression2);
  
  transform(str: string) : string 
  {
    if (!str) return str;

    var result = str.replace(this.regex, "<a href='$1' target='_blank'>[Link]</a>");
    return result.replace(this.regex2, "$1<a href='http://$2' target='_blank'>[Link]</a>");
  }
}

@Pipe({
    name: 'linkifynone',
    standalone: false
})
export class LinkifyNonePipe implements PipeTransform 
{
  // regex for http:// https:// urls
  expression = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  regex = new RegExp(this.expression);
  // regex for www. (without // before it, or it'd re-link the ones done above)
  expression2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  regex2 = new RegExp(this.expression2);
    
  transform(str: string) : string 
  {
    if (!str) return str;
   
    var result = str.replace(this.regex, "…");
    return result.replace(this.regex2, "$1…");
  }
}
