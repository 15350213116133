// to use this config: `ng build --env=prod`

export const environment =
{
  production: false,

  title: "Market Beta", // long title
  label: "Beta", // short title

  host: "https://whitenoisebeta.com",
  admin: "https://admin.whitenoisebeta.com",
  api: "https://api.tmsoft.com",
  paypalId: "AaU9qyHsoSBamCIfMqr4WxrQhgU3XkRG6UayK-hUNtLy5y9dpSPObiXy-vBTyTz6QWQAssVYoYlfVFAF", // client id (tmsoft)

  // google map key (whitenoisemarket2.appspot.com)
  // gmap key for whitenoisemarket.com: "AIzaSyDY4ZP7iN0LuMlYWoIJqv60gR14_DG4jK0"
  gmap: "AIzaSyBI6OTRrD58ZUbtjVKloVngpuJU7dEvZDM",

  // providers for ng2-ui-auth
  auth:
  {
    providers:
    {
      facebook:
      {
        clientId: "152524258096813",
        url: "https://whitenoisebeta.com/auth/facebook/callback",
        redirectUri: "https://whitenoisebeta.com/cbauth/facebook"
      },
      google:
      {
        clientId: "982734939705-em9j16drjr6bg616o81mo21g876165u3.apps.googleusercontent.com",
        url: "https://whitenoisebeta.com/auth/google/callback",
        redirectUri: "https://whitenoisebeta.com/cbauth/google"
      }
    }
  },

  firebase: {
    apiKey: "AIzaSyCgs2509fLi4s12-Oz6dg0B9_E7lUrVn9Y",
    authDomain: "whitenoisemarket.firebaseapp.com",
    databaseURL: "https://whitenoisemarket.firebaseio.com",
    projectId: "whitenoisemarket",
    storageBucket: "whitenoisemarket.appspot.com",
    messagingSenderId: "982734939705",
    appId: "1:982734939705:web:0e8d8b4adb3af89cdd8fe8",
    measurementId: "G-6Y0YTLPYFH"
  }
};