import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { MarketService } from './market.service';
import { MarketAuth } from './marketauth.service';
import { MetaService } from './meta.service';
import { MessagingService } from './messaging.service';
import { Tag } from './tag';

@Component({
  selector: 'market-category',
  templateUrl: './tag.component.html',
  styleUrls: [ './tag.component.css' ],
})

export class TagComponent
{
  // information displayed in html
  title: string;                    // optional title displayed at top of page
  subtitle: string;              // optional subtitle displayed at top of page
  avatar : string;               // optional avatar displayed at top of page
  description: string;           // optional description displayed at top of page
  webLink : string;              // optional web link to follow subtitle
  displayLink : string;          // optional web link for displaying (requires webLink)

  // following / flag support
  followTag : string;            // tag to follow when hearting a category or user
  followFlag : number;           // flag type either 2 or 3 (category or user)
  isFollowed = false;            // true if subscribed to topic (local check)
  
  // tag to display
  tagObj: Tag;
  tag : string; 

  constructor(protected marketService: MarketService,
    protected marketAuth: MarketAuth,
    protected metaService: MetaService,
    protected messagingService: MessagingService,
    protected router: Router,
    protected route: ActivatedRoute)
  {
    const title = "Sounds by Category - White Noise Market";   
    const description = "Download categories of sounds to White Noise for free.";
    const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);

    // parse tag from route
    this.route.params.subscribe((params: Params) => { 
      this.setTag(params['tag'] as string) });
  }

  // called after ngInit
  private userSubscription: any; // keep track of observable so it can be removed later
  public ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(() => this.refreshFollow());
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) 
    {
      this.userSubscription.unsubscribe();
    }
  }

  public setTag(tag: string)
  {
    console.log("Tag Component is requesting tag: " + tag);
    this.tag = tag;
    this.followTag = tag; // categories use tag value for flags
    this.followFlag = 2;  // categories are type 2
    
    // setup title
    this.marketService.getTag(tag)
        .then( tag => this.setupTag(tag));
  }

  private setupTag(tag : Tag): void
  {
    this.tagObj = tag;
    this.title = tag.Label + " Category";
    this.avatar = tag.ImageUrl;

    const title = this.title + " - White Noise Market";
    var description = tag.Label + " category for White Noise Market sounds.";
    if (tag.Description && tag.Description.length > 0)
    {
      // add to the meta description
      this.subtitle = tag.Description;
      description += " " + tag.Description;
    }
    else
    {
      // add sub title
      this.subtitle = "White Noise Market sounds in the " + tag.Label + " category";
    }

    // add additional info for description meta
    description += " Download these sounds to the White Noise app for free.  Official Market for White Noise Sounds by TMSOFT.";

    // update title and meta data
    this.metaService.updateTitle(title);
    this.metaService.updateDescription(description);
  }

  refreshFollow() 
  {
    this.isFollowed = this.marketService.isFollowed(this.tagObj, this.followFlag);
  }

  onFollow(tag: Tag) 
  {
    this.marketService.toggleFollow(tag, this.followFlag)
        .then( (result) => this.isFollowed = Boolean(result.Value))
  }
}
