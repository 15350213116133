import { Component } from '@angular/core';
import { MetaService } from './services//meta.service';

@Component({
    selector: 'market-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./content.css'],
    standalone: false
})

export class PrivacyComponent
{
  constructor(private metaService: MetaService) 
  {
    const title = "Privacy Policy";
    const description = "Privacy policy for the White Noise Market";
    const image = "";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}
