<mat-card appearance="outlined" *ngIf="title">
    <mat-card-header>        
        <mat-card-title><strong>{{title}}</strong></mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>        
    </mat-card-header>
    <mat-card-content></mat-card-content>
</mat-card>

<ad-playwire type="leaderboard_atf" selectorId="desk_chatter_leaderboard_atf"></ad-playwire>

<div class="chatter-container">
    <div *ngFor="let comment of comments" (click)="gotoSound(comment)" class="chatter-box" >

        <div class="img-container">
            <img src="/assets/images/loading_photo_300.jpg"
                [lazyLoad]="this.marketService.imageLink(comment.SoundImageUrl, 300)"
                (onLoad)="lazyLoaded(comment)" />
        </div>

        <div class="top-title">{{ comment.SoundLabel | trim : '26' }}</div>
        <div *ngIf="comment.Hearts > 0" class="top-hearts">
            {{comment.Hearts}}
            <i class="fa fa-heart fa-lg heart-select" style="color:#FAA"></i>
        </div>
        <div class="bottom-avatar" (click)="gotoUser(comment)">
             <app-avatar [url]="comment.AvatarUrl" [size]=28 [uid]="comment.UserUid" [description]="comment.DisplayName" [isAdmin]="comment.IsAdmin" [isSubscriber]="comment.IsSubscriber" [isModerator]="comment.IsModerator"></app-avatar>
        </div>
        <div class="bottom-user" (click)="gotoUser(comment)">
             {{ comment.DisplayName }}
        </div>

        <div class="bottom-time">{{ comment.RelTime }}</div>

        <div class="comment-container">
            <div class="comment-text">{{ comment.Message | linkifynone | trim : '160' }}</div>
        </div>

    </div>
    <!-- creates a number of empty spacer elements so uneven last row of grid is left-aligned (empty items have no height, so extra rows of spacers take no space -->
    <div *ngFor="let spacer of [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]" class="spacer"></div>

    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
</div>