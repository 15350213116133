/* src/app/app.component.css */
.app-title {
  margin-left: 8px;
}
.app-toolbar-filler {
  flex: 1 1 auto;
}
.app-sidenav-footer {
  max-width: 225px;
  margin: 10px auto;
}
.nav-links {
  display: inline-flex;
  flex: 1 1 auto;
}
@media (max-width: 959px) {
  .hide-lt-960 {
    display: none !important;
  }
}
@media (min-width: 960px) {
  .show-lt-960 {
    display: none !important;
  }
}
@media (max-width: 699px) {
  .hide-lt-700 {
    display: none;
  }
}
@media (min-width: 700px) {
  .show-lt-700 {
    display: none;
  }
}
@media (max-width: 538px) {
  .hide-lt-538 {
    display: none;
  }
}
@media (max-width: 439px) {
  .hide-lt-440 {
    display: none;
  }
  .show-lt-440 {
    display: inline;
  }
  .app-toolbar-search {
    width: 140px;
    max-width: 140px;
  }
}
@media (min-width: 440px) {
  .hide-lt-440 {
    display: inline;
  }
  .show-lt-440 {
    display: none;
  }
}
.display-none {
  display: none;
}
.number-badge {
  display: inline-block;
  background-color: white;
  width: 12px;
  line-height: 12px;
  border-radius: 50%;
  text-align: center;
  font-size: 7pt;
  color: #3844AF;
  border: 2px solid white;
}
.app-footer {
  text-align: center;
}
/*# sourceMappingURL=app.component.css.map */
