// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container
{
    width: 100%;
    height: 100%;
}

.content
{
  padding: 20px;
}

.footer
{
    text-align: center;
}

*
{
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

`, "",{"version":3,"sources":["webpack://./src/app/map/map.component.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;;EAEE,+CAA+C;AACjD","sourcesContent":[".map-container\n{\n    width: 100%;\n    height: 100%;\n}\n\n.content\n{\n  padding: 20px;\n}\n\n.footer\n{\n    text-align: center;\n}\n\n*\n{\n  font-family: Roboto,\"Helvetica Neue\",sans-serif;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
