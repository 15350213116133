/* src/app/store/orders/orders.component.css */
.store-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.store-buttons button {
  margin-left: 10px;
  min-width: 150px;
}
.content {
  padding: 20px;
}
.child-content {
  width: 95%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 20vh;
}
.center-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.table-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
table {
  width: 100%;
}
.btn-container {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.login-button {
  color: white;
  background-color: #3744AF;
}
.download-button {
  display: inline-block;
  font-size: 9pt;
  height: 35px;
  color: white;
  background-color: #3744AF;
}
.sound-round-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 1px;
  vertical-align: middle;
  margin-right: 5px;
}
td.mat-mdc-cell {
  border-bottom-style: none;
}
th.mat-mdc-header-cell {
  border-bottom-style: none;
}
tr.order-hdr {
  height: 40px;
  background-color: #3744AF;
}
td.order-hdr-cell {
  font-size: 9pt;
  color: white;
}
.no-border {
  border-bottom-style: none;
}
.details-row {
  height: 60px;
  border-bottom-style: none;
  border-style: none;
}
.mat-column-details {
  padding: 8px !important;
}
.mat-column-date {
  width: 200px;
  min-width: 200px;
}
.mat-column-total {
  max-width: 150px;
  padding-left: 8px;
  padding-right: 4px;
  text-align: left;
}
.mat-column-id {
  width: auto;
  text-align: right;
}
.mat-column-name {
  width: auto;
  padding: 0px !important;
}
.mat-column-duration {
  min-width: 60px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
}
.mat-column-download {
  width: 110px;
  text-align: center;
  vertical-align: center;
  padding: 0px !important;
}
/*# sourceMappingURL=orders.component.css.map */
