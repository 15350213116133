import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialog } from '../terms-dialog/terms-dialog';
import { environment } from '../../../environments/environment';

// services
import { TMApiService } from '../../services/tmapi.service';
import { MarketService } from '../../services/market.service';
import { MarketAuth } from '../../services/marketauth.service';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css'],
    standalone: false
})
export class CheckoutComponent implements OnInit {

  working=false;
  purchasing=false;
  authorized=false;
  empty=true;
  cartData: any;
  columnIds: string[] = ['name', 'duration', 'price', 'remove'];

  // promo properties
  promoError="";
  promoCode="";
  promoWorking=false;
  statusMessage="";

  // keep track of observable so it can be removed later
  private userSubscription: any;

  public payConfig?: IPayPalConfig;

  constructor(
    public tmapiService: TMApiService,
    public marketService: MarketService,
    public marketAuth: MarketAuth,
    private dialog: MatDialog,
    private router: Router)
    { }

  ngOnInit()
  {
    this.authorized = this.marketAuth.isAuthenticated();

    this.statusMessage = "Loading...";
    this.working = true;
    this.tmapiService.checkStoreAvailable()
                    .then(res => {
                      this.working = false;
                      this.continueInit();
                    })
                    .catch (err => {
                      this.handleError(err);
                      this.continueInit();
                    });
  }

  ngOnDestroy()
  {
    // unsubscribe observables to avoid leaks
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  continueInit()
  {
      // update or clear status message
      if (!this.tmapiService.isStoreEnabled())
      {
        this.statusMessage = this.tmapiService.getDisabledMessage();
      }
      else
      {
        this.statusMessage = "";
      }

    // continue without settings
    this.userSubscription = this.marketAuth.user.subscribe(user => this.userUpdated(user));
  }

  userUpdated(user: any)
  {
    if (!this.tmapiService.isStoreEnabled())
    {
      return;
    }

    this.working = true;
    this.authorized = (user != null);
    this.tmapiService.getCartItems()
                    .then(data =>
                      {
                        console.log("Cart: User updated and refreshing cart.");
                        this.setCart(data)
                      })
                    .catch(err => this.handleError(err));
  }

  onRemove(id: string) : void
  {
    this.tmapiService.removeCartItem(id)
                        .then(data => this.setCart(data))
                        .catch(err => this.handleError(err));
  }

  getItemTotal() : string
  {
    if (this.cartData == null) return "$0.00";
    return "$"+this.cartData["purchaseItemTotal"];
  }

  getTotal() : string
  {
    if (this.cartData == null) return "$0.00";
    return "$"+this.cartData["purchaseTotal"];
  }

  getDiscount() : string
  {
    if (this.cartData != null && this.cartData["promoValue"] != null)
    {
      return this.cartData["promoValue"] * 100 + "%";
    }
    return "0%";
  }

  setCart(data: any[]) : void
  {
    let items = data["items"] ? data["items"].length : 0;
    console.log("Cart: Updating cart with " + items + " items.");

    this.cartData = data;
    this.empty = (this.cartData == null || !this.cartData["items"] || this.cartData["items"].length == 0);
    this.working=false;

    // only init paypal if authorized and the cart isn't empty
    if (!this.empty && this.authorized)
    {
      this.initPaypal();
    }
  }

  handleError(error : any) : void
  {
      console.error("ERROR: Problem fetching cart: " + error);
      this.working=false;
  }

  onOrderCompleted(response: any, orderId: string) : void
  {
    //console.log("ORDER COMPLETE=" + response);
    this.tmapiService.clearCart()
                        .then(data=> {

                          // remove purchasing spinner
                          this.purchasing = false;

                          // clear current page
                          this.setCart(data);

                          // navigate to orders
                          this.router.navigateByUrl('/store/orders/'+orderId);
                        })
                        .catch(err => this.handleError(err));
  }

  private initPaypal(): void
  {
    //console.log("Cart: Init paypal");

    if (!this.tmapiService.isStoreEnabled())
    {
      console.warn("Paypal error - Store is not enabled.");
      return;
    }

    if (this.cartData == null)
    {
      console.warn("Paypal error - No cart data.");
      return;
    }

    this.payConfig = {
      currency: 'USD',
      clientId: environment.paypalId,
    createOrderOnClient: (data) => this.createOrderData(this.cartData),
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'checkout',
        layout: 'vertical'
      },
     onApprove: (data, actions) =>
    {
      console.log("PayPal -- Transaction approved.", data, actions);
      this.purchasing=true;

      actions.order.get().then((details: any) => {
        this.tmapiService.putOrder(details, this.cartData)
        .catch(err => this.handleError(err));
    });
    },
    onClientAuthorization: (data) =>
    {
      console.log("PayPal -- Transaction completed.");
      this.tmapiService.putOrder(data, this.cartData)
                         .then(result => this.onOrderCompleted(result, data.id))
                         .catch(err => this.handleError(err));
    },
    onCancel: (data, actions) =>
    {
      console.log("PayPal -- Transaction cancelled.", data, actions);
      this.purchasing = false;
    },
    onError: (err) =>
    {
      console.log("PayPal -- Transaction error: ", err);
      this.handleError(err);
      this.purchasing = false;
    },
    onClick: (data, actions) =>
    {
      // purchase starts here
      console.log("PayPal -- Transaction started.", data, actions);
    },
  };
  }

  createOrderData(data: any): any
  {
    let purchaseUnits = data["purchaseUnits"];
    if (purchaseUnits == null)
    {
      console.error("Paypal error - Invalid purchase units.");
      return;
    }

    //console.log("UNITS = " + purchaseUnits);
    purchaseUnits = JSON.parse(purchaseUnits);

    let purchaseTotal = data["purchaseTotal"];
    if (purchaseTotal == null || purchaseTotal <= 0)
    {
      console.error("Paypal error - Invalid total.");
      return;
    }

    let purchaseItemTotal = data["purchaseItemTotal"];
    if (purchaseItemTotal == null || purchaseItemTotal <= 0)
    {
      console.error("Paypal error - Invalid item total.");
      return;
    }

    // purchase discount is optional
    let purchaseDiscount = data["purchaseDiscount"];
    if (purchaseDiscount == null)
    {
      purchaseDiscount = 0;
    }

    // config is https://developer.paypal.com/docs/api/orders/v2/
    let order = <ICreateOrderRequest> {
      intent: 'CAPTURE',
      application_context: {
        payment_method: { payee_preferred: "UNRESTRICTED" },
        shipping_preference: "NO_SHIPPING",
        user_action: "PAY_NOW",
      },
      purchase_units: [{
        items: purchaseUnits, // items are created in server
         amount: {
            currency_code: "USD",
            value: purchaseTotal, // total is received by server
            breakdown: {
              item_total: { value: purchaseItemTotal, currency_code: "USD" },
              discount: { value: purchaseDiscount, currency_code: "USD" }
            //shipping: { value: "0.00", currency_code: "USD"},
            //handling: { value: "0.00", currency_code: "USD"},
            }
          }
      }]
    }

    //console.log("ORDER = " + JSON.stringify(order));
    return order;
  }

  onPromoInput()
  {
    this.promoError = "";
    this.promoCode = this.tmapiService.fixPromoCode(this.promoCode);
  }

  onPromoApply()
  {
    this.promoCode = this.tmapiService.fixPromoCode(this.promoCode);
    if (this.promoCode.length == 0 || this.promoCode.length > 20)
    {
      this.promoError = "Invalid promo code.";
      return;
    }

    this.promoWorking = true;
    this.promoError = "";
    this.tmapiService.applyPromo(this.promoCode)
                    .then(data => {
                      this.promoCode = "";
                      this.promoWorking = false;
                      this.setCart(data);
                    })
                    .catch(error => this.onPromoError(error));
  }

  onPromoError(error: any)
  {
    this.promoError = error;
    if (this.promoError == null)
    {
      this.promoError = "Unknown error occurred.";
    }
    this.promoWorking = false;
    this.promoCode = "";
  }

  onPromoRemove() : void
  {
    this.promoWorking = true;
    this.tmapiService.applyPromo("reset")
                    .then(data => {
                      this.promoCode = "";
                      this.promoWorking = false;
                      this.setCart(data);
                    })
                    .catch(error => this.onPromoError(error));
  }

  onTerms(): void
  {
    this.dialog.open(TermsDialog);
  }
}
