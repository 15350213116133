<mat-card appearance="outlined">
  <mat-card-title><strong>Delete Account</strong></mat-card-title>
</mat-card>

<mat-progress-bar mode="indeterminate" *ngIf="this.working"></mat-progress-bar>

<div class="content" *ngIf="this.statusMessage">
  <div class="text-container">
    <p>{{this.statusMessage}}</p>
  </div>
</div>
<div class="content" *ngIf="!this.statusMessage">
  <div class="child-content" *ngIf="!this.authorized">
    <div class="center-container">
      <p>You must be logged in to view this page.</p>
      <button mat-button class="login-button" (click)="this.marketAuth.showLogin()">Login</button>
    </div>
  </div>
  <div class="child-content" *ngIf="this.authorized">
    <div class="text-container">
      <p>Before deleting your account we recommend downloading a copy of any sounds you have uploaded that you wish to keep.</p>
      <p>Once the account is deleted your sounds will also become unavailable. Deleting your account cannot be undone.</p>
    </div>
    <div class="center-container" *ngIf="!this.requested">
      <button mat-button class="delete-button" (click)="this.onDelete()">Delete Account</button>
    </div>
    <div class="center-container" *ngIf="this.requested">
      <p>Are you sure you want to delete your account? This cannot be undone.</p>
      <button *ngIf="!this.working" mat-button class="cancel-button" (click)="this.onCancel()">Cancel</button>
      <br>
      <br>
      <button *ngIf="!this.working" mat-button class="delete-confirm-button" (click)="this.onDelete()">Confirm Delete</button>
    </div>
  </div>
</div>