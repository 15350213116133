
<div class="tables-container">
    <table mat-table #table [dataSource]="dataSource">
  
      <!-- Image Column -->
      <ng-container matColumnDef="Image">
        <th mat-header-cell *cdkHeaderCellDef> </th>
        <td mat-cell *cdkCellDef="let row"> <img [src]="row.ImageUrl" class="row-image" *ngIf="row.ImageUrl"></td>
      </ng-container>
  
      <!-- Notification Column -->
      <ng-container matColumnDef="Note">
        <th mat-header-cell *cdkHeaderCellDef> </th>
        <td mat-cell *cdkCellDef="let row"> 
            <div class="row-message">{{row.Message}}</div>
            <div class="row-date">{{row.Sent | date}}</div>            
          </td>
      </ng-container>

      
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openNotification(row.Url)"></tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
  </div>
  