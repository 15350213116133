/* src/app/users.component.css */
.line {
  min-height: 56px;
}
.picture {
  display: inline-block;
  min-width: 52px;
  text-align: left;
}
.name {
  display: inline-block;
  min-width: 125px;
  text-align: left;
}
.stat {
  display: inline-block;
  min-width: 80px;
  text-align: left;
  margin-left: 10px;
}
.stats-icon {
  vertical-align: middle;
  font-size: 24px;
  padding-right: 5px;
}
.spinner {
  margin: 0 auto;
}
.users {
  margin-left: 20px;
}
.spacer {
  width: 300px;
  padding: 0 24px;
  margin: 0 10px;
  height: 0px;
}
/*# sourceMappingURL=users.component.css.map */
