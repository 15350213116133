import { Component, OnInit } from '@angular/core';

// services
import { FirebaseService } from './services/firebase.service';

@Component({
    selector : 'ad-market',
    styleUrls: [ './app.component.css' ],
    template : `
    <div *ngIf="this.footerText" class="ad-market">
      <span><a [href]="this.footerLink" target="_blank">{{this.footerText}}</a></span>
    </div>
    `
})
export class AdMarketComponent
{    
    footerText: string | null = null;
    footerLink: string | null = null;

    constructor(private firebase: FirebaseService)
    {        
    }

    public ngOnInit(): void 
    {                         
        // get nav footer values from remote config
        this.firebase.getRemoteString("footer_text", "").then(value => this.footerText = value);
        this.firebase.getRemoteString("footer_link", "").then(value => this.footerLink = value);
    }
}


