/* src/app/user.component.css */
.user-content {
  margin-left: 54px;
}
.stat {
  min-width: 50px;
  text-align: left;
  margin-left: 0px;
  margin-right: 15px;
  font-size: 16px;
}
.stat-icon {
  vertical-align: middle;
  font-size: 24px;
}
.badge {
  border-radius: 50%;
}
/*# sourceMappingURL=user.component.css.map */
