import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MarketService } from './market.service';
import { MarketAuth } from './marketauth.service';

@Injectable()
export class TMApiService {

  private catalogEndpoint = '/market/sounds.php';           // sound and price queries
  private cartEndpoint = '/market/cart.php';                // viewing and modifying cart
  private ordersEndpoint = '/market/orders.php';            // viewing orders
  private downloadEndpoint = '/market/download.php';        // downloads
  private promoEndpoint = '/market/promo.php';              // promo code query
  private versionEndpoint = '/market/version.php';          // used to test if store is online

  // store settings
  private settings: any;
  private storeOnline: boolean;

  constructor(
    private http: HttpClient,
    private marketService: MarketService,
    private marketAuth: MarketAuth)
  {
    // get mp3 service settings (api host, disabled, etc)
    this.getSettings();

    // auth updates
    this.marketAuth.user.subscribe(user => this.userUpdated(user));
  }

  // -------------- general queries --------------

  // helper to get api URLs
  getApiUrl(endpoint: string) : string
  {
    let host = (this.settings != null) ? this.settings["store.api"] : null;
    if (host == null || host.length == 0)
    {
      console.log("WARNING: No valid api host found in settings so using environment config.");
      host = environment.api;

    }
    return host + endpoint;
  }

  async getSettings(): Promise<any>
  {
    try {
      this.settings = await this.marketService.getStoreSettings();
      return Promise.resolve(this.settings);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  // checks both io.tmsoft is online and settings
  checkStoreAvailable() : Promise<boolean>
  {
    return new Promise((resolve, reject)=>
    {
      // first pull settings which should have store.enabled setting
      this.getSettings()
          .then(settings => {
            // then check store host responds to version.php like the bot does
            let versionUrl = this.getApiUrl(this.versionEndpoint);
            this.http.get(versionUrl)
                     .toPromise()
                     .then(response=> {
                       //console.log("Version = " + JSON.stringify(response));
                       this.storeOnline = true;
                       return resolve(this.isStoreEnabled()); // checks both settings and storeOnline
                      })
                      .catch(err => {
                        this.storeOnline = false;
                        this.handleError(err);
                        return reject(false);
                      });
          })
          .catch(err => {
            this.storeOnline = false;
            this.handleError(err);
            return reject(false);
          });
    });
  }

  isStoreEnabled() : boolean
  {
    if (!this.storeOnline)
    {
      return false;
    }

    if (this.settings == null || this.settings["store.enabled"] == null)
    {
      // now defaulting to false if setting isn't set or can't be read
      return false;
    }

    return this.settings["store.enabled"];
  }

  getDisabledMessage() : string
  {
    var msg = this.settings != null ? this.settings["store.disabled.message"] : null;
    if (msg == null)
    {
      msg = "The store is currently unavailable.";
    }

    return msg;
  }

  getPrice(pricing: any, duration: number): number {

    if (!pricing || pricing.length == 0) {
      return 0;
    }

    for (const item of pricing) {
      if (item.Duration == duration) {
        return item.Price;
      }
    }

    // not found
    return 0;
  }

  getSound(id: String): Promise<any>
  {
    let url = this.getApiUrl(this.catalogEndpoint);
    if (id && id.length > 0)
    {
      url += "?id="+id;
    }

    return this.http.get(url)
                    .toPromise()
                    .then(this.parseResult)
                    .catch(this.handleError);
  }

  getCartItems(): Promise<any>
  {
    if (this.isAuthorized())
    {
      // attempts to push local cart to server before refreshing
      if (this.needPush())
      {
        console.log("Cart: User is logged in and local cart needs syncing.");
        return this.pushCart()
                   .then(response=> {
                      let cart = this.parseResult(response);
                      localStorage.setItem("cart", JSON.stringify(cart));
                      return cart;
                   })
                   .catch(this.handleError);
      }
      else
      {
        console.log("Cart: User is logged in and querying for cart.");

        let url = this.getApiUrl(this.cartEndpoint);
        let token: string = this.marketAuth.getToken();
        if (token != null && token.length > 0)
        {
          url += url.indexOf("?") >= 0 ? "&" : "?";
          url += "token="+token;
        }

        //console.log("CART URL= " + url);
        return this.http.get(url)
                        .toPromise()
                        .then(response=> {
                          //console.log("Cart: Cart query response: " + JSON.stringify(response));
                          let cart = this.parseResult(response);
                          localStorage.setItem("cart", JSON.stringify(cart));
                          return cart;
                        })
                        .catch(this.handleError);
      }
    }
    else
    {
      console.log("Cart: User not logged in returning local cart.");

      var cart = JSON.parse(localStorage.getItem("cart"));
      if (cart == null) cart = {};
      return Promise.resolve(cart);
    }
  }

  // add a sound to cart by id
  addCartItem(id: string, duration: number): Promise<any>
  {
    // if we are authorized we can just rely on server for cart services

    // cap to 1-10 hours in seconds
    if (duration < 3600) duration = 3600;
    else if (duration > 36000) duration = 36000;

    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.cartEndpoint);

      if (id == null || id.length == 0)
      {
        return Promise.reject("Invalid id.");
      }

      // param should be [uid]:[duration] url encoded
      var param = id+":"+duration;
      param = encodeURIComponent(param);

      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "add="+encodeURIComponent(param);

      let token: string = this.marketAuth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("ADD URL= " + url);
      return this.http.get(url)
                      .toPromise()
                      .then(response=> {
                        let cart = this.parseResult(response);
                        localStorage.setItem("cart", JSON.stringify(cart));
                        return cart;
                      })
                      .catch(this.handleError);
    }
    else
    {
      // otherwise lookup the sound and manage it locally
      return new Promise((resolve, reject)=>
      {
        this.getSound(id)
            .then(response =>
              {
                let sound = response["Sounds"][0];
                let pricing = response["Pricing"];

                // load local cart
                let cart = JSON.parse(localStorage.getItem("cart"));
                if (cart == null)
                {
                  cart = {};
                  cart["items"] = [];
                }
                // add duration and display price to the sound result
                let hours = duration / 60 / 60;
                sound["Duration"] = duration;
                sound["DisplayDuration"] = hours == 1 ? "1 Hour" : hours+" Hours";

                let price = this.getPrice(pricing, duration);
                sound["TotalPrice"] = Number(price).toFixed(2);
                sound["DisplayTotal"] = "$"+ Number(price).toFixed(2);

                // add sound to items (replace existing item if exists)
                let index = this.indexOf(cart["items"], sound["Uid"]);
                if (index >= 0) cart["items"][index] = sound;
                  else cart["items"].push(sound);

                // update total price
                this.updateTotal(cart);

                // set changed flag for next login
                cart["changed"] = true;

                // save local cart
                localStorage.setItem("cart", JSON.stringify(cart));

                // return cart
                resolve(cart);
              })
            .catch(err => reject(err));
      });
    }
  }

  removeCartItem(id:string): Promise<any>
  {
    if (id == null || id.length == 0)
    {
      return Promise.reject("Invalid id.");
    }

    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.cartEndpoint);

      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "remove="+encodeURIComponent(id);

      let token: string = this.marketAuth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("REMOVE URL= " + url);
      return this.http.get(url)
                      .toPromise()
                      .then(response=> {
                        let cart = this.parseResult(response);
                        localStorage.setItem("cart", JSON.stringify(cart));
                        return cart;
                      })
                      .catch(this.handleError);
    }
    else
    {
        // load local cart
        let cart = JSON.parse(localStorage.getItem("cart"));

        // remove item if it exists
        var index = this.indexOf(cart["items"], id);
        if (index >= 0)
        {
          cart["items"].splice(index, 1);
        }

        // update totals
        this.updateTotal(cart);
        cart["changed"] = true;

        // save cart
        localStorage.setItem("cart", JSON.stringify(cart));

        return Promise.resolve(cart);
    }
  }

  clearCart(): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.cartEndpoint);

      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "clear";

      let token: string = this.marketAuth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("CLEAR URL=" + url);
      return this.http.get(url)
                      .toPromise()
                      .then(response=> {
                        let cart = this.parseResult(response)
                        localStorage.setItem("cart", JSON.stringify(cart));
                        return cart;
                      })
                      .catch(this.handleError);
    }
    else
    {
        localStorage.removeItem("cart");
        let cart = {};
        return Promise.resolve(JSON.stringify({cart}));
    }
  }

  isCartEmpty(): boolean
  {
    return this.cartCount() == 0;
  }

  cartCount(): Number
  {
    // we can just use local storage snapshot for the count
    let cart = JSON.parse(localStorage.getItem("cart"));
    if (cart == null) return 0;

    let items = cart["items"];
    if (items == null) return 0;
    return items.length;
  }

  applyPromo(promo: string): Promise<any>
  {
    if (this.isAuthorized())
    {
        let url = this.getApiUrl(this.cartEndpoint);
        url += "?promo="+promo;

        let token: string = this.marketAuth.getToken();
        if (token != null && token.length > 0)
        {
          url += url.indexOf("?") >= 0 ? "&" : "?";
          url += "token="+token;
        }

        //console.log("CART URL= " + url);
        return this.http.get(url)
                        .toPromise()
                        .then(response=> {
                          if (response && response["result"] == false)
                          {
                            // reject this cart update with promo error
                            return Promise.reject(response);
                          }
                          else
                          {
                            let cart = response["results"];
                            localStorage.setItem("cart", JSON.stringify(cart));
                            return cart;
                          }
                        })
                        .catch(this.handleError);
    }
    else
    {
      return new Promise((resolve, reject)=>
      {
        // local support for clearing promo code (while logged out)
        if (promo == 'reset') {

          // load local cart
          let cart = JSON.parse(localStorage.getItem("cart"));

          // remove promo
          cart["promoCode"] = "";
          cart["promoValue"] = 0;

          // update cart values
          this.updateTotal(cart);
          cart["changed"] = true;

          // save and return
          localStorage.setItem("cart", JSON.stringify(cart));
          resolve(cart);

        } else {
          let url = this.getApiUrl(this.promoEndpoint);
          url += "?id="+promo;

          this.http.get(url)
                   .subscribe(response => {
                     // load local cart
                     let cart = JSON.parse(localStorage.getItem("cart"));

                     var valid = false;
                     if (response && response["result"] == true)
                     {
                       let data = response["results"];
                       if (data && data.enabled)
                       {
                         cart["promoCode"] = data.promo_id;
                         cart["promoValue"] = data.value;

                        // update totals
                        this.updateTotal(cart);
                        cart["changed"] = true;

                        // save cart
                        localStorage.setItem("cart", JSON.stringify(cart));
                        return resolve(cart);
                       }
                     }

                     if (!valid)
                     {
                       let error = response != null ? response["error"] : null;
                       if (error == null)
                       {
                         error = "Invalid promo code.";
                       }
                       cart["promoCode"] = "";
                       cart["promoValue"] = 0;
                       return reject(error);
                     }
            });
        }
      });
    }
  }

  indexOf(data: any, id: String): number
  {
    if (data == null || data.length == 0)
    {
      return -1;
    }

    if (id == null || id.length == 0)
    {
      return -1;
    }

    for (var i=0; i < data.length; i++)
    {
      var item: any = data[i];
      var uid = item["Uid"];
      if (uid == null || uid.length == 0)
      {
        continue;
      }

      if (uid == id)
      {
        return i;
      }
    }

    return -1;
  }

  updateTotal(cart: any)
  {
    // recalculate total and display values
    // server/api does this for you but have to do it locally if not logged in
    var itemTotal = 0;
    var items = cart["items"];
    for (var i=0; i < items.length; i++)
    {
      var item = items[i];
      var itemPrice = Number(item["TotalPrice"]);
      itemTotal += itemPrice;
    }

    // update the display totals simulating what server calculates (including promo code)
    var promoValue = cart["promoValue"];
    var discount = 0;
    if (promoValue > 0 && promoValue <= 1.0)
    {
      discount = itemTotal * promoValue;
    }
    var total = discount > 0 ? itemTotal - discount : itemTotal;
    cart["purchaseTotal"] = Number(total).toFixed(2);
    cart["purchaseItemTotal"] = Number(itemTotal).toFixed(2);
    cart["purchaseDiscount"] = discount;
  }

  contains(data: any, id: String): boolean
  {
    let index = this.indexOf(data, id);
    return index >= 0;
  }

  getOrders(id:String): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.ordersEndpoint);

      if (id && id.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "id="+id;
      }

      let token: string = this.marketAuth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("ORDERS URL= " + url);
      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      let orders = {};
      return Promise.resolve(orders);
    }
  }

  putOrder(orderData: any, cartData: any): Promise<any>
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.ordersEndpoint);

      // create the body w/ receipt and send as post
      let body = {"receipt": orderData, "cart": cartData, "token": this.marketAuth.getToken()};

      //console.log("SENDING BODY=" + JSON.stringify(body));
      return this.http.post(url, body)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getDownloadBlob(downloadId: string): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getDownloadUrl(downloadId);

      //console.log("DOWNLOAD = " + url);
      return this.http.get(url, { responseType: 'blob' })
                      .toPromise()
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getApiDownloadUrl(): string
  {
    return this.getApiUrl(this.downloadEndpoint);
  }

  getDownloadUrl(downloadId: string): string
  {
    // process order on server to complete it
    var url = this.getApiUrl(this.downloadEndpoint);
    url += url.indexOf("?") >= 0 ? "&" : "?";
    url += "id="+downloadId;

    let token: string = this.marketAuth.getToken();
    if (token != null && token.length > 0)
    {
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "token="+token;
    }

    return url;
  }

  getRawDownloadUrl(downloadId: string): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.downloadEndpoint);

      // process order on server to complete it
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "qid="+downloadId;

      let token: string = this.marketAuth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("DOWNLOAD = " + url);
      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  private parseResult(response: any)
  {
    if (response && response["result"]==true)
    {
      var results = response["results"];
      //console.log("DEBUG MarketIO Response="+JSON.stringify(results));
      return results;
    }
    else
    {
      return Array();
    }
  }

  private handleError(err: any): Promise<any>
  {
    console.error('An error occurred', err);

    // pull out the most specific error message possible (market returns error.Message and firebase returns error.message)
    var msg : string = null;
    if (err.error)
    {
      // tmapi server uses error as a string itself
      if (typeof err.error == 'string')
      {
        msg = err.error;
      }
      else
      {
        msg = err.error.Message || err.error.message;
      }
    }
    if (msg == null)
    {
        msg = err.Message || err.message || err.statusText;
    }
    if (msg == null)
    {
        msg = "Unknown error occurred.";
    }

    // pass along the best error message to the application
    return Promise.reject(msg);
  }

  isAuthorized(): boolean
  {
    let token: string = this.marketAuth.getToken();
    return token != null && token.length > 0;
  }

  userUpdated(user: any)
  {
    if (user != null)
    {
      if (this.needPush())
      {
        this.pushCart()
            .then(response=> {
              console.log("Cart: Saving local cart snapshot after push.");
              let cart = this.parseResult(response);
              localStorage.setItem("cart", JSON.stringify(cart));
            })
            .catch(this.handleError);
      }
    }
  }

  needPush(): boolean
  {
    var cart = localStorage.getItem("cart")
    if (cart != null && cart.length > 0)
    {
      let jsonCart = JSON.parse(cart);
      return jsonCart["changed"];
    }

    return false;
  }

  // pushes snapshot of cart to server
  pushCart(): Promise<any>
  {
    var cart = localStorage.getItem("cart")
    if (cart == null || cart.length == 0)
    {
      console.log("Cart: Nothing in local cart to push.");
      return Promise.resolve("Nothing in local cart.");
    }

    cart = JSON.parse(cart);
    if (!cart["changed"])
    {
      console.log("Cart: Changed flag not set.");
      return Promise.resolve("No changes to push.");
    }

    let url = this.getApiUrl(this.cartEndpoint);
    let token: string = this.marketAuth.getToken();
    if (token != null && token.length > 0)
    {
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "token="+token;
    }

    // build parameter from all the items
    var param="";
    var items = cart["items"];
    if (items != null && items.length > 0)
    {
      for (var i = 0; i < items.length; i++)
      {
        let item = items[i];
        let uid = item["Uid"];
        let duration = item["Duration"];
        if (param.length > 0) param += ",";
        param += uid+":"+duration;
      }
    }
    let promoCode = cart["promoCode"];
    let body = { "set": param,  "promo": promoCode}

    //console.log("PUSH URL= " + url);
    return new Promise((resolve, reject)=>
    {
      this.http.put(url, body)
               .toPromise()
               .then((response) => {

                  cart["changed"] = false;
                  localStorage.setItem("cart", JSON.stringify(cart));

                  // send response to promise
                  resolve(response)
                })
                .catch(err => reject(err));
    });
  }

  fixPromoCode(str: string): string
  {
    if (str == null) return "";
    return str.replace(/\s/g,'')
              .toUpperCase();

  }

  getFileInfo(sound: any): string
  {
    if (sound == null) return "";
    let duration = sound["Duration"];
    let fileInfo = JSON.parse(sound["FileInfo"]);

    var info = "";
    if (duration.length > 0)
    {
      let hours = duration / 60 / 60;
      let hrsLabel = (hours == 1 ? "Hour" : "Hours");
      info += hours+" "+hrsLabel;
    }

    // see if we can get the file size info since we know the duration
    if (fileInfo && fileInfo.length > 0)
    {
      var index=-1;
      for (var i = 0; i < fileInfo.length; i++)
      {
        let file = fileInfo[i];
        if (file.duration == duration)
        {
          index = i;
          break;
        }
      }
      if (index >= 0)
      {
        let file = fileInfo[index];
        info += " - "+ Math.round(file.size/1024/1024).toFixed(0)+"MB";
      }
    }
    return info;
  }
}
