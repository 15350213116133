<mat-card appearance="outlined">
	<mat-card-header>
  		<mat-card-title><strong>MP3 Store</strong></mat-card-title>
		<mat-card-subtitle>Browse sounds available for MP3 purchase, manage your cart, or review your orders.</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="this.tmapiService.isStoreEnabled()" class="store-buttons">			
			<button mat-stroked-button routerLink="/store" routerLinkActive="active" title="Browse Purchaseable Sounds">Catalog</button>
			<button mat-stroked-button routerLink="/store/checkout" routerLinkActive="active" title="View your cart or checkout">Cart &amp; Checkout</button>
			<button mat-flat-button routerLink="/store/orders" routerLinkActive="active" title="View orders and downloads" color="primary">Orders &amp; Downloads</button>			
		</div>
	</mat-card-content>
</mat-card>

<mat-progress-bar mode="indeterminate" *ngIf="working||downloading"></mat-progress-bar>

<!-- store is disabled-->
<div class="content" *ngIf="this.statusMessage && this.statusMessage.length > 0">
	<div class="child-content">
		<div class="center-container">
			<p>{{this.statusMessage}}</p>
		</div>
	</div>
</div>

<div class="content" *ngIf="this.tmapiService.isStoreEnabled()">

  <div class="child-content" *ngIf="!authorized && !working">
    <div class="center-container">
      <p>Login to view your orders.</p>
      <button mat-button class="login-button" (click)="marketAuth.showLogin()">Login</button>
    </div>
  </div>

  <div class="child-content" *ngIf="!working && empty && authorized">
    <div class="center-container">
      <p>You have not completed any orders.</p>
    </div>
  </div>

  <div class="child-content" *ngIf="!working && !empty && authorized">

    <div class="table-container">

      <table mat-table [dataSource]="orderData" multiTemplateDataRows>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let order" class="order-hdr-cell">{{order.order_created | date:'medium'}}</td>
        </ng-container>

        <!-- Total -->
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let order" class="order-hdr-cell">{{order.displayTotal}}</td>
        </ng-container>

        <!-- Order ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Order ID</th>
          <td mat-cell *matCellDef="let order" class="order-hdr-cell">{{order.order_id}} - {{order.order_status}}</td>
        </ng-container>

        <!-- order details column - this is made up of one column that spans across all columns -->
        <ng-container matColumnDef="details">
          <td mat-cell *matCellDef="let order" [attr.colspan]="orderColumnIds.length">
            <div class="detail-container">

              <table id="sounds" mat-table [dataSource]="order.sounds" class="mat-elevation-z0">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <td mat-cell *matCellDef="let sound"><img src="{{sound.ImageUrl}}" class="sound-round-image" />{{sound.Label}}</td>
                </ng-container>

                <!-- Duration Column -->
                <ng-container matColumnDef="duration">
                  <td mat-cell *matCellDef="let sound"> {{this.tmapiService.getFileInfo(sound)}} </td>
                </ng-container>

                <!-- Download Column -->
                <ng-container matColumnDef="download">
                  <td mat-cell *matCellDef="let sound">
                    <form *ngIf="!sound.DownloadId" ngNoForm action="mailto: support@tmsoft.com?subject=Problem with Market MP3 order {{order.order_id}}" target="_blank" method="POST">
                      <button class="download-button" type="submit" mat-button>Contact</button>
                    </form>

                    <form *ngIf="sound.DownloadId" ngNoForm action="{{this.tmapiService.getApiDownloadUrl()}}" target="_blank" method="POST">
                      <input type="hidden" id="downloadId" name="downloadId" value="{{sound.DownloadId}}" />
                      <input type="hidden" id="token" name="token" value="{{this.marketAuth.getToken()}}" />
                      <button class="download-button" type="submit" mat-button>Download</button>
                    </form>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: detailColumnIds;" class="details-row"></tr>

              </table>
              </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: orderColumnIds;" class="order-hdr"></tr>
        <tr mat-row *matRowDef="let row; columns: ['details']"></tr>

      </table>
    </div>
  </div>

</div>
