import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shortnumber'})
export class ShortNumberPipe implements PipeTransform 
{
  transform(value: number, precision: number) : string
  {    
    if (precision === undefined)
    {
      precision = 1;
    }
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: precision });
    var unit = '';

    if (value >= 1000000000)
    {
      value = value / 1000000000.0;
      unit = 'b';      
    }
    else if (value >= 1000000)
    {
      value = value / 1000000.0;
      unit = 'm';      
    }
    else if (value >= 1000)
    {
      value = value / 1000.0;
      unit = 'k';          
    }    
    
    return formatter.format(value) + unit;  
  }
}