/* src/app/chatter.component.css */
.chatter-container {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
}
.chatter-box {
  position: relative;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  display: table;
  cursor: pointer;
  margin: 10px;
  font-size: 14px;
  color: #666;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, .2),
    0 2px 2px 0 rgba(0, 0, 0, .14),
    0 1px 5px 0 rgba(0, 0, 0, .12);
}
.img-container {
  position: absolute;
  top: 36px;
  bottom: 36px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
  position: absolute;
}
.img-container img {
  width: 100%;
  min-height: 100%;
}
.top-title {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 17px;
  color: #333;
}
.top-hearts {
  position: absolute;
  top: 10px;
  right: 8px;
}
.top-hearts .heart-select {
  color: white;
  font-size: 18px;
}
.comment-container {
  width: 100%;
  height: 100%;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.comment-text {
  padding: 4px;
  font-size: 140%;
  font-weight: 500;
  color: white;
  text-shadow:
    -1px -1px 0 #333,
    1px -1px 0 #333,
    -1px 1px 0 #333,
    1px 1px 0 #333;
}
.bottom-avatar {
  position: absolute;
  bottom: 1px;
  left: 1px;
  cursor: pointer;
}
.bottom-user {
  position: absolute;
  bottom: 10px;
  left: 38px;
  cursor: pointer;
}
.bottom-time {
  position: absolute;
  bottom: 10px;
  right: 8px;
}
.spacer {
  width: 300px;
  margin: 0 10px;
  height: 0px;
}
/*# sourceMappingURL=chatter.component.css.map */
