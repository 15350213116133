<div class="tag-profile">
  <mat-card appearance="outlined" *ngIf="title">
    <mat-card-header>
        <mat-card-title><strong>{{title}}</strong></mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">{{subtitle}} <a *ngIf="webLink" [href]="webLink" target="_blank">{{displayLink || webLink}}</a></mat-card-subtitle>
        <div mat-card-avatar *ngIf="avatar">
            <app-avatar [url]="avatar" [size]=42></app-avatar>
        </div>
    </mat-card-header>
    <mat-card-content>
     <div class="tag-content" >
        <p itemprop="about" *ngIf="description">{{description}}</p>
        <p *ngIf="this.followTag && this.followTag.length > 0">
          <button mat-flat-button color="primary" (click)="onFollow(this.tagObj)" *ngIf="!this.isFollowed" style="width: 130px;">
            <mat-icon fontIcon="favorite_border"/>
            Follow
          </button>
          <button mat-flat-button color="primary" (click)="onFollow(this.tagObj)" *ngIf="this.isFollowed" style="width: 130px;">
            <mat-icon fontIcon="favorite"/>
            Unfollow
          </button>
        </p>
      </div>
    </mat-card-content>
  </mat-card>
  <ad-playwire type="leaderboard_atf" selectorId="desk_tag_leaderboard_atf"></ad-playwire>
  <app-sounds query="tag" [param]="tag"></app-sounds>
</div>
