/* src/app/search.component.css */
.search-form {
  width: 90%;
  max-width: 500px;
  border: 1px solid #CCC;
  padding: 10px 6px;
  margin-right: 10px;
  display: flex;
  text-align: center;
  overflow: hidden;
  margin: auto;
  margin-top: 10px;
}
.search-input {
  text-align: center;
  width: 80%;
  max-width: 450px;
}
.search-button {
  margin-top: 10px;
}
.search-trends {
  text-align: center;
  font-size: large;
  margin-top: 20px;
  margin-bottom: 20px;
}
.search-trend {
  padding-bottom: 10px;
}
.search-results {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.table-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mat-column-image {
  padding: 10px !important;
  width: 64px;
  height: 64px;
}
.item-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 1px;
  vertical-align: middle;
}
.mat-column-sound {
  padding-top: 8px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  text-align: left;
}
.highlight {
  background-color: yellow;
}
table tr:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
/*# sourceMappingURL=search.component.css.map */
