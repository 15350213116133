<mat-card appearance="outlined">
	<mat-card-header>
  		<mat-card-title><strong>MP3 Store</strong></mat-card-title>
		<mat-card-subtitle>Browse sounds available for MP3 purchase, manage your cart, or review your orders.</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="this.tmapi.isStoreEnabled()" class="store-buttons">			
			<button mat-flat-button routerLink="/store" routerLinkActive="active" title="Browse Purchaseable Sounds" color="primary">Catalog</button>
			<button mat-stroked-button routerLink="/store/checkout" routerLinkActive="active" title="View your cart or checkout">Cart &amp; Checkout</button>
			<button mat-stroked-button routerLink="/store/orders" routerLinkActive="active" title="View orders and downloads">Orders &amp; Downloads</button>			
		</div>
	</mat-card-content>
</mat-card>
<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
<br/>
<br/>

<div class="content">
	<div class="center-container" *ngIf="this.statusMessage && this.statusMessage.length > 0">
		<p>{{this.statusMessage}}</p>
	</div>
	<div *ngIf="this.tmapi.isStoreEnabled()">
		<div class="center-container" *ngIf="empty">
			<p>There are currently no sounds available for purchase.</p>
		</div>
		<div class="table-container" *ngIf="!empty">
			<table mat-table [dataSource]="this.catalogItems">
				<ng-container matColumnDef="image">
					<td mat-cell *matCellDef="let item" (click)="goSound(item)">
						<img class="item-image" alt="{{item.name}} Photo" width=64 height=64
							[defaultImage]="'/assets/images/loading_photo_300.jpg'" 
							[errorImage]="'/assets/images/default_photo.jpg'" 
							[lazyLoad]="item.ImageUrl" />
					</td>
				</ng-container>
				<ng-container matColumnDef="sound">
					<td mat-cell *matCellDef="let item" (click)="goSound(item)"> 
						  <span><strong>{{item.Label}}</strong></span><br/>
						<span class="muted">{{item.ShortDescription}}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="preview">
					<td mat-cell *matCellDef="let item">
						<button mat-button class="button" (click)="onPlayPause(item)" *ngIf="this.previewId !== item.Uid">
							<mat-icon class="icon" fontIcon="play_circle"></mat-icon>
						</button>
						<button mat-button class="button" (click)="onPlayPause(item)" *ngIf="this.previewId === item.Uid">
							<mat-icon class="icon" fontIcon="pause_circle"></mat-icon>
						</button>
					</td>
				</ng-container>
				<ng-container matColumnDef="action">
					<td mat-cell *matCellDef="let item"> 
						<button mat-button class="button" (click)="addCart(item)">
							<mat-icon class="icon" fontIcon="add_shopping_cart"></mat-icon>
						</button>
					</td>
				</ng-container>
				<tr mat-row *matRowDef="let item; columns: catalogColumns;"></tr>
			</table>
		</div>
	</div>	
</div>