<div class="tiles">
  <mat-card appearance="outlined" *ngIf="title">
    <mat-card-header>
        <mat-card-title><strong>{{title}}</strong></mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>        
    </mat-card-header>
    <mat-card-content></mat-card-content>
  </mat-card>

  <ad-playwire type="leaderboard_atf" selectorId="desk_users_leaderboard_atf"></ad-playwire>

  <mat-tab-group (selectedTabChange)="setTab($event.index)" id="tabs" name="tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">diamond</mat-icon>
        <span>&nbsp;Subscribers</span>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">person</mat-icon>
        <span>&nbsp;Users</span>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">group</mat-icon>
        <span>&nbsp;All</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div class="users">
    <mat-nav-list>
      <mat-list-item *ngFor="let user of users" (click)="gotoUser(user)">
        <!--<img mat-list-avatar src="{{user.avatarUrl}}" (error)="avatarError($event.target);">-->
        <p mat-line>
          <span class="picture"><app-avatar [url]="user.avatarUrl" [size]=40 [uid]="user.userId" [description]="user.displayName" [isAdmin]="user.isAdmin" [isSubscriber]="user.isSubscriber" [isModerator]="user.isModerator"></app-avatar></span>
          <span class="name" title="Name"><strong>{{user.displayName}}</strong></span>
          <span class="stat" title="Zen"><mat-icon class="material-icons-dark stats-icon" fontIcon="diamond" aria-hidden="true"></mat-icon>{{user.stats.zen || 0 | shortnumber:0}}</span>
          <span class="stat" title="Uploads"><mat-icon class="material-icons-dark stats-icon" fontIcon="upload" aria-hidden="true"></mat-icon> {{user.stats.myUploads || 0 | shortnumber:0}}</span>
          <span class="stat" title="Hearts"><mat-icon class="material-icons-dark stats-icon" fontIcon="favorite" aria-hidden="true"></mat-icon> {{user.stats.hearts || 0 | shortnumber:0}}</span>
          <span class="stat" title="Comments"><mat-icon class="material-icons-dark stats-icon" fontIcon="comment" aria-hidden="true"></mat-icon> {{user.stats.comments || 0 | shortnumber:0}}</span>
          <span class="stat" title="Downloads"><mat-icon class="material-icons-dark stats-icon" fontIcon="download" aria-hidden="true"></mat-icon> {{user.stats.downloads || 0 | shortnumber:0}}</span>
          <span class="stat" title="Minutes Listened"><mat-icon class="material-icons-dark stats-icon" fontIcon="headphones" aria-hidden="true"></mat-icon> {{user.stats.minutes || 0 | shortnumber:0}}</span>
        </p>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
</div>