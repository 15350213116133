<div class="user-profile">
    <mat-card appearance="outlined" *ngIf="title">
      <mat-card-header>
          <mat-card-title><strong>{{title}}</strong></mat-card-title>
          <mat-card-subtitle *ngIf="subtitle">{{subtitle}}<br><a *ngIf="profile.userWebsite" [href]="profile.userWebsite | weblink" target="_blank">{{profile.userWebsite | displaylink}}</a></mat-card-subtitle>
          <div mat-card-avatar *ngIf="avatar">
              <app-avatar [url]="avatar" [size]=42 [isAdmin]="profile && profile.isAdmin" [isSubscriber]="profile && profile.isSubscriber" [isModerator]="profile && profile.isModerator"></app-avatar>
          </div>
      </mat-card-header>
      <mat-card-content>
        <div class="user-content" *ngIf="profile && profile.stats">
          <span class="nowrap">
            <span class="stat" title="Zen" (click)="showMessage('Total zen earned by ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="diamond" aria-hidden="true"></mat-icon> {{profile.stats.zen || 0 | number}}</span>
            <span class="stat" title="Total Uploads" (click)="showMessage('Total sounds uploaded by ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="upload" aria-hidden="true"></mat-icon> {{profile.stats.myUploads || 0 | number}}</span>
            <span class="nowrap" *ngIf="profile && profile.stats && profile.stats.myUploads > 0">
                  <span class="stat" title="Hearts received for user's sounds and comments" (click)="showMessage('Total hearts received by ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="favorite" aria-hidden="true"></mat-icon> {{profile.stats.hearts || 0 | number}}</span>
                  <span class="stat" title="Comments written about user's uploaded sounds" (click)="showMessage('Total comments written to ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="comment" aria-hidden="true"></mat-icon> {{profile.stats.comments || 0 | number}}</span>
                  <span class="stat" title="Downloads of user's uploaded sounds" (click)="showMessage('Total downloads of sounds by ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="download" aria-hidden="true"></mat-icon> {{profile.stats.downloads || 0 | number}}</span>
                  <span class="stat" title="Minutes listened of user's uploaded sounds" (click)="showMessage('Total minutes listened for sounds by ' + profile.displayName)"><mat-icon class="material-icons-dark stat-icon" fontIcon="headphones" aria-hidden="true"></mat-icon> {{profile.stats.minutes || 0 | number}}</span>
            </span>
          </span>
        </div>
        <div class="user-content" *ngIf="badges && badges.length > 0">
            <span class="nowrap">
                <br>
                <span *ngFor="let badge of badges">
                    <img class="badge" [src]="badge.ImageUrl" [title]="badge.Label" [alt]="badge.Label" width="32" height="32" (click)="showMessage(badge.Label + ' Badge earned by ' + profile.displayName + ' (' + badge.Description + ')')">&nbsp;
                </span>
            </span>
        </div>
       <div class="user-content" >
          <p itemprop="about" *ngIf="description">{{description}}</p>
          <p *ngIf="this.followTag && this.followTag.length > 0">
            <button mat-flat-button color="primary" (click)="onFollow(this.profile)" *ngIf="!this.isFollowed" style="width: 130px;">
              <mat-icon fontIcon="favorite_border"/>
              Follow
            </button>
            <button mat-flat-button color="primary" (click)="onFollow(this.profile)" *ngIf="this.isFollowed" style="width: 130px;">
              <mat-icon fontIcon="favorite"/>
              Unfollow
            </button>
          </p>
        </div>
      </mat-card-content>
    </mat-card>
    <ad-playwire type="leaderboard_atf" selectorId="desk_user_leaderboard_atf"></ad-playwire>
    <app-sounds query="user" [param]="userId" empty="No Uploaded Sounds."></app-sounds>    
  </div>
  