/* src/app/config.dialog.css */
.value-field {
  color: #000;
}
.config-container {
  display: flex;
  flex-direction: column;
  min-height: 150px;
}
.config-container > * {
  width: 80%;
  margin-top: 8px;
}
/*# sourceMappingURL=config.dialog.css.map */
