import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit 
{  
  @Input() 
  url : string = "/assets/images/default_user.jpg"; // url to image

  @Input() 
  size : number = 32; // size of image

  @Input() 
  uid : string = "";  // user uid for click to user profile

  @Input() 
  description : string = ""; // description for hover and alt

  @Input()
  isAdmin : boolean = false;
  @Input()
  isSubscriber : boolean = false;
  @Input()
  isModerator : boolean = false;

  // private bad variables handled from changes to input
  badgeUrl : string; // url to badge
  badgeSize : number; // size of badge
  badgeDescription : string; // size of badge

  constructor(private router: Router) { }

  ngOnInit() 
  {
  }

  ngOnChanges(model: any) 
  {
    if (this.url == "") 
    {
      this.url = "/assets/images/default_user.jpg";
    }
    
    if (this.isAdmin)
    {
      this.badgeUrl = "https://img.tmsoft.com/roles/admin.png";
      this.badgeDescription = "White Noise Administrator";
      this.badgeSize = this.size / 3;
      if (this.badgeSize < 16) this.badgeSize = 16;
    }
    else if (this.isSubscriber)
    {
      this.badgeUrl = "https://img.tmsoft.com/roles/subscriber.png";
      this.badgeDescription = "Premium Subscriber";
      this.badgeSize = this.size / 3;
      if (this.badgeSize < 16) this.badgeSize = 16;
    }
    else if (this.isModerator)
    {
      this.badgeUrl = "https://img.tmsoft.com/roles/moderator.png";
      this.badgeDescription = "Moderator";
      this.badgeSize = this.size / 3;
      if (this.badgeSize < 16) this.badgeSize = 16;
    }
    else
    {
      this.badgeUrl = null;
      this.badgeDescription = null;
      this.badgeSize = 0;
    }

    //console.log("Changing badge size to " + this.badgeSize);
  }

  gotoUser(): void
  {
    if (this.uid && this.uid.length > 0)
    {
      this.router.navigate(['/user', this.uid]);
    }
  }

  avatarError(image : any): void
  {
      //console.warn("Failed to load avatar for url " + this.url);
      image.onerror = null;
      image.src = "/assets/images/default_user.jpg";
  }

}
