// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card 
{
    width: 150px;
    max-width: 80vw;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    outline: blue;
}
.tiles-flex
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/badges.component.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;AACA;;IAEI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".card \n{\n    width: 150px;\n    max-width: 80vw;\n    display: inline-block;\n    margin: 10px;\n    vertical-align: top;\n    outline: blue;\n}\n.tiles-flex\n{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
