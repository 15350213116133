<h1 mat-dialog-title>Upload Sound</h1>
<mat-dialog-content>
    <div>Upload a White Noise Audio (WNA) file to your profile.</div>
    <br/>
    <input type="file" (change)="fileChange($event)" placeholder="Upload WNA file">
    <br/><br/>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
    <div>
        <div *ngIf="errorMsg.length > 0" style="color: red">{{errorMsg}}</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Add</button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">Cancel</button>
</mat-dialog-actions>
