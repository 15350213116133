import { Injectable, Injector }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

// objects
import { Sound, UserSounds } from './sound';
import { Tag } from './tag';
import { Comment } from './comment';
import { Flag, Flags, FlagResult } from './flag';
import { User } from './user';

// login and authentication
import { MarketAuth } from './marketauth.service';
import { Setting } from '../account/setting';
import { MessagingService } from './messaging.service';


// keep a reference to google analytics for easy access across the app
declare var gtag : any;

@Injectable()
export class MarketService
{
  private host               = environment.host;                      // Hostname to use for queries
  private itemsUrl           = this.host + '/api/items';              // URL to web api recent sounds
  private itemUrl            = this.host + '/api/item';               // URL to web api single sound details
  private tagsUrl            = this.host + '/api/categories';         // URL to public tags
  private searchUrl          = this.host + '/api/search';             // URL to search
  private searchesUrl        = this.host + '/api/searches';           // URL to search trends
  private geoUrl             = this.host + '/api/geo';                // URL to geo sounds
  private userUrl            = this.host + '/api/user/items';         // URL to user sounds
  private commentsUrl        = this.host + '/api/comments';           // URL to chatter comments
  private flagUrl            = this.host + '/api/flag';               // URL to for flags (hearts, follows)
  private usersUrl           = this.host + '/api/users';              // URL to top users
  private configUrl          = this.host + '/api/user/config';        // URL to user configuration
  private feedUrl            = this.host + '/api/user/feed';          // URL to user feed
  private profileUrl         = this.host + '/api/user/profile';       // URL to user profile
  private settingsUrl        = this.host + '/api/user/settings';      // URL to query store settings=
  private noteUrl            = this.host + '/api/user/notifications'; // URL to user notifications
  private avatarsUrl         = this.host + '/api/avatars';            // URL to avatars
  private badgesUrl          = this.host + '/api/badges';             // URL to badges
  private topicUrl           = this.host + '/api/topic';              // URL for web firebase topics
  private storeItemsUrl      = this.host + '/api/store/items';        // URL to query mp3 items
  private storeSettingsUrl   = this.host + '/api/store/settings';     // URL to query store enabled
  private recommendUrl       = this.host + '/api/recommend';          // URL to query recommendations
  private deleteUrl          = this.host + '/api/user/delete';        // URL to delete user sound
  private uploadUrl          = this.host + '/api/user/upload';        // URL to upload sound
  
  private cachedTags : any;
  private cachedBadges : any[];

  constructor(private http: HttpClient,
              private marketAuth: MarketAuth,
              private inj: Injector)
  {
    if (environment.logging)
    {
        console.log("Created White Noise Market Service");
    }
  }

  // -------------- general queries --------------

  getSounds(limit = 0, offset = 0): Promise<Sound[]>
  {
    let url = this.itemsUrl;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSoundsByTag(tag: string, limit = 0, offset = 0): Promise<Sound[]>
  {
    var url = `${this.itemsUrl}/tag/${tag}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSoundsBySearch(search: string, limit = 0, offset = 0): Promise<Sound[]>
  {
	  // remove whitespace and newline from search string
    search = search.trim();

    var url = `${this.searchUrl}/${search}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  // get user sounds and display name or profile
  getSoundsByUser(user: string, limit = 0, offset = 0): Promise<UserSounds>
  {
    // pull out "DisplayName" that is delivered alongside Results
    var url = `${this.userUrl}/${user}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then( response => { return { DisplayName: this.parseUser(response), Profile: this.parseProfile(response), Sounds: this.parseSounds(response) }; })
               .catch(this.handleError);
  }

  // new: just get the user sounds without the user display name (UserSounds)
  getSoundsByUserId(user: string, limit = 0, offset = 0): Promise<Sound[]>
  {
    var url = `${this.userUrl}/${user}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSound(id: string, slug: string): Promise<Sound>
	{
    if (id == null || id.length == 0) id = "0";
    if (slug == null) slug = "";

    const url = `${this.itemUrl}/${id}?slug=${slug}`;
    console.info('Get sound: ' + url);
    return this.http.get(url)
      .toPromise()
      .then(response => response['Results'][0] as Sound)
      .catch(this.handleError);
  }

  getRecommend(id: string): Promise<Sound[]>
	{
    if (id == null || id.length == 0) id = "0";    

    const url = `${this.recommendUrl}/${id}`;
    console.info('Recommend sound: ' + url);
    return this.http.get(url)
      .toPromise()
      .then(response => this.parseSounds(response))
      .catch(this.handleError);
  }

  getSearches(): Promise<any[]>
  {
    const url = this.searchesUrl;

    return this.http.get(url)
               .toPromise()
               .then((response: { Results: any[] }) => response.Results)
               .catch(this.handleError);
  }

  getGeo(latitude: number = 0, longitude: number = 0, distance: number = 0): Promise<Sound[]>
  {
    let url = this.geoUrl;

    if (latitude || longitude)
    {
      url += "/" + latitude + "/" + longitude;
      if (distance) url += "/" + distance;
    }

    url += "?limit=100";

    //console.log("Market: Executing geo query");
    return this.http.get(url).toPromise()
               .then(response => this.parseSounds(response))
               .catch(this.handleError);
  }

  getTags(): Promise<Tag[]>
  {
    if (this.cachedTags)
    {
      if (environment.logging)
      {
        console.info("Gettings Tags from Cache");
      }
      return Promise.resolve(this.cachedTags);
    }

    if (environment.logging)
    {
        console.info("Gettings Tags from Market");
    }  
  
    return this.http.get(this.tagsUrl)
               .toPromise()
               .then(response => { this.cachedTags = response['Results'] as Tag[]; return this.cachedTags; } )
               .catch(this.handleError);
  }

  getTag(name: string): Promise<Tag>
  {
    return this.getTags().then(tags =>
    {
      for (var i = 0; i < tags.length; ++i)
      {
        var tag = tags[i];
        if (tag.Value == name)
        {
          return tag;
        }
      }

      // return a default tag
      const properName = name.charAt(0).toUpperCase() + name.slice(1);
      var t = new Tag();
      t.Value = name;
      t.Label = properName;

      return t;
    }).catch(this.handleError);
  }

  getComments(id = "", limit = 0, offset = 0): Promise<Comment[]>
  {
    let url = this.commentsUrl;
    if(id) url += "/" + id;

    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit=" + limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset=" + offset;
    }

    return this.http.get(url)
               .toPromise()
               .then(response => response['Results'] as Comment[] )
               .catch(this.handleError);
  }

  getUsers(sort = "", filter = 1, limit = 100): Promise<User[]>
  {
    let url = this.usersUrl;
    url += "?limit=" + limit + "&all=" + filter;

    if (sort.length > 0)
    {
      url += "&sort="+sort;
    }

    return this.http.get(url)
               .toPromise()
               .then(this.parseUsers)
               .catch(this.handleError);
  }

  // -------------- authenticated queries --------------
  getFlags(type: number, sid: string): Promise<Flags>
  {
    console.info("Getting flags: " + sid + " type: " + type);
    if (this.marketAuth.isAuthenticated() == false) {
      return Promise.resolve({} as Flags);
    }

    const url = `${this.flagUrl}/${type}/${sid}`;
    return this.http.get(url, this.marketAuth.headers())
               .toPromise()
               .then( response => this.parseFlags(response['flags'] as Flag[]))
               .catch(this.handleError);
  }

  setFlag(type: number, sid: string, cid: string, value: boolean): Promise<FlagResult>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    var result = {} as FlagResult;
    result.Type = type;
    result.Item1 = sid;
    result.Item2 = cid;
    result.Value = value ? 1 : 0;
    result.Success = false;

    let url = `${this.flagUrl}/${type}/${sid}`;
    if (cid.length > 0) url += "/" + cid;

    console.info("Set flag: [" + type + "][" + sid + "][" + cid + "]=" + value + " url: " + url);
    if (value)
    {
      return this.http.post(url, {}, this.marketAuth.headers())
                .toPromise()
                .then( response => { 
                  result.Success = (response['Result'] === 'OK'); 
                  result.Total = response['Total']; 
                  return result; 
                })
                .catch(this.handleError);
    }
    else
    {
      return this.http.delete(url, this.marketAuth.headers())
          .toPromise()
          .then( response => { 
            result.Success = (response['Result'] === 'OK'); 
            result.Total = response['Total']; 
            return result; 
          })
          .catch(this.handleError);
    }
  }

  addComment(sid: string, cid: string, message: string): Promise<Comment>
  {
    console.info("Add comment: [" + sid + "][" + cid + "] = " + message + " url: " + url);
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    var url = `${this.commentsUrl}/${sid}`;
    if (cid.length > 0) url = url + "/" + cid;

    return this.http.put(url, { message: message, heart: true }, this.marketAuth.headers())
              .toPromise()
              .then( response => response['Result'] as Comment )
              .catch(this.handleError);
  }

  removeComment(sid: string, cid: string): Promise<Comment>
  {
    console.info("Remove comment: [" + sid + "][" + cid + "]");
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    var result = {} as Comment;
    result.SoundUid = sid;
    result.CommentUid = cid;

    const url = `${this.commentsUrl}/${sid}/${cid}`;

    return this.http.delete(url, this.marketAuth.headers())
          .toPromise()
          .then( response => { if (response['Result'] === 'OK') return result; else return null; } )
          .catch(this.handleError);
  }

  getUserSettings(): Promise<any>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    return this.http.get(this.settingsUrl, this.marketAuth.headers())
          .toPromise()
          .then( response => response['Results'] as Setting[])
          .catch(this.handleError);
  }

  putUserSetting(setting: Setting): Promise<any>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    return this.http.post(this.settingsUrl, setting, this.marketAuth.headers())
          .toPromise()
          .then( response => { return response['status'] === 'OK'; } )
          .catch(this.handleError);
  }

  putUserSettings(settings: Setting[]): Promise<any>
  {

    if (this.marketAuth.isAuthenticated() == false) 
    {
      return Promise.reject("Not authenticated");
    }
  
    return this.http.post(this.settingsUrl, settings, this.marketAuth.headers())
          .toPromise()
          .then( response => { return response['status'] === 'OK'})
          .catch(this.handleError);
  }

  getUserConfig(): Promise<any>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    return this.http.get(this.configUrl, this.marketAuth.headers())
          .toPromise()
          .then( response => response['Results'] )
          .catch(this.handleError);
  }

  setUserConfig(config : any): Promise<boolean>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    return this.http.post(this.configUrl, config, this.marketAuth.headers())
          .toPromise()
          .then( response => { return response['status'] === 'OK'; } )
          .catch(this.handleError);
  }

  uploadSound(file: File): Promise<any>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    let user = this.marketAuth.getUser();
    if (!user)
    {
      return Promise.reject("Not authenticated");

    }

    if (!file)
    {
      return Promise.reject("Invalid file");
    }

    // create form data with our upload file
    let formData: FormData = new FormData();
    formData.append("wna", file);

    let url = this.uploadUrl + "/" + user.userId;
    return this.http.post(url, formData, this.marketAuth.headers())
          .toPromise()
          .then(result => { 
            var sound = result as unknown as Sound;
            sound.ShortLabel = sound.Label
            if (sound.ShortLabel.length > 64)
            {
               sound.ShortLabel = sound.ShortLabel.slice(0,61) + '...';
            }
  
            sound.ShortDescription = sound.Description
            if (sound.ShortDescription.length > 200)
            {
              sound.ShortDescription = sound.ShortDescription.slice(0,197) + '...';
            }
            return sound;
          })
          .catch(this.handleError)
  }

  deleteSound(sound: Sound): Promise<any> {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    if (!sound)
    {
      return Promise.reject("Invalid sound");
    }

    let url = this.deleteUrl + "/" + sound.Uid;
    return this.http.get(url, this.marketAuth.headers())
          .toPromise()
          .then( response => { return response['status'] === 'OK'; } )
          .catch(this.handleError);
  }

  terminateAccount() : Promise<boolean> {

    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }

    let url = this.host + "/api/user/terminate";
    return this.http.get(url, this.marketAuth.headers())
      .toPromise()
      .then( response => { return response['status'] === 'OK'; } )
      .catch(this.handleError)
  }
        
  getUserFeed(limit = 0, offset = 0): Promise<Sound[]>
  {
    let url = this.feedUrl;
    if (limit > 0 || offset > 0)
    {
      url += "?";
      if (limit > 0) url += "limit="+limit;
      if (limit > 0 && offset > 0) url += "&";
      if (offset > 0) url += "offset="+offset;
    }
    return this.http.get(url, this.marketAuth.headers())
          .toPromise()
          .then(this.parseSounds)
          .catch(this.handleError);
  }

  getUserProfile(uid : string): Promise<any>
  {
    return this.http.get(this.profileUrl + "/" + uid)
          .toPromise()
          .then( response => response['Profile'] )
          .catch(this.handleError);
  }

  getMeProfile(): Promise<any>
  {
    if (this.marketAuth.isAuthenticated() == false)
    {
      return Promise.reject("Not authenticated");
    }
    return this.http.get(this.profileUrl, this.marketAuth.headers())
          .toPromise()
          .catch(this.handleError);
  }

  getUserNotifications(): Promise<any>
  {
    return this.http.get(this.noteUrl, this.marketAuth.headers())
          .toPromise()
          .then( response => response['Results'] )
          .catch(this.handleError);
  }

  getAvatars(uid: string): Promise<any>
  {
    let url = this.avatarsUrl;
    if (uid.length > 0) url += "/" + uid;
    return this.http.get(url)
          .toPromise()
          .catch(this.handleError);
  }

  getBadges(): Promise<any[]>
  {
    if (this.cachedBadges)
    {
        console.info("Gettings Badges from Cache");
        return Promise.resolve(this.cachedBadges);
    }

    console.info("Gettings Badges from Market");
    return this.http.get(this.badgesUrl)
          .toPromise()
          .then( response => { this.cachedBadges = response['badges']; return this.cachedBadges; } )
          .catch(this.handleError);
  }

  testServer(): Promise<any>
  {
    var url = "https://whitenoisemarket.com/api/test";
    console.log("Testing URL: " + url)
    return this.http.get(url)
          .toPromise()
          .then(response => {return response;} )
          .catch(this.handleError);
  }

  // -------------- gtag analytics ---------

  trackSoundDownload(s: Sound) : void
  {
    this.trackEvent('download', 'sound', s.ShortLabel || s.Label);
  }

  trackStoreClick(storeName: string, s: Sound) : void
  {
    this.trackEvent('store', storeName, s.ShortLabel || s.Label);
  }

  trackEvent(category: string, action: string, label: string) : void
  {
    if (typeof gtag === 'undefined') {
      console.warn("trackEvent - gtag is not set up.");
      return;
    }
    gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  }

  // -------------- helpers --------------

  webify(label: string) : string
  {
    var result = label.toLowerCase();
    // replace illegal chars ,'"()/\? with spaces
    result = result.replace(/,/g, " ");
    result = result.replace(/'/g, " ");
    result = result.replace(/"/g, " ");
    result = result.replace(/\(/g, " ");
    result = result.replace(/\)/g, " ");
    result = result.replace(/\//g, " ");
    result = result.replace(/\\/g, " ");
    result = result.replace(/\?/g, " ");
    // replace all multiple spaces with single space
    result = result.replace(/  +/g, " ");
    // trim spaces from front and back
    result = result.trim();
    // replace spaces with a single dash
    result = result.replace(/ /g, "-");
    // make sure we have something
    if (result === "") result = "null";
    return result;
  }

  // get absolute url for user
  userLink(uid: string) : string
  {    
    if (uid == null || uid == null) return "";
    return this.host + "/user/" + uid;
  }

  // get absolute url for sound
  soundLink(sound: Sound) : string
  {
    var url = "";
    if (sound == null || sound.Uid == null) return url;
    if (sound.Slug == null || sound.Slug.length == 0) 
    {
      // uid version
      url = this.host + "/sound/" + sound.Uid;
    }
    else
    {    
      // full sound slug and id version
      url = this.host + "/sound/" + sound.Slug + "?id=" + sound.Uid;
      //console.log("Sound link: " + url);
    }
    return url;
  }

  // get absolute url for preview
  previewLink(sound: Sound) : string
  {
    if (sound == null || sound.PreviewUrl == null || sound.PreviewUrl.length == 0) return null;
    // if preview link is already absolute then use it
    if (sound.PreviewUrl.startsWith("http")) return sound.PreviewUrl;
    // otherwise call the preview api
    return this.host + "/preview/" + sound.Uid;
  }

  // get absolute url for download
  downloadLink(sound: Sound) : string
  {
    // validate sound to download
    if (sound == null || sound.WhiteNoiseUrl == null || sound.WhiteNoiseUrl.length == 0) return null;

    // validate auth and sound is not an original (doesn't require auth)
    if (this.marketAuth.isAuthenticated() == false)
    {
      if (sound.Tags == null) return null;
      if (sound.Tags.indexOf("original") < 0) return null;
    }

    // create download url with token if autenticated
    var url = this.host + "/download/" + sound.File + "?id=" + sound.Uid;
    if (this.marketAuth.isAuthenticated()) url += "&token=" + encodeURIComponent(this.marketAuth.getToken());
    return url;
  }

  // get absolute link for image
  imageLink(url: string, size: number): string 
  {
    if (url == null || url.length == 0) return "";

    // figure best url to use, either google storage or local api depending on ImageUrl format
    let photoUrl = url;

    // if url starts with a / it is assumed to be a reference to the local photo api
    // otherwise assume the url is a resolvable on its own
    if (photoUrl.startsWith("/")) 
    {
      // construct url to local api based on configured host
      if (this.host.startsWith("http")) 
      {
        photoUrl = `${this.host}${photoUrl}`;
      } 
      else 
      {
        // localhost should use http otherwise assume https
        let proto = this.host.includes("localhost") ? "http:" : "https:";
        photoUrl = `${proto}${this.host}${photoUrl}`;
      }
    }

    // append size params if included
    if (size > 0) 
    {
      // deprecated: google's sizing params are =s${size}-c
      if (photoUrl.includes("google")) 
      {
        photoUrl += `=s${size}-c`;
      }
      else 
      {
        if (!photoUrl.endsWith('?')) 
        {
          photoUrl += '?';
        }

        // local sizing is standard url params
        photoUrl += `width=${size}&height=${size}`;
      }
    }
    return photoUrl;
  }

  getStoreItems() : Promise<any> {
    return this.http.get(this.storeItemsUrl)
                    .toPromise()
                    .then(this.parseSounds)
                    .catch(this.handleError);
  }

  getStoreSettings() : Promise<any>
  {
    return this.http.get(this.storeSettingsUrl)
                    .toPromise()
                    .then(this.parseSettings)
                    .catch(this.handleError);
  }

  subscribeTopic(token: string, topic: string) 
  {
    let url = `${this.topicUrl}/${token}/${topic}/1`;
    return this.http.post(url, {}, this.marketAuth.headers())
          .toPromise()
          .then( response => response['Result'] )
          .catch(this.handleError);
  }

  unsubscribeTopic(token: string, topic: string) 
  {
    let url = `${this.topicUrl}/${token}/${topic}/0`;
    return this.http.post(url, {}, this.marketAuth.headers())
          .toPromise()
          .then( response => response['Result'] )
          .catch(this.handleError);
  }

  // in this case the obj should be a Tag or a User
  public async toggleFollow(obj: any, type: number) : Promise<FlagResult>
  {
     // tag in this case can be a user uid if following a user
      if (!obj || obj == null) 
      {
          // not a valid tag
          return Promise.reject("invalid follow object");
      }

      if (!this.marketAuth.isAuthenticated())
      {
        // not authenticated
        this.marketAuth.showLogin();
        return Promise.reject("not authenticated");
      }

      return new Promise<FlagResult>((resolve, reject) =>
      {
        let id = "";
        if (type == 1)
        {
          id = obj.Uid;
        }
        else if (type == 2)
        {
          id = obj.Value;
        }
        else if (type == 3)
        {
          id = obj.userId;
        }

        if (id.length == 0)
        {
          reject("invalid follow id");
          return;
        }
        
        const value = !this.isFollowed(obj, type);
        this.setFlag(type, id, "", value)
            .then(result => 
            {
              console.log("Follow flag set: ", result);
              if (result && result.Success) 
              {
                // add or remove favorite from logged in user if success and subscribe topic
                let messaging = this.inj.get(MessagingService);
                if (value)
                {
                  this.marketAuth.addFavorite(obj, type);
                  if (type > 1) messaging.subscribeToTopic(id);
                }
                else
                {
                  this.marketAuth.removeFavorite(obj, type);
                  if (type > 1) messaging.unsubscribeFromTopic(id);
                }
                resolve(result);
              }
              else
              {
                reject("Server error setting flag");
              }
            })
            .catch(err => 
            {
              console.error("Error setting follow flag: ", err);
              reject(err);
            });
      });
  
      /*
      console.log("onFollow tag: ", tag);
      if (this.messagingService.isSubscribedToTopic(tag) == false) 
      {
          // follow topic on messaging service
          this.messagingService.subscribeToTopic(tag);
          this.isFollowed = true;
      } 
      else 
      {
        // unfollow topic on messaging service
        this.messagingService.unsubscribeFromTopic(tag);
        this.isFollowed = false;
      }
      */
  }

  // in this case a tag can be a category or user id
  public isFollowed(obj: any, type: number): boolean
  {
      if (!obj || obj == null)
      {
        return false;
      }

      if (!this.marketAuth.isAuthenticated())
      {
          // not authenticated
          return false;
      }

      let profile = this.marketAuth.getUserProfile();
      if (!profile || profile == null)
      {
        // has not fully loaded from login
        return false;
      }

      if (type == 1)
      {
        let id = obj.Uid;
        let sounds = profile.Favorites.Tags as Sound[];
        return sounds.find(s => s.Uid == id) != null || obj.UserHeart;
      }
      else if (type == 2)
      {
        let id = obj.Value;
        let tags = profile.Favorites.Tags as Tag[];
        return tags.find(t => t.Value == id) != null;
      } 
      else if (type == 3)
      {
        let id = obj.userId;
        let users = profile.Favorites.Users as User[];
        return users.find(u => u.userId == id) != null;
      }
      return false;
  }

  private parseSettings(response: any)
  {
    if (response && response["result"]==true)
    {
      let results = response["settings"];
      //console.log("DEBUG MarketIO Response="+JSON.stringify(results));
      return results;
    }
    else
    {
      return Array();
    }
  }

  openAdminSite() : void
  {
    window.open(environment.admin, '_blank'); // in new tab
  }

  private parseUser(response: any)
  {
      var json = response;
      var user = json.DisplayName;
      if (user == undefined || user.length == 0) user = "White Noise";
      return user;
  }

  private parseProfile(response: any)
  {
    var json = response;
    var profile = json.Profile;
    return profile;
  }

  private parseSounds(response: any) : Sound[]
  {
      //console.log("Parsing sounds: ", response);
      var json = response;
      var sounds = json.Results as Sound[];

      // create shorter label and descriptions
			sounds.forEach(function(sound)
			{
				  sound.ShortLabel = sound.Label
				  if (sound.ShortLabel.length > 64)
				  {
					   sound.ShortLabel = sound.ShortLabel.slice(0,61) + '...';
				  }

				  sound.ShortDescription = sound.Description
				  if (sound.ShortDescription.length > 200)
				  {
					  sound.ShortDescription = sound.ShortDescription.slice(0,197) + '...';
          }
			});

      return sounds;
  }

  private parseFlags(flags : Flag[]): Flags
  {
    var result = {} as Flags;
    if (flags && flags.length > 0)
    {
      // conver to dictionary
      for (var i = 0; i < flags.length; ++i)
      {
        var flag = flags[i];
        result[flag.Item] = (flag.Value > 0);
      }
    }
    return result;
  }

  private parseUsers(response: any)
  {
      var json = response;
      var users = json.Results as User[];
      return users;
  }

  private handleError(err: any): Promise<any>
  {
    console.error('An error occurred', err);

    // pull out the most specific error message possible (market returns error.Message and firebase returns error.message)
    var msg : string = null;
    if (err.error)
    {
        msg = err.error.Message || err.error.message;
    }
    if (msg == null)
    {
        msg = err.Message || err.message || err.statusText;
    }
    if (msg == null)
    {
        msg = "Unknown";
    }

    // pass along the best error message to the application
    return Promise.reject(msg);
  }

}
