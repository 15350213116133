// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content
{
  padding: 20px;
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

.child-content {
	width:95%;
	height:100%;
	margin-left:auto;
	margin-right:auto;
	min-height: 20vh;
}

.text-container {
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 11pt;
}

.center-container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.login-button {
	color: white;
	background-color: #3744AF;
}

.delete-button {
	color: white;
	background-color: #3744AF;
}

.cancel-button {
	color: white;
	background-color: #8d8d8d;
}

.delete-confirm-button {
	color: white;
	background-color: #b11414;
}`, "",{"version":3,"sources":["webpack://./src/app/account/delete.component.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,+CAA+C;AACjD;;AAEA;CACC,SAAS;CACT,WAAW;CACX,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,iBAAiB;CACjB,kBAAkB;CAClB,kBAAkB;CAClB,eAAe;AAChB;;AAEA;CACC,UAAU;CACV,iBAAiB;CACjB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;CACC,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;CACC,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;CACC,YAAY;CACZ,yBAAyB;AAC1B","sourcesContent":[".content\n{\n  padding: 20px;\n  font-family: Roboto,\"Helvetica Neue\",sans-serif;\n}\n\n.child-content {\n\twidth:95%;\n\theight:100%;\n\tmargin-left:auto;\n\tmargin-right:auto;\n\tmin-height: 20vh;\n}\n\n.text-container {\n\twidth: 70%;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\ttext-align: center;\n\tfont-size: 11pt;\n}\n\n.center-container {\n\twidth: 90%;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\ttext-align: center;\n}\n\n.login-button {\n\tcolor: white;\n\tbackground-color: #3744AF;\n}\n\n.delete-button {\n\tcolor: white;\n\tbackground-color: #3744AF;\n}\n\n.cancel-button {\n\tcolor: white;\n\tbackground-color: #8d8d8d;\n}\n\n.delete-confirm-button {\n\tcolor: white;\n\tbackground-color: #b11414;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
