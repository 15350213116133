<mat-card appearance="outlined" *ngIf="title">
  <mat-card-header>
    <mat-card-title><strong>{{title}}</strong></mat-card-title>
    <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content></mat-card-content>
</mat-card>

<ad-playwire type="leaderboard_atf" selectorId="desk_map_leaderboard_atf"></ad-playwire>

<div class="content">

  <!-- new google map-->
  <div class="map-container" *ngIf="apiLoaded | async">
    <google-map width="100%" height="700px" [center]="center" [zoom]="zoom" [options]="mapOptions"
      (boundsChanged)="mapMoved($event)">
      <map-marker #marker="mapMarker" *ngFor="let sound of sounds; let ix = index"
        [position]="{lat: sound.Location.Lat, lng: sound.Location.Lng}" [options]="markerOptions"
        (mapClick)="showMarkerInfo(marker, ix, sound)">
        <map-info-window>
          <div style="max-width: 400px">
            <a href="/sound/{{ sound.Slug }}?id={{ sound.Uid }}" target="_blank">
              <img src="/assets/images/loading_photo_96.jpg" alt="{{sound.Label}}" width="96" height="96"
                [lazyLoad]="this.marketService.imageLink(sound.ImageUrl, 96)" (onLoad)="lazyLoaded(sound)"
                style="float: left; margin: 0 12px 4px 0;">
            </a>
            <h1>{{ sound.Label }}</h1>
            {{ sound.Description }}
          </div>
          <div style="text-align:right; padding: 8px 8px 16px 8px">
            <strong>
              <a href="/sound/{{ sound.Slug }}?id={{ sound.Uid }}" target="_blank">View Details</a>
            </strong>
          </div>
        </map-info-window>
      </map-marker>
    </google-map>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

  <p class="footer">
    Upload Recordings to the World Map using the
    <!-- <a href="http://app.tmsoft.com/whitenoisemarket/">Market App</a>. -->
    <a href="/app" routerLink="/app">Market App</a>.
    It's FREE!
  </p>
</div>