<h1 mat-dialog-title>
  <!-- wrapping login icon in divs like this was only way I could find to properly center and align the icon with the header text -->
  <div style="display: inline-block; position: relative; width: 29px; height: 24px;">
    <div style="position: absolute; left: 0; top: 2px; font-size: 30px" class="material-icons">account_circle</div>
  </div>
  Login to Market
</h1>
<mat-dialog-content class="login-dialog">
  Login to download White Noise sounds for FREE, download MP3s, send hearts, and to chat with other users. <small><a href="/help" target="_blank" tabindex="-1">More Info</a></small>
  <mat-tab-group #tabGroup class="tab-group">
    <mat-tab label="Social Network Login" [disabled]="working">      
      <div class="tab-container flex-column-center">
        <button mat-button class="btn-facebook" (click)="login('facebook')" tabindex="-1" [disabled]="working">
            <span class="btn-fa"><fa-icon style="font-size: 20px;" [icon]="facebookIcon"></fa-icon></span> &nbsp;
            <span class="btn-label">Facebook Login</span>
        </button>
        <button mat-button class="btn-google" (click)="login('google')" tabindex="-1" [disabled]="working">
          <span class="btn-fa"><fa-icon style="font-size: 20px" [icon]="googleIcon"></fa-icon></span> &nbsp;
          <span class="btn-label">Google Login</span>
      </button>
      <button mat-button class="btn-email" (click)="showEmail()" tabindex="-1" [disabled]="working">
        <span class="btn-fa"><fa-icon style="font-size: 20px" [icon]="emailIcon"></fa-icon></span> &nbsp;
        <span class="btn-label">Login with Email</span>
      </button>
        <!--<small class="text-muted">Logging in with Facebook or Google does not give us access to post as you.</small>-->
      </div>
      <!--</mat-dialog-actions>-->
    </mat-tab>
    <mat-tab label="E-mail Login" [disabled]="working">
      <form class="tab-container flex-column-center">
        <!--fxLayout.gt-xs="row"-->
        <mat-form-field class="email-field">
          <input matInput placeholder="Email" id="email" name="email" [(ngModel)]="email" tabindex="0" [disabled]="working">
        </mat-form-field>
        <mat-form-field class="email-field">
          <input matInput type="password" placeholder="Password" id="password" name="password" [(ngModel)]="password" tabindex="0" [disabled]="working">
        </mat-form-field>
        <button mat-button class="btn-email" (click)="login('email')" tabindex="-1" [disabled]="working">
          <span class="btn-fa"><fa-icon style="font-size: 20px" [icon]="emailIcon"></fa-icon></span> &nbsp;
          <span class="btn-label">Login with Email</span>
        </button>
        <br>
        <span><a (click)="showSignup()">Create account?</a> <a (click)="showResetPassword()" style="float:right">Forgot password?</a> </span>
      </form>
      <!--</mat-dialog-actions>-->
    </mat-tab>
    <mat-tab label="E-mail Signup" [disabled]="working || disableCreate">
        <form class="tab-container flex-column-center">
            <mat-form-field class="email-field">
              <input matInput placeholder="Email" id="email" name="email" [(ngModel)]="email" tabindex="0" [disabled]="working || disableCreate">
            </mat-form-field>
            <mat-form-field class="email-field">
              <input matInput type="password" placeholder="Password" id="password" name="password" [(ngModel)]="password" tabindex="0" [disabled]="working || disableCreate">
            </mat-form-field>
            <mat-form-field class="email-field">
                <input matInput type="password" placeholder="Confirm Password" id="password2" name="password2" [(ngModel)]="password2" tabindex="0" [disabled]="working || disableCreate">
            </mat-form-field>
            <button mat-button class="btn-email" (click)="createEmail()" tabindex="-1" [disabled]="working || disableCreate">
              <span class="btn-fa"><fa-icon style="font-size: 20px" [icon]="emailIcon"></fa-icon></span> &nbsp;
              <span class="btn-label">Create Account</span>
            </button>
          </form>
    </mat-tab>
    <mat-tab label="Reset Password" *ngIf="showReset" [disabled]="working || disableReset">
        <form class="tab-container flex-column-center">
            <mat-form-field class="email-field">
              <input matInput placeholder="Email to Reset" id="email" name="email" [(ngModel)]="email" tabindex="0" [disabled]="working || disableReset">
            </mat-form-field>
            <button mat-button class="btn-reset" (click)="resetPassword()" tabindex="-1" [disabled]="working || disableReset">
              <span class="btn-fa"><fa-icon style="font-size: 20px" [icon]="emailIcon"></fa-icon></span> &nbsp;
              <span class="btn-label">Reset Password</span>
            </button>
          </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div>
  <br>
  <hr>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
<div *ngIf="info">
    <span style="color: blue">{{info}}</span>
</div>
<div class="error" *ngIf="error">
  <span>{{error}}</span>
</div>

<mat-dialog-actions class="flex-column-center">
  <button mat-button mat-dialog-close #cancelButton class="cancel-button" tabindex="-1" [disabled]="working">Cancel</button>
</mat-dialog-actions>

