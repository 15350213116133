import { Component, OnInit } from '@angular/core';

// services
import { MarketAuth } from '..//services/marketauth.service';

@Component({
    selector: 'ad-tmsoft',
    styleUrls: ['../app.component.css'],
    template: `
    <div *ngIf="showAd">
        <div class="hide-lt-700" style="text-align: center; overflow: hidden; width: 94%; margin: auto;">  
            <iframe src='//p.tmsoft.com' frameborder='0' scrolling='no' width='728' height='90' style='margin-top: 15px; border: none;  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);'>
            </iframe>
        </div>
        <div class="show-lt-700" style="text-align: center; overflow: hidden; width: 94%; margin: auto;">  
            <iframe src='//p.tmsoft.com/?w=332' frameborder='0' scrolling='no' width='332' height='45' style='margin-top: 5px; border: none;  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);'>
            </iframe>
        </div>   
    </div>
    `,
    standalone: false
})
export class AdTmsoftComponent
{    
    public showAd: boolean = true;

    constructor(protected marketAuth: MarketAuth)
    {        
    }

    public ngOnInit(): void 
    {            
        let user = this.marketAuth.getUser();
        let subscriber = user && user.isSubscriber;
        this.showAd = !subscriber;   
    }
}


