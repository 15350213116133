import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { Comment } from './comment';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';

@Component({
  selector: 'market-chatter',
  templateUrl: './chatter.component.html',
  styleUrls: [ './chatter.component.css']
})
export class ChatterComponent implements OnInit
{
    constructor(private marketService: MarketService,
                private router: Router,
                private metaService: MetaService)
    {
      const metatitle = "Chatter - White Noise Market";
      const description = "Latest comments from the White Noise Market community.";
      const image = "/assets/images/white-noise-market.png";

      // update title and meta data
      this.metaService.updateMetadata(metatitle, description, image);
    }

    comments: Comment[];
    title = "Recent Chatter";
    subtitle = "Latest comments from the White Noise Market community.";
    offset = 0;
    limit = 50;
    working = false;

    ngOnInit()
    {
        this.working = true;
        this.marketService.getComments()
            .then(comments => { this.working = false; this.comments = comments;})
            .catch(err => { this.working = false; console.log(err) });
    }

    // moved to pipes
    /*
    getTrimmedSoundLabel(comment: Comment): string
    {
        const max_length = 26;
        if (comment.SoundLabel.length > max_length) return comment.SoundLabel.substring(0, (max_length - 2)) + "...";
        return comment.SoundLabel;
    }

    getTrimmedComment(comment: Comment): string
    {
        // remove urls first
        var msg = comment.Message.replace(this.regex, " ");
        // trim to 160 chars
        if (msg.length < 160) return msg;
        return msg.substring(0, 160) + '…';
    }
    */

    gotoUser(comment : Comment): void
    {
        this.router.navigate(['/user', comment.UserUid]);
    }

    gotoSound(comment : Comment): void
    {
        console.log("nav to sound with uid " + comment.SoundUid);
        var slug = this.marketService.webify(comment.SoundLabel);
        this.router.navigateByUrl("/sound/" + slug + "?id=" + comment.SoundUid);
    }

    lazyLoaded(comment: Comment): void
    {
        console.log("Chatter lazy loaded image for sound: " + comment.SoundLabel);
        // disable loading more chatter for now until we figure out router issue
        /*
        if (comment == this.comments[this.comments.length - 1])
        {
            this.offset += this.limit;
            this.marketService.getComments("", this.limit, this.offset).then(comments => {
                Array.prototype.push.apply(this.comments, comments);
                this.cdRef.markForCheck();
                this.cdRef.detectChanges();
            });
        }
        */
    }
}
