/* src/app/login.dialog.css */
.login-dialog {
  max-width: 600px;
}
.tab-container {
  min-height: 350px;
  height: 100%;
  overflow: hidden;
}
.tab-container > * {
  width: 80%;
  margin-top: 16px;
  align-items: center;
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border: 1px solid #335190;
  min-height: 50px;
}
.btn-facebook:hover,
.btn-facebook:focus {
  color: #fff;
  background-color: #294786;
}
.btn-google {
  color: #fff;
  background-color: #4285F4;
  border: 1px solid #4285F4;
  min-height: 50px;
}
.btn-google:hover,
.btn-google:focus {
  color: #fff;
  background-color: #3073e2;
}
.btn-email {
  color: #fff;
  background-color: #1f3195;
  border: 1px solid #1f3195;
  min-height: 50px;
}
.btn-email:hover,
.btn-email:focus {
  color: #fff;
  background-color: #103090;
}
.btn-fa {
  vertical-align: middle;
  line-height: normal;
  display: inline-block;
}
.fa-icon {
  display: inline-block;
  vertical-align: middle;
}
.btn-label {
  display: inline-block;
  white-space: normal;
}
.btn-reset {
  color: #fff;
  background-color: #e44444;
  border: 1px solid #e44444;
  min-height: 44px;
}
.btn-reset:hover,
.btn-reset:focus {
  color: #fff;
  background-color: #e23030;
}
.email-field {
  color: #000;
}
.cancel-button {
  color: white;
  background-color: #A0A0A0;
  min-height: 44px;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error {
  inline-size: 525px;
  overflow-wrap: break-word;
  color: red;
}
/*# sourceMappingURL=login.dialog.css.map */
