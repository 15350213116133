/* src/app/badges.component.css */
.badge {
  width: 200px;
  max-width: 80vw;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  outline: blue;
}
.badges-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/*# sourceMappingURL=badges.component.css.map */
