<div class="tags">
	<mat-nav-list>
		<mat-list-item *ngFor="let tag of tags" (click)="gotoTag(tag)">			
			<img matListItemAvatar 
			src="/assets/images/loading_photo_40.jpg"
			height="40" width="40"
			alt="{{tag.Label}}"
			[lazyLoad]="this.marketService.imageLink(tag.ImageUrl, 40)" 
			(onLoad)="lazyLoaded(tag)">
			<h3 matListItemTitle> {{tag.Label}} </h3>
		</mat-list-item>
	</mat-nav-list>
</div>