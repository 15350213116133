import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { MarketService } from "../market.service";
import { Sound } from "../sound";

@Component({    
    templateUrl: "./sound-add-dialog.component.html", 
})
export class SoundAddDialogComponent
{
    constructor(
        private market: MarketService,
        private dialogRef: MatDialogRef<SoundAddDialogComponent>
    ){ }

    private file: File = null;
    public errorMsg = "";
    public working = false;

    fileChange(event) 
    {
        var fileList: FileList = event.target.files;
        if (fileList.length > 0) 
        {
            console.log("selected file " + fileList[0].name);
            this.file = fileList[0];
            this.errorMsg = "";
        }
    }
    
    onSubmit(): void
    {        
        if (this.working)
        {
            console.error("Already uploading wna file");
            return;
        }

        console.log("sending file " + (this.file == null ? "null" : this.file.name));
        
        if(!this.file)
        {
            this.errorMsg = "Please select a file to attach.";
            return;
        }

        this.working = true;
        this.market.uploadSound(this.file)
        .then(result => {
            console.log("Send file result: ", result);
            this.dialogRef.close(result);
        })
        .catch (err => {
            console.error("Send file error: ", err);
            this.working = false;
            this.errorMsg =  "An error occurred uploading file, please try again.";
            if (err && err.length > 0) this.errorMsg = err;
        });
    }
}