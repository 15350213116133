import { Component, OnInit } from "@angular/core";

//import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { Sound } from './sound';
import { MarketService } from './market.service';
import { MarketAuth } from "./marketauth.service";

@Component({    
    selector: 'sound-download',
    templateUrl: 'sound-download.dialog.html',
    styleUrls: [ './sound-download.dialog.css' ]
})
export class SoundDownloadDialog implements OnInit
{
    public sound : Sound;
    public downloadUrl: string;
    public working = true;
    public downloaded : number = 0;
    public ad : boolean = true;

    constructor(
        private marketService: MarketService,
        private marketAuth: MarketAuth
        //private dialogRef: MatDialogRef<SoundDownloadDialog>
    )
    {
        // ads
        let user = this.marketAuth.getUser();
        let subscriber = user && user.isSubscriber;
        if (subscriber)
        {
            this.ad = false;
            //console.log("Not showing ad");
        }
    }

    ngOnInit(): void
    {
        this.downloadUrl = this.marketService.downloadLink(this.sound);

        var that = this;
        setTimeout(function()
        {
            that.download();
        }, 2000);
    }

    download(): void
    {
       if (this.downloadUrl && this.downloaded < 2)
       {
         console.log("Download sound " + this.sound.Uid);

         var a = document.createElement('a');
         a.setAttribute("href", this.downloadUrl);
         a.setAttribute("download", this.sound.File);
         document.body.appendChild(a);
         a.click();
         document.body.removeChild(a);

         // stop progress bar after a second
         var that = this;
         setTimeout(function()
         {
             that.working = false;
         }, 1500);

         this.downloaded += 1;
       }
       else
       {
           console.error("Download finished");
       }
    }
}
