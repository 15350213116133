export class Sound
{
  Uid: string;
  Label: string;
  Description: string;
  ImageUrl: string;
  Slug: string;
  File: string;
  Tags: string;
  Stats:
  {
    Downloads: number;
    Hearts: number;
    Comments: number;
    Minutes: number;
  }
  PreviewUrl: string;
  WhiteNoiseUrl: string;
  ITunesUrl: string;
  SpotifyUrl: string;
  PlayUrl: string;
  AmazonUrl: string;
  RhapsodyUrl: string;
  YouTubeUrl: string;
  UploadDate: string;
  RecordDate: string;
  UserHeart: boolean;

  // user and avatar
  DisplayName: string;
  AvatarUrl: string;

  // configured by market site:
  AppleMusicUrl: string;
  
  // configured by the service:
  ShortLabel : string;
  ShortDescription : string;

  // tw: flag for mp3 store
  Purchasable: boolean;

/*
  public ShortLabel() : string
  {
    return this.Label;
  }

  public ShortDescription() : string
  {
      return this.Description;
  }
*/
}

export class UserSounds
{
  DisplayName: string;
  Profile: any;
  Sounds: Sound[];
}