// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-container
{
    background-color: #f0f0f0;
    padding-top:8px;
    padding-bottom:8px;
    min-height:90px;
}`, "",{"version":3,"sources":["webpack://./src/app/ad-playwire.component.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".ad-container\r\n{\r\n    background-color: #f0f0f0;\r\n    padding-top:8px;\r\n    padding-bottom:8px;\r\n    min-height:90px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
