import { Component, AfterViewInit } from '@angular/core'

@Component({
    selector: 'ad-adsense-tile',
    template: `<ins class="adsbygoogle"
    style="display:block"
    data-ad-format="fluid"
    data-ad-layout-key="+3c+rf+4k-75-z"
    data-ad-client="ca-pub-8934170658251200"
    data-ad-slot="1480780129"></ins>`,
})

export class AdAdSenseTileComponent implements AfterViewInit 
{
    constructor() {}

    ngAfterViewInit() 
    {        
        try 
        {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            console.log("Loaded ads by google for tile ad");
        }
        catch (e) 
        {
            console.error("Ad Tile Error", e);
        }
    }
}