/* src/app/avatar/avatar.component.css */
.avatar {
  position: relative;
  display: inline-block;
  padding: 10%;
  line-height: normal;
}
.avatar-image {
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}
.avatar-badge {
  position: absolute;
  right: 0px;
  top: 0px;
}
/*# sourceMappingURL=avatar.component.css.map */
