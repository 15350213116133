<h2 mat-dialog-title>
  <i class="fa fa-download fa-lg"></i> Download {{sound.Label}}
</h2>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

<mat-dialog-content class="dialog-download">

  <div *ngIf="ad">
    <p>Bandwidth for this download provided by:</p>
    <ad-playwire type="leaderboard_atf" selectorId="desk_download_leaderboard_atf"></ad-playwire>
  </div>

  <p>If your download does not automatically start in 3 seconds, then click <a href="javascript:void(0)"
      (click)="download()" id="downloadLinkAnchor" tabindex="-1">here</a>.
  <p>Download our new native <a routerLink="/app" routerLinkActive="active" tabindex="-1">Market App</a> for an
    improved experience
    including faster speed, download management, and a new chatter view that shows the latest conversations.</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close style="color: white; background-color: #A0A0A0;">Close</button>
</mat-dialog-actions>