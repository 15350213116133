import { Component, OnInit } from '@angular/core';
import { Router }   from '@angular/router';

// services
import { MarketService } from './services/market.service';

@Component({
    selector: 'market-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.css'],
    standalone: false
})

export class BadgesComponent implements OnInit
{
    title = "Badges";
    subtitle = "White Noise Market User Badges"
    data : any[];
    avatar = "/assets/images/default_badge.jpg";
    working = false;

    constructor(private marketService: MarketService,
                private router: Router)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.marketService.getBadges().then(data => this.setData(data)).catch(err => this.setError(err));
    }

    setData(data: any[])
    {
      this.working = false;
      this.data = data;
    }

    avatarError(image : any): void
    {
      image.onerror = null;
      image.src = "/assets/images/default_badge.jpg";
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

 }
