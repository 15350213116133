/* src/app/sound-detail.component.css */
.padding {
  padding-left: 10pt;
  padding-right: 10pt;
}
.stats {
  display: inline-flex;
  vertical-align: middle;
  line-height: 20px;
}
.stat {
  min-width: 30px;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
}
.stats-icon {
  vertical-align: middle;
}
.tab-icon {
  margin-right: 8px;
}
.download-link-image {
  width: 124px;
  height: 44px;
}
.spinner {
  margin: 0 auto;
}
.sound-description {
  font-weight: bold;
}
.sound-display > img {
  width: 80vw;
  height: 80vw;
  max-width: 512px;
  max-height: 512px;
}
.mp3-button {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}
.flex-content {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.tiles-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 200px;
  max-width: 80vw;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  outline: blue;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .flex-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/*# sourceMappingURL=sound-detail.component.css.map */
