<div>
    <!-- ad -->
    <ng-template #ShowAd>
        <mat-card appearance="outlined" class="sound">
            <ad-playwire type="med_rect_btf"></ad-playwire>
        </mat-card>
    </ng-template>
    <div style="margin: 16px;">
        <button mat-flat-button color="primary" (click)="onUpload()" *ngIf="isMyFeed()">
            <mat-icon class="material-icons" fontIcon="add" aria-hidden="true"></mat-icon>
            &nbsp;Add Sound
        </button>
    </div>
    <!-- tiles of sounds -->
    <div class="sounds-flex">
        <div *ngFor="let sound of sounds">
            <mat-card appearance="outlined" *ngIf="sound.Label; else ShowAd" class="sound" itemscope itemtype="http://schema.org/MediaObject" (click)="gotoSound(sound)">
                <mat-card-header>
                    <mat-card-title><span itemprop="name">{{sound.ShortLabel}}</span></mat-card-title>
                    <span class="overflow" *ngIf="isMyFeed()">
                        <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                            <mat-icon class="material-icons-dark" fontIcon="more_vert" aria-hidden="true"></mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="onDeleteSound(sound)">
                                <mat-icon class="material-icons-dark" fontIcon="delete" aria-hidden="true"></mat-icon>
                                Delete Sound
                            </button>
                        </mat-menu>
                    </span>
                </mat-card-header>
                <img mat-card-image width="300" height="300" 
                        [defaultImage]="'/assets/images/loading_photo_300.jpg'"
                        [errorImage]="'/assets/images/default_photo.jpg'"
                        [lazyLoad]="this.marketService.imageLink(sound.ImageUrl, 300)"
                        (onStateChange)="onImageStateChange($event, sound)"
                        [offset]="500" itemprop="image"
                        [alt]="sound.Label" />
                <mat-card-content>                    
                    <link itemprop="url" [href]="safeSoundLink(sound)" />                    
                    <span class="nowrap">
                        <span class="stat" title="Hearts">
                            <mat-icon class="material-icons-dark stats-icon" fontIcon="favorite" aria-hidden="true"></mat-icon>
                            {{sound.Stats.Hearts || 0 | shortnumber:0}}
                        </span>
                        <span class="stat" title="Comments">
                            <mat-icon class="material-icons-dark stats-icon" fontIcon="comment" aria-hidden="true"></mat-icon>
                            <span itemprop="commentCount">{{sound.Stats.Comments || 0 | shortnumber:0}}</span>
                        </span>
                        <span class="stat" title="Downloads">
                            <mat-icon class="material-icons-dark stats-icon" fontIcon="download" aria-hidden="true"></mat-icon>
                            {{sound.Stats.Downloads || 0 | shortnumber:0}}
                        </span>
                        <span class="stat" title="Minutes Listened">
                            <mat-icon class="material-icons-dark stats-icon" fontIcon="headphones" aria-hidden="true"></mat-icon>
                            {{sound.Stats.Minutes || 0 | shortnumber:0}}
                        </span>
                    </span>
                    <p itemprop="description">{{sound.ShortDescription}}</p>                    
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
    <!-- no sounds mesage -->
    <div class="message-container" *ngIf="!working && sounds?.length == 0">
        <p class="message">{{empty}}</p>
    </div>

    <!-- ad footer -->
    <!-- <ad-playwire *ngIf="sounds?.length > 0"></ad-playwire> -->
    
</div>