// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-column-Note
{
  vertical-align: top;
  padding: 10px;
  width: 100%;  
}

.mat-column-Image
{
  vertical-align: top;
  padding: 10px;
}

 /* Highlight the entire row on hover and change cursor to pointer */
table tr:hover 
{
  background-color: #f0f0f0; /* Change to your desired highlight color */
  cursor: pointer;
}

.row-image
{    
  width: 64px;
  height: 64px;
}

.row-message
{  
  font-weight: normal;
}
.row-date
{
  padding-top: 10px;  
  color: #888;
}`, "",{"version":3,"sources":["webpack://./src/app/notifications.component.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;;EAEE,mBAAmB;EACnB,aAAa;AACf;;CAEC,mEAAmE;AACpE;;EAEE,yBAAyB,EAAE,2CAA2C;EACtE,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,mBAAmB;AACrB;AACA;;EAEE,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".mat-column-Note\n{\n  vertical-align: top;\n  padding: 10px;\n  width: 100%;  \n}\n\n.mat-column-Image\n{\n  vertical-align: top;\n  padding: 10px;\n}\n\n /* Highlight the entire row on hover and change cursor to pointer */\ntable tr:hover \n{\n  background-color: #f0f0f0; /* Change to your desired highlight color */\n  cursor: pointer;\n}\n\n.row-image\n{    \n  width: 64px;\n  height: 64px;\n}\n\n.row-message\n{  \n  font-weight: normal;\n}\n.row-date\n{\n  padding-top: 10px;  \n  color: #888;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
