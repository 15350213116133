<h2 mat-dialog-title>Personal Use License Agreement</h2>
  <mat-dialog-content>
    <p>
      By placing an online order for these products you agree to be bound by the terms of this purchase agreement. You are purchasing a personal use license to the audio files included with your purchase. Personal use is defined as private, non-public use. The audio file purchased, or its derivatives, may not be used without explicit permission for any public use, commercial or non-commercial. Public use includes the use of audio, in whole, in part, or as derivative in any work shared to social media or media hosting platforms including, but not limited to YouTube, Soundcloud, Spotify, Bandcamp, etc. Public use is expressly forbidden. This license does not grant public performance rights. All rights to public performance are withheld.  Unless explicitly stated otherwise, as between you (the purchaser) and TMSOFT, LLC, TMSOFT, LLC. owns all right and title to the audio downloaded, regardless of format.
    </p>
    <p>
      <strong>Limitations</strong><br/>
      This license expressly forbids any unauthorized inclusion of the content in any library (e.g. sample instrument, sound effects library, sfx tool kit for a production music company, etc.) online or offline without our express written consent. The license also forbids any re-distribution method of the content, through any means, including but not limited to, re-selling, trading, sharing, resampling, mixing, processing, isolating, or embedding into software or hardware of any kind, for the purpose of re-recording or reproduction as part of any free or commercial library of musical and/or sound effect samples and/or articulations, or any form of musical sample or sound effect sample playback system or device.
    </p>
    <p>
      <strong>Derivatives</strong><br/>
      All derivatives of licensed audio files, whether created in part or whole of the file licensed by this agreement, either by mixing, editing, processing or any other means remain bound by the terms and restrictions of this Agreement.
    </p>
    <p>
      <strong>Fair Use</strong><br/>
      Fair use is granted only as explicitly defined in Section 107 of the Copyright Act. Fair use is granted only for use purposes such as criticism, comment, news reporting, teaching, scholarship, or research.  As per Section 107, fair use will be determined by the following factors:
    </p>
  <ol>
    <li>the purpose and character of the use, including whether such use is of a commercial nature or is for nonprofit educational purposes;</li>
    <li>the nature of the copyrighted work;</li>
    <li>the amount and substantiality of the portion used in relation to the copyrighted work as a whole; and</li>
    <li>the effect of the use upon the potential market for or value of the copyrighted work.</li>
  </ol>
  <p> While fair use is granted as required by law, public use, as defined in the Personal License Agreement, is expressly prohibited.</p>
  <p>
    <strong>Term</strong><br/>
    The license is Lifetime of the named Licensee.
  </p>
  <p>
    <strong>© 2020-2025 TMSOFT, LLC. All rights reserved.</strong>
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close style="color: white; background-color: #3744AF;">OK</button>
</mat-dialog-actions>