<mat-card appearance="outlined" *ngIf="title">
  <mat-card-header>
      <mat-card-title><strong>{{title}}</strong></mat-card-title>
      <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>
      <img mat-card-avatar [src]="avatar" *ngIf="avatar" (error)="avatarError($event.target);">
  </mat-card-header>
</mat-card>

<div class="badges-flex">
  <div *ngFor="let badge of data">
    <mat-card appearance="outlined" class="badge">
      <mat-card-header>        
        <mat-card-title><strong>{{badge.Label}}</strong></mat-card-title>      
      </mat-card-header>
      <img mat-card-image [src]="badge.ImageUrl" width="200" height="200">
      <mat-card-content>
          <div itemprop="description">{{badge.Description}}</div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>