import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { MarketService } from './market.service';
import { MarketAuth } from './marketauth.service';
import { MetaService } from './meta.service';
import { MessagingService } from './messaging.service';
import { User } from './user';

@Component({
  selector: 'market-user',
  templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})

export class UserComponent
{
    // information displayed in html
    title: string;                  // optional title displayed at top of page
    subtitle: string;              // optional subtitle displayed at top of page
    avatar : string;               // optional avatar displayed at top of page
    description: string;           // optional description displayed at top of page
    profile : any;                 // optional user profile for displaying stats
    badges : any[];                // optional array of badges from user profile    
    working = false;               // displays working progress bar if true

    // following / flag support
    followTag : string;            // tag to follow when hearting a category or user
    followFlag : number;           // flag type either 2 or 3 (category or user)
    isFollowed = false;            // true if subscribed to topic (local check)
    
    // user id for this page
    userId : string;
  
    constructor(protected marketService: MarketService,
                protected marketAuth: MarketAuth,
                protected metaService: MetaService,
                protected messagingService: MessagingService,
                protected router: Router,
                protected route: ActivatedRoute,
                protected snackBar: MatSnackBar)
    {
        const title = "White Noise Sounds by User - White Noise Market";   
        const description = "Download user created sounds to White Noise for free.";
        const image = "/assets/images/white-noise-market.png";

        // update title and meta data
        this.metaService.updateMetadata(title, description, image);    

        // parse user from route
        this.route.params.subscribe((params: Params) => { 
            this.setUser(params['user'] as string) ;
        });
    }

      // called after ngInit
  private userSubscription: any; // keep track of observable so it can be removed later
  public ngOnInit()
  {
    // subscribe for auth updates
    this.userSubscription = this.marketAuth.user.subscribe(() => this.refreshFollow());
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) 
    {
      this.userSubscription.unsubscribe();
    }
  }

    private setUser(userId: string)
    {
        console.log("User Component requesting user sounds: " + userId);
        this.userId = userId;

        // pull in user profile
        this.marketService.getUserProfile(this.userId).then(response => 
        {   
          this.setProfile(response);
          
          // setup for following
          this.followTag = userId; // users use the user id for flag
          this.followFlag = 3; // users are type 3
          this.refreshFollow();
        });
    }

    private setProfile(profile : any)
    {
        console.log("User profile received:", profile);
        this.profile = profile;
        this.avatar = profile.avatarUrl;
        this.setTitle(profile.displayName);

        // parse user description
        if (this.profile.userDescription)
        {
            this.subtitle = this.profile.userDescription;
        }

        // parse user badges
        if (this.profile.badges)
        {
            // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
            this.marketService.getBadges().then(data => this.parseBadges(data, this.profile.badges)).catch(err => this.handleBadgeError(err));
        }
    }

    private setTitle(user : string)
    {
        this.title = "Sounds by " + user;
        this.subtitle = "Sounds uploaded by " + user + " to the White Noise Market."
        
         // setup meta data
        const title = "White Noise Sounds by " + user + " - White Noise Market";
        const description = "Sounds uploaded by " + user + " to the White Noise Market.  Download and listen to user created sounds with the White Noise App by TMSOFT.";

        // update title and meta data
        this.metaService.updateTitle(title);
        this.metaService.updateDescription(description);
    }

    private parseBadges(all : any[], badges : string) : void
    {
        if (all == null || all.length == 0 || badges == null || badges.length == 0) return;

        let result = [];
        
        for (var i = 0; i < all.length; ++i) 
        {
            let a : any = all[i];
            // {"Id":"web","Label":"Web World","ImageUrl":"https://img.tmsoft.com/badges/web.png","Description":"Login using the White Noise Market website"}
            if (badges.includes(a.Id))
            {
                // add user badge to front of array as the items at the end are more important badges (tougher to get)
                result.unshift(a);
            }
        }
        
        this.badges = result;
        //console.info("Returning total user badges: " + this.badges.length);
    }

    private handleBadgeError(error : any) : void
    {
      console.info("Server had error requesting badges: " + JSON.stringify(error));
    }

    refreshFollow()
    {
      this.isFollowed = this.marketService.isFollowed(this.profile, this.followFlag);
    }
    
    onFollow(user: User) 
    {
      this.marketService.toggleFollow(user, this.followFlag)
          .then( (result) => this.isFollowed = Boolean(result.Value))
    }
    
    showMessage(msg : string): void 
    {
      this.snackBar.open(msg, null, { duration: 3000, verticalPosition: "bottom" });
    }

}