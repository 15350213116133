/* src/app/sounds/sounds.component.css */
.sound {
  width: 300px;
  max-width: 80vw;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  outline: blue;
  cursor: pointer;
}
.sounds-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sound-stats {
  display: inline-flex;
  vertical-align: middle;
  line-height: 20px;
}
.stat {
  min-width: 30px;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
}
.ad-container {
  height: 475px;
  width: 100%;
  display: block;
}
.stat-user {
  min-width: 50px;
  text-align: left;
  margin-left: 0px;
  margin-right: 15px;
  font-size: 16px;
}
.stats-icon {
  vertical-align: middle;
  font-size: 24px;
}
.spinner {
  margin: 0 auto;
}
.spacer {
  width: 300px;
  padding: 0 24px;
  margin: 0 10px;
  height: 0px;
}
.overflow {
  position: absolute;
  top: 6px;
  right: -12px;
  display: inline-block;
  vertical-align: middle;
}
.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100%;
}
.message {
  padding: 25px;
  text-align: center;
  max-width: 500px;
}
/*# sourceMappingURL=sounds.component.css.map */
