/* src/app/store/cart/checkout.component.css */
.store-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.store-buttons button {
  margin-left: 10px;
  min-width: 150px;
}
.content {
  padding: 20px;
}
.child-content {
  width: 95%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 20vh;
}
.center-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.table-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
table {
  width: 100%;
}
tr.mat-mdc-footer-row {
  font-weight: normal;
}
td.title-cell {
  width: auto;
}
.promo-container {
  display: block;
  float: right;
  min-width: 216px;
  margin: 16px;
}
.promo-info {
  margin-left: auto;
  margin-right: auto;
  float: right;
  font-size: 10pt;
  text-align: center;
  vertical-align: center;
}
.promo-error {
  color: red;
  margin-left: auto;
  margin-right: auto;
  font-size: 10pt;
  text-align: center;
  vertical-align: center;
}
.promo {
  display: block;
  padding: 16px 0 16px 0;
}
.cart-item-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 1px;
  vertical-align: middle;
  margin-right: 5px;
}
.checkout-container {
  display: block;
  float: right;
  text-align: center;
  width: 100%;
  margin: 0 0 16px 0;
}
.checkout-container #paypal-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  max-width: 300px;
  text-decoration: none;
}
a:visited,
a:link {
  text-decoration: none;
  color: #428bca;
}
a:hover {
  color: #428bca;
  text-decoration: underline;
}
.blue-button {
  color: white;
  background-color: #3744AF;
}
.spinner {
  margin-left: auto;
  margin-right: auto;
}
td.mat-mdc-cell {
  border-bottom-style: none;
}
th.mat-mdc-header-cell {
  border-bottom-style: none;
}
tr.cart-hdr {
  height: 40px;
  background-color: #3744AF;
}
.cart-hdr-cell {
  font-size: 9pt;
  color: white;
}
.details-row {
  height: 60px;
  border-bottom-style: none;
  border-style: none;
}
.mat-column-name {
  width: auto;
}
.mat-column-duration {
  min-width: 60px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
}
.mat-column-price {
  width: 100px;
  min-width: 75px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
}
.mat-column-remove {
  width: 40px;
  text-align: center;
  vertical-align: center;
}
/*# sourceMappingURL=checkout.component.css.map */
