// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card
{
    width: 80%;
    max-width: 80vw;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    outline: blue;    
}
.content {
  padding: 15px;
  white-space: normal; /* Allow content to wrap */
  word-wrap: break-word; /* Break long words to wrap */
  overflow-wrap: break-word; /* Ensure long words break correctly */
}`, "",{"version":3,"sources":["webpack://./src/app/token.component.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;AACA;EACE,aAAa;EACb,mBAAmB,EAAE,0BAA0B;EAC/C,qBAAqB,EAAE,6BAA6B;EACpD,yBAAyB,EAAE,sCAAsC;AACnE","sourcesContent":[".card\n{\n    width: 80%;\n    max-width: 80vw;\n    display: inline-block;\n    margin: 10px;\n    vertical-align: top;\n    outline: blue;    \n}\n.content {\n  padding: 15px;\n  white-space: normal; /* Allow content to wrap */\n  word-wrap: break-word; /* Break long words to wrap */\n  overflow-wrap: break-word; /* Ensure long words break correctly */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
