import { Component, OnInit } from '@angular/core';
import { TMApiService } from '../tmapi.service';
import { MarketService } from '../../market.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CartAddDialog } from '../cart/cart-add.dialog';

@Component({
  selector: 'app-store',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css'],
})
export class CatalogComponent implements OnInit {

  working=false;
  empty=false;
  statusMessage="";

  player: HTMLAudioElement;
  previewId: string = "";

  // mp3 sound data
  catalogItems: any[] = [];
  catalogColumns: string[] = ["image", "sound", "preview", "action"];

  constructor(
    public tmapi: TMApiService,
    public market: MarketService,
    private router: Router,
    private snack: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.statusMessage = "Loading...";
    this.working = true;
    this.tmapi.checkStoreAvailable()
                    .then(res => {
                      this.working = false;
                      this.continueInit();
                    })
                    .catch (err => {
                      this.handleError(err);
                      this.continueInit();
                    });
  }

  continueInit() {
    // update or clear status message
    if (!this.tmapi.isStoreEnabled())
    {
      this.statusMessage = this.tmapi.getDisabledMessage();
    }
    else
    {
      this.statusMessage = "";
      this.getCatalog();
    }
  }

  addCart(sound: any): void {
    console.log("Open dialog for sound: ", sound);

    let ref = this.dialog.open(CartAddDialog);
    ref.componentInstance.sound = sound;
    ref.afterClosed()
      .subscribe(result => {
        if (result && result.value) {
          let duration = result.value as number;
          if (duration > 0) {
            let hours = duration / 60 / 60;
            let msg = sound.Label + " ("+hours + (hours==1 ? " hour" : " hours") + ") added to cart.";
            this.snack.open(msg, "View Cart", { duration: 5000, verticalPosition: "top" })
              .onAction().subscribe(()=> {
                // navigate to cart
                this.router.navigateByUrl('/store/checkout');
              });
          }
        }
      });
  }

  goSound(sound: any): void {
    let url = `/sound/${sound.Slug}?id=${sound.Uid}`;
    this.router.navigateByUrl(url);
  }

  onPlayPause(sound: any): void {
    if (this.previewId !== sound.Uid) {
      this.stopAudio();

      this.previewId = sound.Uid;
      let src = this.market.previewLink(sound);

      this.player = new Audio(src);
      this.player.addEventListener("ended", () => this.stopAudio());
      this.player.addEventListener("error", () => this.stopAudio());
      this.player.play();
    } else {
      // just stop without changing
      this.stopAudio();
    }
  }

  private stopAudio() {
    if (this.player) {
      this.player.removeAllListeners();
      this.player.pause();
      this.player.src = "";
      this.player = null;
    }
    this.previewId = "";
  }

  private async getCatalog() {
    this.working = true;
    return this.market.getStoreItems()
      .then(data => this.setCatalogData(data))
      .catch(error => this.handleError(error));
  }

  private setCatalogData(data: any) {
    console.log("Got mp3 sounds: ", data);
    this.catalogItems = data;
    this.empty == (!this.catalogItems || this.catalogItems.length == 0);
    this.working = false;
  }

  handleError(error: any) : void{
    console.error("ERROR: Problem fetching mp3 sounds: " + error);
    this.working=false;
  }
}
