<mat-card appearance="outlined" *ngIf="title">
  <mat-card-header>
    <mat-card-title><strong>{{title}}</strong></mat-card-title>
    <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content></mat-card-content>
</mat-card>

<form class="search-form">
  <mat-form-field class="search-input">
    <input matInput placeholder="Search" name="search" [(ngModel)]="search" maxlength="50">
  </mat-form-field>  
  <button mat-button (click)="onSearch()" color="primary" type="submit" class="search-button">
    <mat-icon>search</mat-icon>
  </button>
</form>

<!-- search trends -->
<div *ngIf="trends && trends.length > 0 && results == null" class="search-trends">
  <div *ngFor="let trend of trends" class="search-trend">
    <a href="/search/{{trend?.Term}}" [routerLink]="['/search', trend?.Term]">{{trend?.Term}}</a>
  </div>
</div>
<br><br>
<!-- search results -->
<div class="search-results">
  <div *ngIf="results?.length == 0">
    <p>Search resulted in no sounds.</p>
    <br>
  </div>
  <div class="table-container" *ngIf="results?.length > 0">
    <table mat-table [dataSource]="this.results">
      <ng-container matColumnDef="image">
        <td mat-cell *matCellDef="let item" (click)="goSound(item)" class="table-cell">
          <img class="item-image" alt="{{item.name}} Photo" width="64" height="64"
            [defaultImage]="'/assets/images/loading_photo_64.jpg'" [errorImage]="'/assets/images/default_photo.jpg'"
            [lazyLoad]="item.ImageUrl" />
        </td>
      </ng-container>
      <ng-container matColumnDef="sound">
        <td mat-cell *matCellDef="let item" (click)="goSound(item)" class="table-cell">
          <span><strong [innerHTML]="item.Label | highlight: search"></strong></span><br />
          <span class="muted" [innerHTML]="item.ShortDescription | highlight: search"></span>
          <br><br>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let item; columns: columns;"></tr>
    </table>
  </div>
  <br><br><br>
  <ad-playwire type="leaderboard_btf" selectorId="desk_search_leaderboard_btf"></ad-playwire>
</div>