import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TMApiService } from '../tmapi.service';
import { MarketService } from '../../market.service';
import { MarketAuth } from '../../marketauth.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
})
export class OrdersComponent implements OnInit {

  working=false;
  downloading=false;
  empty=false;
  authorized=false;
  orderParam: string = "";
  orderData: any[];
  downloadData: any[];
  statusMessage="";

  orderColumnIds: string[] = ['date', 'total', 'id'];
  detailColumnIds: string[] = ['name', 'duration', 'download'];
  expandedOrder: any | null;

  // keep track of observable so it can be removed later
  private userSubscription: any;

  constructor(
    public tmapiService: TMApiService,
    public marketService: MarketService,
    private marketAuth: MarketAuth,
    private route: ActivatedRoute,
    ) { }

  ngOnInit()
  {
    this.authorized = this.marketAuth.isAuthenticated();

    this.statusMessage = "Loading...";
    this.working = true;
    this.tmapiService.checkStoreAvailable()
                    .then(res => {
                      this.working = false;
                      this.continueInit();
                    })
                    .catch (err => {
                      this.handleError(err);
                      this.continueInit();
                    });
  }

  ngOnDestroy()
  {
    // remove observable
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  continueInit() {

    // update or clear status message
    if (!this.tmapiService.isStoreEnabled())
    {
      this.statusMessage = this.tmapiService.getDisabledMessage();
    }
    else
    {
      this.statusMessage = "";
    }

    // Route parameters
    this.route.params.subscribe( params => {

      this.orderParam = params && params.orderId ? params.orderId : "";

      // subscribe for auth updates; orders are retrieved after user updates
      this.userSubscription = this.marketAuth.user.subscribe(user => this.userUpdated(user));
    });
  }

  userUpdated(user: any)
  {
    if (!this.tmapiService.isStoreEnabled())
    {
      return;
    }

    this.authorized = (user != null);
    if (this.authorized)
    {
      this.working = true;
      this.tmapiService.getOrders("")
                      .then(data => this.setData(data))
                      .catch(err => this.handleError(err));
    }
  }

  setData(data: any[]) : void
  {
    this.working=false;
    this.orderData = data;
    this.empty = this.orderData.length == 0;
    this.expandedOrder = this.findOrder(this.orderParam);
  }

  findOrder(orderId: string) {
    if (this.orderData && orderId)
    {
      for (var i = 0; i < this.orderData.length; i++)
      {
        let order = this.orderData[i];
        if (orderId === order.order_id)
        {
          return order;
        }
      }
    }
  }

  handleError(error: any) : void
  {
    this.working=false;
    console.error("ERROR: Problem fetching orders: " + error);
  }

  onDownload(sound: any): void
  {
    // this snippet tries to force save as dialog from resource url
    let downloadId = sound["DownloadId"];

    let downloadUrl = this.tmapiService.getDownloadUrl(downloadId);
    let name = sound["Slug"]+"-"+sound["Duration"]+".mp3";
    if (downloadUrl && downloadUrl.length > 0)
    {
      var a = document.createElement('a');
      a.href = downloadUrl;
      a.download = name;
      a.target="_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    /*
    this.downloading=true;
    let downloadId = sound["DownloadId"];
    let name = sound["Slug"]+"-"+sound["Duration"]+".mp3";
    */

    /*
    this.tmapiService.getDownloadUrl(downloadId)
                    .then(response=> this.startDownload(response, name))
                    .catch(this.handleError);
    */

    /*
    this.tmapiService.getDownloadBlob(downloadId)
                        .then(response=> this.startDownload(response, name))
                        .catch(this.handleError);
    */
  }

  startDownload(response: any, name: string): void
  {

    if (response)
    {
          // this snippet downloads as a blob first, then prompts to save
      /*
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([response], { type: "application/octet-stream" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.target = "_blank";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100)
        */

        // this snippet tries to force save as dialog from resource url
        var downloadUrl = response["downloadUrl"];
        if (downloadUrl && downloadUrl.length > 0)
        {
          var a = document.createElement('a');
          a.href = downloadUrl;
          a.download = name;
          a.target="_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
    }

   this.downloading=false;
  }
}
