<button mat-button (click)="goBack()">Back</button>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

<div *ngIf="sound">

  <mat-card appearance="outlined" itemscope itemtype="http://schema.org/MediaObject">
      <mat-card-header>
        <!--<img mat-card-avatar src="{{sound.AvatarUrl}}" (error)="avatarError($event.target);">-->
        <mat-card-title><strong itemprop="name">{{sound.Label}}</strong></mat-card-title>
        <mat-card-subtitle>Posted by <a routerLink="/user/{{ sound.UserUid }}"><span itemprop="author" itemscope itemtype="http://schema.org/Person">
          <span itemprop="name">{{sound.DisplayName}}</span></span></a> on <meta itemprop="datePublished" content="{{ sound.UploadDate | date: 'yyyy-MM-dd' }}">{{ sound.UploadDate | date: 'MM/dd/yyyy' }}</mat-card-subtitle>
        <div mat-card-avatar *ngIf="sound.AvatarUrl">
            <app-avatar [url]="sound.AvatarUrl" [size]=42 [uid]="sound.UserUid" [isAdmin]="sound.IsAdmin" [isSubscriber]="sound.IsSubscriber" [isModerator]="sound.IsModerator"></app-avatar>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="flex-content">
          <div class="padding">
            <span *ngIf="youTubeLink" class="sound-display">
                <iframe class="youtube-player" type="text/html" width="560" height="315" [src]="youTubeLink"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </span>
            <div *ngIf="!youTubeLink" class="sound-display">
              <img src="{{this.marketService.imageLink(sound.ImageUrl, 512)}}">
            </div>
            <link itemprop="image" [href]="safeImageLink(sound)" />
            <!--
            <span class="stats">
              <i class="fa fa-heart-o fa-lg heart-noselect" *ngIf="!sound.UserHeart"></i>
              <i class="fa fa-heart fa-lg heart-select" *ngIf="sound.UserHeart"></i>
              <span class="stat">{{sound.Stats.Hearts}} Hearts</span>
              <i class="material-icons">chat</i><span class="stat">{{sound.Stats.Comments}} Comments</span>
              <i class="material-icons">file_download</i><span class="stat">{{sound.Stats.Downloads}} Downloads</span>
              <i class="material-icons">headset</i><span class="stat">{{sound.Stats.Minutes}} Minutes</span>
            </span>
            -->
            <br>
            <a (click)="toggleHeart()" class="nowrap">
              <mat-icon class="material-icons heart-noselect stats-icon" fontIcon="favorite_border" aria-hidden="true" *ngIf="!marketAuth.isAuthenticated() || !this.isFollowed"></mat-icon>
              <mat-icon class="material-icons heart-select stats-icon" fontIcon="favorite" aria-hidden="true" *ngIf="marketAuth.isAuthenticated() && this.isFollowed"></mat-icon>
              <div class="arrow_box">{{ sound.Stats.Hearts || 0 | number }} heart<span *ngIf="sound.Stats.Hearts != 1">s</span><span *ngIf="sound.Stats.Hearts == 1">&nbsp;&nbsp;</span></div>
            </a>
            <a (click)="showComments()" class="nowrap">
                <mat-icon class="material-icons stats-icon" fontIcon="comments" aria-hidden="true"></mat-icon>
                <div class="arrow_box">{{ sound.Stats.Comments || 0 | number }} comment<span *ngIf="sound.Stats.Comments != 1">s</span></div>
            </a>
            <a (click)="download()" *ngIf="sound.WhiteNoiseUrl" class="nowrap">
                <mat-icon class="material-icons stats-icon" fontIcon="download" aria-hidden="true"></mat-icon>
                <div class="arrow_box">{{ sound.Stats.Downloads || 0 | number }} download<span *ngIf="sound.Stats.Downloads != 1">s</span></div>
            </a>
            <a (click)="showMinutes()" class="nowrap">
              <mat-icon class="material-icons stats-icon" fontIcon="headphones" aria-hidden="true"></mat-icon>
              <div class="arrow_box">{{ sound.Stats.Minutes || 0 | number }} minute<span *ngIf="sound.Stats.Minutes != 1">s</span></div>
            </a>
            <br /><br />
          </div>
          <div class="padding">

              <!-- Sound Description -->
              <span itemprop="description">
                <span class="sound-description" innerHtml="{{ sound.Description | linkify }}"></span>
                <br><br>
                <span *ngIf="sound.WhiteNoiseUrl">
                  In order to download and playback {{ sound.Label }}, please install the latest version of the
                    <a href="http://app.tmsoft.com/whitenoiselite/" target="_blank">White Noise</a> app, and then tap the Download to White Noise button below.
                </span>
                <span *ngIf="sound.ITunesUrl || sound.SpotifyUrl || sound.PlayUrl || sound.AmazonUrl">
                  {{ sound.Label }} is also available for purchase or streaming from your favorite digital music store.
                </span>
              </span>
              <br /><br />

              <!-- Sound Tags -->
              <div *ngIf="tags">Tags:
                <span itemprop="keywords">
                    <span *ngFor="let tag of tags; let last = last">
                      <a routerLink="/tag/{{ tag.Value }}">{{ tag.Label }}</a><span *ngIf="!last">, </span>
                    </span>
                    </span>
                  <br /><br />
              </div>

              <!-- Additional Sound Info -->
              <div *ngIf="sound.Channels == 2">
                Format: Stereo
                <br /><br />
              </div>

              <!-- Audio Preview -->
              <div *ngIf="previewLink">
                Audio Preview:
                <br /><br />
                <audio controls [src]="previewLink" type="audio/mpeg"></audio>
                <meta itemprop="contentUrl" [content]="previewLink" />
                <br /><br />
              </div>

              <!-- Download to White Noise -->
              <div *ngIf="sound.WhiteNoiseUrl">
                Download to White Noise:
                <br />
                <a (click)="download()">
                  <img class="download-link-image" src="/assets/images/button-white-noise-app.png" alt="Download to White Noise by TMSOFT"/>
                </a>
                <br /><br />
              </div>

              <!-- TMSOFT Direct Purchase -->
              <div *ngIf="sound.Purchasable">
                Purchase downloadable MP3:
                <br/>
                <button class="mp3-button" (click)="addCartDialog()"><img class="download-link-image" src="/assets/images/button-buy-mp3.png" alt="Purchase MP3"/></button>
                <br/><br/>
              </div>

              <!-- Music Stores -->
              <div *ngIf="sound.ITunesUrl || sound.SpotifyUrl || sound.PlayUrl || sound.AmazonUrl || sound.YouTubeUrl || sound.RhapsodyUrl">
                Stream or purchase online:
                <br />
                <a *ngIf="sound.AmazonUrl" [href]="sound.AmazonUrl" wnm-tclick wnm-type="store" wnm-category="amazon" target="_blank" (click)="trackStoreClick('amazon')">
                  <img class="download-link-image" src="/assets/images/button-amazon-music.png" alt="Listen on Amazon Music"/>
                </a>
                <a *ngIf="sound.AppleMusicUrl" [href]="sound.AppleMusicUrl" wnm-tclick wnm-type="store" wnm-category="apple" target="_blank" (click)="trackStoreClick('apple')">
                  <img class="download-link-image" src="/assets/images/button-apple-music.png" alt="Listen on Apple Music"/>
                </a>
                <a *ngIf="sound.SpotifyUrl" [href]="sound.SpotifyUrl" wnm-tclick wnm-type="store" wnm-category="spotify" target="_blank" (click)="trackStoreClick('spotify')">
                  <img class="download-link-image" src="/assets/images/button-spotify.png" alt="Listen on Spotify"/>
                </a>
                <a *ngIf="sound.PlayUrl" [href]="sound.PlayUrl" wnm-tclick wnm-type="store" wnm-category="play" target="_blank" (click)="trackStoreClick('play')">
                  <img class="download-link-image" src="/assets/images/button-youtube-music.png" alt="Listen on YouTube Music"/>
                </a>                
                <a *ngIf="sound.YouTubeUrl" [href]="sound.YouTubeUrl" wnm-tclick wnm-type="store" wnm-category="youtube" target="_blank" (click)="trackStoreClick('youtube')">
                  <img class="download-link-image" src="/assets/images/button-youtube.png" alt="Watch on YouTube"/>
                </a>
                <!--
                <a *ngIf="sound.RhapsodyUrl" [href]="sound.RhapsodyUrl" wnm-tclick wnm-type="store" wnm-category="napster" target="_blank" (click)="trackStoreClick('napster')">
                  <img class="download-link-image" src="/assets/images/button-napster.png" alt="Listen on Napster"/>
                </a>
                -->
                <!--
                <a *ngIf="sound.ITunesUrl" [href]="sound.ITunesUrl" wnm-tclick wnm-type="store" wnm-category="itunes" target="_blank" (click)="trackStoreClick('itunes')">
                  <img class="download-link-image" src="/assets/images/button-apple-itunes.png" alt="Purchase on iTunes"/>
                </a>
                -->
                <br /><br />
              </div>
                            
              <div style="max-width: 100%">                
                Sharing is caring:                                                
                <share-buttons                                  
                  [include]="['x','facebook','pinterest','reddit','copy']"
                  [title]="sound.Label"
                  [description]="sound.Description"
                  [url]="this.marketService.soundLink(sound)"
                  [image]="this.marketService.imageLink(sound.ImageUrl, 0)"
                  [showIcon]="true"
                  [showText]="true">
                </share-buttons>
               <br><br>
              </div>              
          </div>
        </div>

        <br>

        <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">comments</mat-icon>
              Comments
            </ng-template>
            <market-comments id="marketComments" [sound]="sound" [flags]="flags"></market-comments>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">listen</mat-icon>
              Similar Sounds
            </ng-template>
            <div class="tiles-flex">
              <div *ngFor="let sound of similarSounds">
                <mat-card appearance="outlined" class="card" (click)="gotoSound(sound)" itemscope itemtype="http://schema.org/MediaObject">                  
                  <link itemprop="url" [href]="safeSoundLink(sound)" />
                  <img mat-card-image
                    width="200"
                    height="200"
                    [defaultImage]="'/assets/images/loading_photo_300.jpg'"
                    [errorImage]="'/assets/images/default_photo.jpg'"
                    [lazyLoad]="this.marketService.imageLink(sound.ImageUrl, 300)"                    
                    [offset]="500"
                    itemprop="image">
                  <mat-card-content>
                    <p itemprop="description">{{sound.ShortLabel}}</p>
                  </mat-card-content>
                </mat-card>
              </div>
              <!-- creates a number of empty spacer elements so uneven last row of grid is left-aligned (empty items have no height, so extra rows of spacers take no space -->
              <div *ngFor="let spacer of [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]" class="spacer"></div>
            </div>
            <br>
            <br>
            <br>
          </mat-tab>
        </mat-tab-group>        
      </mat-card-content>
  </mat-card>
  <ad-playwire type="leaderboard_btf" selectorId="desk_sound_leaderboard_btf"></ad-playwire>
</div>
