import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// services
import { Comment } from './services/comment';
import { Flags, FlagResult } from './services/flag';
import { Sound } from './services/sound';
import { MarketService } from './services/market.service';
import { MarketAuth } from './services/marketauth.service';

// dialogs
import { ConfirmDialog } from './confirm.dialog';


// lightweight class for helping sort and organize comment threads
class CommentThread
{
    constructor(comment: Comment, replyLevel: number)
    {
        this.Comment = comment;
        this.ReplyLevel = replyLevel;
        this.Replies = [];
    }
    Comment: Comment;
    Replies: CommentThread[];
    ReplyLevel: number;
}


@Component({
    selector: 'market-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css'],
    standalone: false
})

export class CommentsComponent
{
    @Input()
    sound: Sound;

    @Input()
    flags: Flags;

    // pulled from sound
    sid: string;

    // querying for list of comments
    querying: boolean = false;
    comments: Comment[];
    commentThreads: CommentThread[];

    // comment to add
    adding: boolean = false;
    message: string = '';
    // use separate var for reply so text doesnt appear in 2 places at once
    replyMessage: string = '';

    // last "reply" form that was visible
    lastReplyForm: HTMLFormElement;

    // comment uids already replied to (cannot reply again until component reload), can set "" to disable top-level comments too
    repliesSent = {};

    constructor(private router: Router,
                private marketService: MarketService,
                public marketAuth: MarketAuth,
                private dialog: MatDialog) { }

    ngOnChanges()
    {
        this.sid = this.sound.Uid;
        this.getComments();
        this.updateHearts();
    }

    getComments(): void
    {
        // only run one query
        if (this.querying == false && this.sid != undefined && this.sid.length > 0)
        {
            this.querying = true;
            this.marketService.getComments(this.sid).then(comments => {
                this.comments = comments;
                this.buildCommentThreads();
                this.updateHearts();
            } );
        }
    }

    gotoUser(comment : Comment): void
    {
      this.router.navigate(['/user', comment.UserUid]);
    }

    avatarError(image : any): void
    {
        //console.warn("Failed to load avatar for comment");
        image.onerror = null;
        image.src = "/assets/images/default_user.jpg";
    }

    updateHearts(): void
    {
        // need both comments and flags
        if (this.flags == null || this.comments == null)
        {
            //console.log("Updating comment hearts - not yet");
            return;
        }

        // update comment hearts if we have hearts
        for (var i = 0; i < this.comments.length; ++i)
        {
            var comment = this.comments[i];
            comment.UserHeart = this.flags[comment.CommentUid];
        }
    }

    toggleHeart(comment : Comment)
    {
        if (this.marketAuth.isAuthenticated() == false)
        {
            this.marketAuth.showLogin();
            return;
        }

        console.log("Toggle comment heart: " + comment.CommentUid);
        this.marketService.setFlag(1, this.sid, comment.CommentUid, !comment.UserHeart)
                     .then( result => { this.updateHeart(result); } )
                     .catch( err => { console.error("Error toggle comment heart: " +err); });
    }

    updateHeart(result : FlagResult)
    {
        console.info("Update hearts: ", result);
        if (result.Success)
        {
            // update our flags
            if (this.flags)
            {
                this.flags[result.Item2] = result.Value > 0;
            }

            // find comment and update
            for (var i = 0; i < this.comments.length; ++i)
            {
                var comment = this.comments[i];
                if (comment.CommentUid == result.Item2)
                {
                    comment.UserHeart = result.Value > 0;
                    comment.Hearts = result.Total;
                    break;
                }
            }
        }
    }

    addComment(replyTo: Comment)
    {
        let replyUid = replyTo ? replyTo.CommentUid : "";
        let message = (replyTo ? this.replyMessage : this.message).trim();
        console.log("Adding comment" + (replyTo ? ("(ReplyUid=" + replyUid + ")") : "") + ": " + message);

        if (this.marketAuth.isAuthenticated() == false)
        {
            this.marketAuth.showLogin();
            return;
        }
        else if (this.adding)
        {
            return;
        }
        this.adding = true;

        // should disable submit in template, but check in code to be extra sure
        if (message.length < 10)
        {
            console.error("Comment must be at least 10 chars to post.");
            return;
        }

        // should also disable submit in template, but check in code to be extra sure
        if (this.repliesSent[replyUid])
        {
            console.error("Message already sent for replyUid="+replyUid);
            return;
        }

        // flag that user has sent a reply to this comment (or '' for top-level comment), disabling subsequent posts
        this.repliesSent[replyUid] = true;

        this.marketService.addComment(this.sid, replyUid, message)
                        .then( comment => {
                                this.comments.unshift(comment);
                                this.buildCommentThreads();
                                this.message = "";
                                this.replyMessage = "";
                                this.adding = false;
                                this.sound.Stats.Comments += 1;
                        })
                        .catch( err => {
                            console.warn("Failed to add comment: " + err);
                            this.repliesSent[replyUid] = false;
                            this.adding = false;
                        });
    }

    removeComment(id : string)
    {
        console.log("Remove comment: " + id);

        let options = {
            data: {
              title: "Delete Comment",
              message: "Do you want to delete this comment?",
            }
         };

        this.dialog.open(ConfirmDialog, options)
                   .afterClosed()
                   .subscribe(result => {
                        if (!result) return;

                        this.marketService.removeComment(this.sid, id)
                        .then( comment => { this.removeCommentInList(comment.CommentUid); } )
                        .catch( err => { console.warn("Failed to remove comment: " + err); this.adding = false; });
                    });
    }

    removeCommentInList(id : string)
    {
        // find comment and update
        for (var i = 0; i < this.comments.length; ++i)
        {
            var comment = this.comments[i];
            if (comment.CommentUid == id)
            {
                this.comments.splice(i, 1);
                this.sound.Stats.Comments -= 1;
                break;
            }
        }

        this.buildCommentThreads();
    }

    showReplyComment(replyForm: HTMLFormElement)
    {
        this.replyMessage = "";

        if(this.lastReplyForm == replyForm)
        {
            if(replyForm.classList.contains("hide-display"))
                replyForm.classList.remove("hide-display");
            else
                replyForm.classList.add("hide-display");
            return;
        }

        if(this.lastReplyForm)
            this.lastReplyForm.classList.add("hide-display");

        this.lastReplyForm = replyForm;
        replyForm.classList.remove("hide-display");
    }

    // re-inits the commentThreads array based on the comments array data
    buildCommentThreads(): void
    {
        // work with a copy of the comments array so we can remove items as we process them
        let c = this.comments.slice();

        let newThreads: Array<CommentThread> = []; // becomes this.commentThreads at end of method
        let threadsByUid = {}; // each key is a uid, value is a CommentThread, used for simpler lookups

        // begin by adding all top-level comments (those without a ReplyUid) to newThreads, removing them from c[] as they're added
        for (let i = 0; i < c.length; i++)
        {
            if(c[i].ReplyUid) continue;

            // add top-level comment to newThreads (and threadsByUid)
            let newThread = new CommentThread(c[i], 0);
            newThreads.push(newThread);
            threadsByUid[c[i].CommentUid] = newThread;

            // remove each comment as it is placed in newThreads
            c.splice(i, 1);
            i--;
        }

        let lastLength: number; // tracks whether or not each do-while iter actually removed any items (otherwise an orphaned comment would create infinite loop)
        do
        {
            lastLength = c.length;
            for (let i = 0; i < c.length; i++)
            {
                // try to find a comment thread matching the replyUid, if we can't find it, skip for now
                let parentThread = threadsByUid[c[i].ReplyUid] as CommentThread;
                if (!parentThread) continue;

                //// might be better to do this in the view than the data
                // if(parentThread.ReplyLevel >= 2) // don't let comments go deeper than 2 levels by making any deeper threads direct children of their grandparent thread
                // {
                //     let grandparentThread = threadsByUid[parentThread.Comment.ReplyUid] as CommentThread;
                //     if (grandparentThread) parentThread = grandparentThread;
                //     else console.warn("Unexpectedly unable to find comment thread for uid=" + parentThread.Comment.ReplyUid);
                // }

                let newThread = new CommentThread(c[i], parentThread.ReplyLevel + 1);
                parentThread.Replies.push(newThread);
                //parentThread.Replies.unshift(newThread); // reverse ordering for replies (oldest-first versus newest first for top-level)
                threadsByUid[c[i].CommentUid] = newThread; // add to threadsByUid for easy lookup

                // remove each comment as it is placed in newThreads
                c.splice(i, 1);
                i--;
            }
        } while(c.length < lastLength);

        //console.log("done sorting threads!");
        //console.log(newThreads);

        // warn about any non-matched replies (orphaned, parent deleted?)
        for (let i = 0; i < c.length; i++)
        {
            console.warn("Failed to match comment replyUid to a commentUid for uid=" + c[i].ReplyUid);
        }

        this.commentThreads = newThreads;
    }

}


