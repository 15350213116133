<div class="content">
  <div class="child-content">
    <div *ngFor="let st of this.settings">
      <div class="section" *ngIf="st.Setting.Section">
        <span class="title">{{st.Setting.Section}}</span>
        <hr>
      </div>
      <div class="setting">
        <span class="title">{{st.Setting.Title}}</span>
        <span class="desc">{{st.Setting.Description}}</span>
        <div *ngIf="st.Setting.Type === 'text'">
          <mat-form-field appearance="fill" *ngIf="st.Setting.Max > 50">
            <textarea matInput [formControl]="st.FormControl" minlength="{{st.Setting.Min}}" maxlength="{{st.Setting.Max}}" (change)="this.onSave(st)">{{st.Setting.Value}}</textarea>
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
            <mat-hint align="end">{{st.Setting.Value.length}} / {{st.Setting.Max}}</mat-hint>
            <mat-error>{{st.Error}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" *ngIf="st.Setting.Max <= 50">
            <input matInput [formControl]="st.FormControl" minlength="{{st.Setting.Min}}" maxlength="{{st.Setting.Max}}" (change)="this.onSave(st)" />
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint align="end">{{st.Setting.Value.length}} / {{st.Setting.Max}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
            <mat-error>{{st.Error}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="st.Setting.Type === 'url'">
          <mat-form-field appearance="fill">
            <input matInput type="url" [formControl]="st.FormControl" maxlength="{{st.Setting.Max}}" placeholder="tmsoft.com" (change)="this.onSave(st)"/>
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint align="end">{{st.Setting.Value.length}} / {{st.Setting.Max}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
            <mat-error>{{st.Error}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="st.Setting.Type == 'bool' || st.Setting.Type == 'boolean'">
          <span>
            <mat-slide-toggle hideIcon [(ngModel)]="st.Checked" (ngModelChange)="this.onChange(st, $event)" color="primary" (change)="this.onSave(st)"></mat-slide-toggle>
            <br/>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
          </span>
        </div>
        <div *ngIf="st.Setting.Type === 'list'">
          <mat-form-field appearance="fill">
            <mat-select [formControl]="st.FormControl" (selectionChange)="this.onSave(st)">
              <mat-option *ngFor="let o of this.getOptions(st)" [value]="o"> {{o}}</mat-option>
            </mat-select>
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="st.Setting.Type === 'radio'">
          <mat-radio-group appearance="fill" [formControl]="st.FormControl" (change)="this.onSave(st)">
            <mat-radio-button *ngFor="let o of this.getOptions(st)" color="primary" [value]="o" [checked]="st.Setting.Value == o">{{o}}</mat-radio-button>
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
          </mat-radio-group>
        </div>
        <div *ngIf="st.Setting.Type === 'number'">
          <mat-form-field class="number" appearance="fill">
            <input matInput type="number" [formControl]="st.FormControl" min="{{st.Setting.Min}}" max="{{st.Setting.Max}}" (change)="this.onSave(st)" />
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
            <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
            <mat-error>{{st.Error}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="st.Setting.Type === 'range'">
          <mat-slider discrete color="primary" min="{{st.Setting.Min}}" max="{{st.Setting.Max}}" step="{{st.Setting.Step}}"><input matSliderThumb />
            <input matSliderThumb [formControl]="st.FormControl" (change)="this.onSave(st)" />
          </mat-slider>
          <mat-hint class="error" align="start" *ngIf="st.FormControl.disabled">{{st.Error}}</mat-hint>
        </div>
        <div *ngIf="st.Setting.Type === 'delete'">
          <button class="action" mat-flat-button color="warn" routerLink="/account/delete">{{st.Setting.Title}}</button>
        </div>
        <div *ngIf="st.Setting.Type === 'avatar'">
          <div class="avatar">
            <img class="rounded" src="{{getAvatarUrl(st)}}" alt="Avatar"><br/>
            <mat-hint class="status" align="start" *ngIf="st.Status">{{st.Status}}</mat-hint>
          </div>
          <button class="action" mat-flat-button color="primary" (click)="onAvatar(st)">Select Avatar</button>
        </div>
      </div>
    </div>
    <hr>
    <div class="footer" *ngIf="this.footer">
      <div class="progress">
        <mat-spinner [diameter]="16" *ngIf="this.saving"></mat-spinner>
        <span class="status" *ngIf="this.saveMsg">{{this.saveMsg}}</span>
        <span class="error" *ngIf="this.saveError">{{this.saveError}}</span>
      </div>
      <div class="buttons">
        <button class="button" mat-flat-button color="primary" (click)="this.onSaveDirty()" [disabled]="!this.isDirty()">Save</button>
        <button class="button" mat-flat-button color="cancel" (click)="this.onReset()" [disabled]="!this.isDirty()">Reset</button>
      </div>
    </div>
  </div>
</div>