import { Component, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';

import { faFacebook, faGoogle} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// firebase backed auth compatibility with market
import { MarketAuth } from './marketauth.service';

// login dialog
@Component({
    selector: 'login-dialog',
    templateUrl: 'login.dialog.html',
    styleUrls: ['login.dialog.css']
})

export class LoginDialog
{
    constructor(public dialogRef: MatDialogRef<LoginDialog>,
        private marketAuth: MarketAuth) { }

    @ViewChild('tabGroup', { static: true }) tabGroup : MatTabGroup;

    email: string;
    password: string;
    password2: string;

    info: string;
    error: string;
    working = false;

    disableCreate = false;
    showReset = false;
    disableReset = false;

    // icons used
    facebookIcon = faFacebook;
    googleIcon = faGoogle;
    emailIcon = faEnvelope;

    login(service: string)
    {
        if (this.working)
        {
            console.log("Login already in progress");
            return;
        }

        //console.log("Login requested: " + service);
        this.working = true;
        this.error = null;

        // create user data
        let serviceData = {};
        if (service === 'email') {
             // validate we have everything for login
            if (this.validateEmailData(1) == false) {
                return;
            }

            serviceData = { email: this.email, password: this.password };
        }

        console.log("Logging in with service:", service, "service data:", serviceData);

        this.working = true;
        this.marketAuth.login(service, serviceData).then(result =>
        {
            console.log("Login result: " + result);
            this.working = false;
            this.dialogRef.close(result);
        })
        .catch(err => {
            this.handleError(err)
        });
    }

    validateEmailAddress(email : string) : boolean
    {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // validate form data at specified level (0=email only, 1=include password, 2=includes passwords)
    validateEmailData(level : number) : boolean
    {
        // reset existing info and error before checking
        this.info = null;
        this.error = null;

        // check if already doing an operation
        if (this.working)
        {
            console.error("Login already in progress");
            return;
        }
        if (this.email == null || this.email.length == 0 || this.validateEmailAddress(this.email) == false)
        {
            this.error = "Enter in a valid e-mail address";
            return false;
        }
        if (level >= 1 && (this.password == null || this.password.length < 8))
        {
            this.error = "Enter a valid password with at least 8 characters";
            return false;
        }
        if (level >= 2 && this.password !== this.password2)
        {
            this.error = "Passwords do not match";
            return false;
        }
        // okay
        return true;
    }

    // firebase email login and creation
    createEmail()
    {
        console.log("Create Email", this.email, this.password, this.password2);

        // validate we have everything for creating an account
        if (this.validateEmailData(2) == false)
        {
            return;
        }

        this.working = true;
        let serviceData = { email: this.email, password: this.password };
        this.marketAuth.createEmail(serviceData).then(res => {
            console.log("Create Email Success", res);
            res.user.sendEmailVerification();
            this.error = null;
            this.info = "Account has been created. Check your e-mail and follow the instructions to verify your account before logging in.";
            this.working = false;
            this.password = null;
            this.password2 = null;
            this.disableCreate = true;
            this.showEmail();

        }).catch(err => this.handleError(err));
    }

    showEmail()
    {
        this.tabGroup.selectedIndex = 1;
    }

    showSignup()
    {
        this.tabGroup.selectedIndex = 2;
    }

    showResetPassword()
    {
        this.showReset = true;
        this.tabGroup.selectedIndex = 3;
    }

    resetPassword()
    {
        console.log("Reset Email", this.email, this.password);

        // validate we have everything for email reset
        if (this.validateEmailData(0) == false)
        {
            return;
        }

        this.working = true;
        let serviceData = { email: this.email };
        this.marketAuth.resetEmail(serviceData).then(res => {
            console.log("Reset Email Success", res);
            this.working = false;
            this.disableReset = true;
            this.info = "Password has been reset.  Check your e-mail and follow the instructions to set a new password.";
            this.showEmail();

        }).catch(err => this.handleError(err));
    }

    handleError(err : any)
    {
        // pull out the most specific error message possible (market returns error.Message and firebase returns error.message)
        var msg : string = null;
        if (err.error)
        {
            msg = err.error.Message || err.error.message;
        }
        if (msg == null)
        {
            msg = err.Message || err.message || err.statusText;
        }
        if (msg == null)
        {
            msg = "Unknown";
        }

        console.error("Login Error", msg);
        this.info = null;
        this.working = false;
        this.error = msg;
    }
}
