import { Component, AfterViewInit } from '@angular/core'

@Component({
    selector: 'ad-adsense-download',
    template: `<ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-8934170658251200"
    data-ad-slot="6467337062"
    data-ad-format="auto"
    data-full-width-responsive="true">White Noise Market</ins>`,
})

export class AdAdSenseDownloadComponent implements AfterViewInit 
{
    constructor() {}

    ngAfterViewInit() 
    {
        try 
        {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            console.log("Loaded ads by google for download ad");
        }
        catch (e) 
        {
            console.error("Ad Download Error", e);
        }
    }
}