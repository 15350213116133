import { Component } from '@angular/core';

import { MetaService } from './services/meta.service';

@Component({
    selector: 'market-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./content.css'],
    standalone: false
})
export class TermsComponent
{
  constructor(private metaService: MetaService) 
  {
    const title = "Terms and Conditions";
    const description = "Terms and Conditions for using the White Noise Market";
    const image = "";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}
