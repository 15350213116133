import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// services
import { MarketService } from './services/market.service';

// config dialog
@Component({
    selector: 'config-dialog',
    templateUrl: 'config.dialog.html',
    styleUrls: ['config.dialog.css'],
    standalone: false
})

export class ConfigDialog
{
    constructor(public dialogRef: MatDialogRef<ConfigDialog>,
                private market: MarketService,
                @Inject(MAT_DIALOG_DATA) data : any) 
                { 
                    // initialize
                    if (data.name) this.name = data.name;
                    if (data.value) this.value = data.value;
                    if (data.title) this.title = data.title;
                    if (data.description) this.description = data.description;
                    if (data.placeholder) this.placeholder = data.placeholder;
                    if (data.maxlength) this.maxlength = data.maxlength;

                    // save original value
                    this.original = this.value;
                }

    // dialog distplay
    title: string = "Change Value";
    description: string = "Change your value and then tap the Apply button.";
    placeholder: string = "Enter Value"

    // configuration
    name: string = "";
    value: string = "";

    // value length
    maxlength: number = 25;

    // tracking original value
    original: string = "";
    
    // feedback
    info: string;
    error: string;
    working = false;

    // commit value change
    commit() 
    {
        if (this.name == null || this.name === "")
        {
            console.log("Invalid configuration given to dialog");
            return;
        }

        if (this.working) 
        {
            console.log("Set config already in progress");
            return;
        }

        this.working = true;
        this.error = null;

        var config = {};
        config[this.name] = this.value;
        console.log("Set user configuration:", config);

        this.market.setUserConfig( config )
                   .then(result => {
                       console.log("Set config result: " , result);
                       this.dialogRef.close(result);
                   })
                   .catch(err => this.handleError(err));
    }

    handleError(msg : any)
    {
        this.info = null;
        this.working = false;
        this.error = "Error: " + msg;
    }

}
