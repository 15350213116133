// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line
{
    min-height: 56px;
}

.picture
{
    display: inline-block;
    min-width: 52px;
    text-align: left;
}

.name
{
    display: inline-block;
    min-width: 125px;
    text-align: left;
}

.stat
{
    display: inline-block;
    min-width: 80px;
    text-align: left;
    margin-left: 10px;
}

.stats-icon
{
    vertical-align: middle;
    font-size: 24px;
    padding-right: 5px;
}

.spinner
{
    margin:0 auto;
}

.users
{
    margin-left: 20px;
}

.spacer
{
    width: 300px;
    padding: 0 24px;
    margin: 0 10px;
    height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/users.component.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,WAAW;AACf","sourcesContent":[".line\r\n{\r\n    min-height: 56px;\r\n}\r\n\r\n.picture\r\n{\r\n    display: inline-block;\r\n    min-width: 52px;\r\n    text-align: left;\r\n}\r\n\r\n.name\r\n{\r\n    display: inline-block;\r\n    min-width: 125px;\r\n    text-align: left;\r\n}\r\n\r\n.stat\r\n{\r\n    display: inline-block;\r\n    min-width: 80px;\r\n    text-align: left;\r\n    margin-left: 10px;\r\n}\r\n\r\n.stats-icon\r\n{\r\n    vertical-align: middle;\r\n    font-size: 24px;\r\n    padding-right: 5px;\r\n}\r\n\r\n.spinner\r\n{\r\n    margin:0 auto;\r\n}\r\n\r\n.users\r\n{\r\n    margin-left: 20px;\r\n}\r\n\r\n.spacer\r\n{\r\n    width: 300px;\r\n    padding: 0 24px;\r\n    margin: 0 10px;\r\n    height: 0px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
