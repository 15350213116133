import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, of, from } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, catchError, map } from "rxjs/operators";

import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { Sound } from '../services/sound';
import { MarketService } from '../services/market.service';
import { MetaService } from '../services/meta.service';
import { environment } from '../../environments/environment';

// keep track of all our geo queries
export interface GeoQuery
{
  lat: number;
  lng: number;
  dist: number;
  zoom: number;
  sounds: number;
}

@Component({
    selector: 'market-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    standalone: false
})

export class MapComponent
{
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) markerWindow: MapInfoWindow;
  @ViewChildren(MapInfoWindow) markerWindows: QueryList<MapInfoWindow>;
  apiLoaded: Observable<boolean>;

  title = "World Map of White Noise Market Sounds";
  subtitle = "White Noise recordings uploaded with geolocation data are displayed on the world map.";
  sounds: Sound[] = [];//Observable<Sound[]>;
  soundLimit = 10000;
  queryLimit = 100;

  // useful info for distance verifiation:
  // the united states is 2802 miles or 4,509,382 from florida to washington
  // the earth is 40,075,020 meters round
  // google map zoom goes from 0 to 22 but only 4 to 15 are appropriate for our use
  minZoom: number = 4;
  maxZoom: number = 15;
  minDistance: number = 2000;     // 2000 meters is pretty small distance
  maxDistance: number = 20000000; // half the planet
  maxSounds: number = 100;        // server limit per query
  mapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: false,
    mapTypeControl: false,
    fullscreenControl: false
  };

  markerOptions: google.maps.MarkerOptions = {
    optimized: true
  };

  // google maps starting position and zoom level
  center: google.maps.LatLngLiteral = {lat: 33.88961582334383, lng: -98.50509315};
  zoom = 4;

  // querying
  globalQuery = false; // have we run the global geo query?
  query : GeoQuery; // current active query
  queries: GeoQuery[] = []; // executed queries
  latLngSearch = new Subject<google.maps.LatLngBounds>();

  // misc
  debug = false;
  working = false;

  constructor(httpClient: HttpClient,
              public marketService: MarketService,
              private metaService: MetaService)
  {
    // If you're using the `<map-heatmap-layer>` directive, you also have to include the `visualization` library
    // when loading the Google Maps API. To do so, you can add `&libraries=visualization` to the script URL:
    // https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=visualization
    this.setBusy(true);

    const apiUrl = "https://maps.googleapis.com/maps/api/js?key=" + environment.gmap;
    this.apiLoaded = httpClient.jsonp(apiUrl, 'callback')
    .pipe(
      map(() => {
        console.log("Map Initialized.");
        this.setBusy(false);
        this.initialQuery();
        return true;
      },
      catchError((err: any) => {
        console.log("Map Err: " + err);
        this.setBusy(false);
        return of(false);
      }),
    ));

    const title = "World Map of Sounds";
    const description = "Map of White Noise sound recordings uploaded by users from around the world.";
    const image = "https://img.tmsoft.com/market/map.jpg";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  initialQuery(): void {
      var soundsObs = this.latLngSearch.pipe(debounceTime(200), distinctUntilChanged(), switchMap(latLng => this.requerySounds(latLng)));
      soundsObs.subscribe(sounds => {
      if (sounds != null)
      {
        // go through sounds from server and add ones that are not dups
        var added = 0;
        for (let i=0; i < sounds.length; i++)
        {
          if (!this.sounds.find((val: Sound, ix: number, arr: Sound[]) => val.Uid == sounds[i].Uid))
          {
            this.sounds.push(sounds[i]);
            ++added;
          }
        }

        // save the query that executed to our list
        if (this.query)
        {
          this.debugLog("Query returned " + sounds.length + " sounds (new: " + added + ") for new total: " + this.sounds.length);
          this.query.sounds = sounds.length;
          this.storeQuery(this.query);
          this.query = null;
        }
      }
      this.setBusy(false);
    });

    // tm: already seems to send first search without this
    // send first search
    //setTimeout(() => this.latLngSearch.next(), 1);
  }

  centerMap(event: google.maps.MapMouseEvent)
  {
    this.center = (event.latLng.toJSON());
    this.debugLog("Center = " + JSON.stringify(this.center));
  }

  mapMoved($event : any)
  {
    let bounds = this.map.getBounds();
    this.zoom = this.map.getZoom();
    if (bounds && this.zoom)
    {
      this.debugLog("Map moved with bounds: " + JSON.stringify(bounds));
      this.latLngSearch.next(bounds); // add next search to observable stream
    }
  }

  showMarkerInfo(marker: MapMarker, index: number, sound: any)
  {
    this.debugLog("Clicked sound " + index + ": " + sound.Uid + " marker: " + marker);
    this.markerWindows.forEach((window: MapInfoWindow, ix: number) =>
    {
      if (index === ix) {
        window.open(marker);
      } else {
        window.close();
      }
    });
  }

  // all EMPTY returns were changed to null observables for this reason:
  // https://stackoverflow.com/questions/38548407/return-an-empty-observable
  requerySounds(latLng: google.maps.LatLngBounds) : Observable<Sound[]>
  {
    this.debugLog("Requerying sounds for bounds: " + JSON.stringify(latLng));
    
    // if already querying do not execute another
    if (this.isBusy())
    {
      // return observable of null so the subscribe will ignore
      this.debugLog("Already querying map api.");
      return of(<Sound[]>(null));
    }

    if (!latLng)
    {
      // return observable of null so the subscribe will ignore
      this.debugLog("Not valid bounds");
      return of(<Sound[]>(null));
    }

    // once we've received 1000 sounds or done a 100 queries don't do anymore
    if ( (this.soundLimit > 0 && this.sounds.length >= this.soundLimit) || 
         (this.queryLimit > 0 && this.queries.length >= this.queryLimit) )
    {
      this.debugLog("Too many sounds or queries performed.");
      return of(<Sound[]>(null));
    }

    // setup for new query
    var newQuery : GeoQuery =
    {
      lat: latLng.getCenter().lat(),
      lng: latLng.getCenter().lng(),
      dist: this.getRadiusOfBounds(latLng),
      zoom: this.getZoom(),
      sounds: 0
    };

    // check if we should run
    var runQuery = this.shouldRunQuery(newQuery);
    this.debugLog("Geo is lat: " + newQuery.lat + " long: " + newQuery.lng + " radius: " + newQuery.dist + " zoom: " + newQuery.zoom + " run: " + runQuery);

    // check if we should run query or not
    if (!runQuery)
    {
      // return observable of null so the subscribe will ignore
      return of(<Sound[]>(null));
    }

    // set that we are working to animate progress bar
    this.setBusy(true);

    // save the query which will be used to store results (or set global query as being executed)
    this.query = newQuery;

    // empty cache key means global query
    if (this.isGlobalQuery(this.query))
    {
      // run global query
      this.debugLog("Executing global query");
      return from(this.marketService.getGeo());
    }
    else
    {
      // run lat/long query with distance
      this.debugLog("Executing geo query");
      return from(this.marketService.getGeo(this.query.lat, this.query.lng, this.query.dist));
    }
  }

  // get zoom capped to our zoom threshold
  getZoom(): number
  {
    if (this.zoom <= this.minZoom) return this.minZoom;
    if (this.zoom >= this.maxZoom) return this.maxZoom;
    return this.zoom;
  }

  // get distance radius of map view from center to corner and capped to our distance threshold
  getRadiusOfBounds(bounds: google.maps.LatLngBounds): number
  {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var dist = this.measure(ne.lat(), ne.lng(), sw.lat(), sw.lng());

    // cap distance to min and max ranges
    if (dist < this.minDistance) dist = this.minDistance;
    if (dist > this.maxDistance) dist = this.maxDistance;

    // dist calculated from corner to corner, so return half for dist-from-center
    return dist * 0.5;
  }

  // https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
  measure(lat1: number, lon1: number, lat2: number, lon2: number): number
  {
    const R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon/2) * Math.sin(dLon/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d * 1000; // meters
  }

  isGlobalQuery(geo: GeoQuery) : boolean
  {
    return (geo.zoom <= this.minZoom || geo.dist >= this.maxDistance);
  }

  shouldRunQuery(geo: GeoQuery): boolean
  {
    // check if this is a global query
    if (this.isGlobalQuery(geo))
    {
      return !this.globalQuery;
    }

    // check query array
    for (let i = 0; i < this.queries.length; ++i)
    {
      var query = this.queries[i];

      // measure distance from geo to existing queries
      var dist = this.measure(geo.lat, geo.lng, query.lat, query.lng);
      var contained = (dist <= query.dist);
      //console.log("Geo point " + geo.lat + ", " + geo.lng + " compared to query circle " + query.lat + ", " + query.lng + " dist: " + query.dist + " contained: " + contained);

      // if we are at the same zoom level do not execute the query if contained
      if (contained && geo.zoom == query.zoom)
      {
        this.debugLog("- Already queried this area at same zoom level");
        return false;
      }

      // if we are zoomed further in then we do not execute if contained and not at max returned sounds (ie--could find more by zooming in)
      if (contained && geo.zoom > query.zoom && query.sounds < this.maxSounds)
      {
        this.debugLog(" - Should not find any sounds even though we are zoomed in more");
        return false;
      }

    }
    return true;
  }

  storeQuery(geo: GeoQuery)
  {
    if (this.isGlobalQuery(geo))
    {
      this.globalQuery = true;
      this.debugLog("Saved global query");
    }
    else
    {
      // add to our array of queries
      this.queries.push(geo);
      this.debugLog("Saved geo query for total of " + this.queries.length);
    }
  }

  private isBusy(): boolean {
    return this.working;
  }

  private setBusy(working: boolean): void {
    if (this.working != working) {
      this.debugLog("Working state changed: " + working);
      this.working = working;
    }
  }

  private debugLog(message: string): void {
    if (this.debug) {
      console.log(message);
    }
  }

  lazyLoaded(sound : Sound): void
  {
    console.log("Sound image loaded: " + sound.Label);
  }
}
