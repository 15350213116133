<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title><strong>White Noise Market App</strong></mat-card-title>
    <mat-card-subtitle>Download the Social Network of Sounds for FREE!</mat-card-subtitle>    
  </mat-card-header>
  <mat-card-content></mat-card-content>
</mat-card>
<div class="content">
    <div class="column-layout">

        <div class="padding">

            <h2>Free App Download</h2>
            <p>
                Download the White Noise Market App and experience the social network of sounds on your Android or iOS device.
                It's much faster than using your mobile browser.  It's a FREE download too!
            </p>
            <p style="text-align:center">
                <a href="http://app.tmsoft.com/whitenoisemarket/?apple" target="_blank"><img src="/assets/images/download-app-store.png" alt="Apple App Store" style="max-width: 95%"></a>
                <a href="http://app.tmsoft.com/whitenoisemarket/?google" target="_blank"><img src="/assets/images/download-google-play.png" alt="Google Play Store" style="max-width: 95%"></a>
                <!--<a href="http://app.tmsoft.com/whitenoisemarket/?amazon" target="_blank"><img src="/assets/images/download-amazon.png" alt="Amazon Appstore" style="max-width: 95%"></a>-->
            </p>

            <h2>Social Network of Sounds</h2>
            <p>
              The White Noise Market native app is the best way to download and share relaxing sounds with the
              <a href="http://app.tmsoft.com/whitenoise/" target="_blank">White Noise</a>, <a href="http://app.tmsoft.com/whitenoisepro/" target="_blank">White Noise Pro</a>, <a href="http://app.tmsoft.com/whitenoiselite/" target="_blank">White Noise Lite</a>, and <a href="http://app.tmsoft.com/whitenoisebaby/" target="_blank">White Noise Baby</a> apps.
              Upload your existing White Noise recordings and mixes to share your audio experiences with the entire White Noise community.
              Join us in helping others relax during the day and sleep great at night!
            </p>

            <p>
              Discover thousands of White Noise Market sounds and download them into White Noise for free.
              Browse by featured sounds, recent sounds, or by sound category.
              Check out the latest chatter to see what sound everyone is talking about.
              Upload your own audio recordings from your unique part of the world.
              Navigate the world map to find unique sounds or search by keyword to find the perfect sound.
              Leave comments and start a conversation about the sounds you enjoy the most.
              Sounds will be quickly downloaded in the background and made available to all of our White Noise apps.
              New sounds are posted daily so check back often and enable notifications to be the first to download our weekly featured sounds.
            </p>

          <h2>App Features</h2>

          <ul>
            <li>Quickly download new sounds directly to <a href="http://app.tmsoft.com/whitenoise/" target="_blank">White Noise</a>, <a href="http://app.tmsoft.com/whitenoisepro/" target="_blank">White Noise Pro</a>, <a href="http://app.tmsoft.com/whitenoiselite/" target="_blank">White Noise Lite</a>, and <a href="http://app.tmsoft.com/whitenoisebaby/" target="_blank">White Noise Baby</a>.</li>
            <li>Upload recordings and mixes to share your audio experience with the entire community.</li>
            <li>Discover thousands of sounds users are talking about with the Chatter view.</li>
            <li>Push notifications when weekly featured sounds are available for download or users respond to your comments.</li>
            <li>Today widget can be enabled to show you the 3 most recent featured sounds.</li>
            <li>Explore specific sound categories such as ASMR, Binaural Beats, Nature, Music, Meditation, and more!</li>
            <li>Discover unique sounds from around the world by navigating the world map of recordings.</li>
            <li>Make friends by starting a conversation with other White Noise users.  Heart and post comments about your favorite sounds and mixes.</li>
            <li>Preview sounds before you download and share your favorites.</li>
            <li>Stream or purchase sounds at popular digital stores including Spotify, Apple Music, Google Play Music, iTunes, and more.</li>
            <li>Supports Portrait/Landscape orientations and the latest iOS and Android phones and tablets.</li>
          </ul>

          <p>
          Thanks for being a part of our growing White Noise community!
          </p>
        </div>
        <p style="text-align:center">
          <a href="http://app.tmsoft.com/whitenoisemarket/" target="_blank">
            <img src="/assets/images/white-noise-market-app-portrait.png" alt="White Noise Market App" style="max-width: 95%">
          </a>
        </p>
    </div>
  </div>