import { Component, OnInit } from '@angular/core';
import { MarketService } from './market.service';
import { MarketAuth } from './marketauth.service';

@Component({
     selector: 'market-token',
  templateUrl: './token.component.html',
    styleUrls: ['./token.component.css']
})

export class TokenComponent implements OnInit
{
    title: string;
    content: string = "";    
    token: string;

    constructor(private marketService: MarketService,
                private marketAuth: MarketAuth)
    {
    }

    ngOnInit(): void
    {
        // if browser is going directly to search location then set the search bar text to value
        this.token = location.pathname.substring(7);

         // single route param subscribe:
         console.log("Initializing token: " + this.token);
         this.setToken(this.token);         
    }

    setToken(token : string)
    {
        if (token === "test")
        {
            this.title = "Run Test";
            this.content = "";
            this.runTest();
        }
        else if (token === "me")
        {
            this.title = "My Token";
            this.content = this.marketAuth.getToken();
        }
        else
        {            
            this.title = "Set Token";
            this.content = token;
            return this.marketAuth.setToken(token);
        }
    }

    runTest()
    {
        this.marketService.getMeProfile()
                          .then(result => { this.content = "Result: " + JSON.stringify(result); console.log("Result:", result)})
                          .catch(err => this.handleError(err));
    }

    handleError(error : any) : void
    {
        // on error set an empty array and working to false
        this.title = "Error";
        this.content = error;
    }

}
