import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform 
{
  transform(value: string, ...args: string[]): string
  {
    if (!value) return value;
    let limit = args.length > 0 ? parseInt(args[0], 10) : 50;
    let trail = args.length > 1 ? args[1] : '…';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

