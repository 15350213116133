/* src/app/notifications.component.css */
.mat-column-Note {
  vertical-align: top;
  padding: 10px;
  width: 100%;
}
.mat-column-Image {
  vertical-align: top;
  padding: 10px;
}
table tr:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.row-image {
  width: 64px;
  height: 64px;
}
.row-message {
  font-weight: normal;
}
.row-date {
  padding-top: 10px;
  color: #888;
}
/*# sourceMappingURL=notifications.component.css.map */
