<h2 mat-dialog-title>
  <i class="fa fa-shopping-cart"></i> Add to Cart
</h2>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

<mat-dialog-content>
  <div>
    <p>Select the desired duration for your {{sound.Label}} MP3 and add it to your cart.</p>
    <mat-form-field *ngIf="!working">
      <mat-label>Hours</mat-label>
      <mat-select style="width: auto;" required="true" [(value)]="selectedItem">
        <mat-option *ngFor="let item of selectItems" [value]="item">{{item.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button style="color: white; background-color: #3744AF;" (click)="onAddToCart()">Add</button>
  <button mat-button mat-dialog-close style="color: white; background-color: #A0A0A0;" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>