import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { Sound } from '../../sound';
import { TMApiService } from '../tmapi.service';

@Component({
    selector: 'cart-add',
    templateUrl: 'cart-add.dialog.html',
})
export class CartAddDialog implements OnInit
{
    public sound: Sound;
    public working = true;
    soundData: any[];
    priceData: any[];
    fileData: any[];
    selectItems = new Array(10);
    selectedItem: any;

    constructor(
        private tmapiService: TMApiService,
        private dialogRef: MatDialogRef<CartAddDialog>)
    {
    }

    ngOnInit(): void
    {
        this.working=true;
        this.tmapiService.getSound(this.sound.Uid)
                          .then(data => this.setData(data))
                          .catch(err => this.setError(err));
    }

    onAddToCart(): void
    {
        this.working = true;
        let soundId = this.sound.Uid;
        let duration = this.selectedItem["value"];
        this.tmapiService.addCartItem(soundId, duration)
                          .then(data => this.finishAdd(data))
                          .catch(err => this.setError(err));
    }

    onCancel(): void
    {
        this.dialogRef.close(-1);
    }

    finishAdd(data: any): void
    {
        this.working=false;
        let result = {
            "cart": data,
            "value": this.selectedItem["value"]
        }
        // auto close dialog
        this.dialogRef.close(result);
    }

    setData(data: any[])
    {
        this.working = false;
        this.soundData = data["Sounds"][0];
        this.priceData = data["Pricing"];
        this.fileData = JSON.parse(this.soundData["FileInfo"]);
        if (this.fileData && this.fileData.length > 0)
        {
            for (var i=0; i < this.fileData.length; i++)
            {
                let info = this.fileData[i];
                let duration = info.duration; // assuming durations are pre-sorted in db and indexes match sizes
                let size = info.size; // assuming size is going to be in the hundreds of Mbs
                let price = this.tmapiService.getPrice(this.priceData, duration);

                let hours = duration / 60 / 60;
                let hoursLabel = "" + hours + (hours==1 ? " Hour" : " Hours");
                let priceLabel = "$"+Number(price).toFixed(2);
                let sizeLabel = (size/1024/1024).toFixed(0)+"MB";
                let label = hoursLabel + " - "+ sizeLabel + " ("+priceLabel+")";
                this.selectItems[i] = { "value": duration, "label": label };
            }
        }
        else
        {
            // up to 10 hours for the select options if no duration or sizes specified
            for (var i=0; i < 10; i++) 
            {
                let hours = i+1;
                let hoursLabel = "" + hours + (hours==1 ? " Hour" : " Hours");
                let priceLabel = "$-.--";
                let label = hoursLabel + " ("+priceLabel+")";
                this.selectItems[i] = { "value": hours*60*60, "label": label };
            }
        }

        // set default selected item
        if (this.selectItems.length > 0)
        {
            this.selectedItem = this.selectItems[0];
        }
    }

    setError(err: any)
    {
        console.error("Error during query: " + err);
        this.working = false;
    }
}
