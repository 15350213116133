<mat-progress-bar mode="indeterminate" *ngIf="this.working"></mat-progress-bar>

<!-- not logged in -->
<div class="main" *ngIf="!this.authorized">
    <div class="center">
        <p>You must be logged in to view your page.</p>
        <button mat-raised-button color="primary" type="submit" class="login-button" (click)="this.marketAuth.showLogin()">Login</button>
    </div>
</div>
<!-- logged in -->
<div class="main" *ngIf="this.authorized">
    <!-- header -->
    <mat-card appearance="outlined" *ngIf="user">
        <mat-card-header>
            <mat-card-title><strong>{{user.displayName}}</strong></mat-card-title>
            <mat-card-subtitle>{{user.userDescription || "No profile description"}}<br><a *ngIf="user.userWebsite" [href]="user.userWebsite | weblink" target="_blank">{{user.userWebsite | displaylink}}</a></mat-card-subtitle>                
            <div mat-card-avatar *ngIf="user.avatarUrl">
                <app-avatar [url]="user.avatarUrl" [size]=42 [isAdmin]="user && user.isAdmin" [isSubscriber]="user && user.isSubscriber" [isModerator]="user && user.isModerator"></app-avatar>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div class="user-content" *ngIf="user && user.stats">
                <br>
                <div><small><strong>MY STATS</strong></small></div>           
                <span class="nowrap">
                    <span class="stat-user" title="Zen" (click)="showMessage('Total zen earned by ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="diamond" aria-hidden="true"></mat-icon> {{user.stats.zen || 0 | number}}</span>
                    <span class="stat-user" title="Total Uploads" (click)="showMessage('Total sounds uploaded by ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="upload" aria-hidden="true"></mat-icon> {{user.stats.myUploads || 0 | number}}</span>
                    <span class="nowrap" *ngIf="user && user.stats && user.stats.myUploads > 0">
                        <span class="stat-user" title="Hearts received for user's sounds and comments" (click)="showMessage('Total hearts received by ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="favorite" aria-hidden="true"></mat-icon> {{user.stats.hearts || 0 | number}}</span>
                        <span class="stat-user" title="Comments written about user's uploaded sounds" (click)="showMessage('Total comments written to ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="comment" aria-hidden="true"></mat-icon> {{user.stats.comments || 0 | number}}</span>
                        <span class="stat-user" title="Downloads of user's uploaded sounds" (click)="showMessage('Total downloads of sounds by ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="download" aria-hidden="true"></mat-icon> {{user.stats.downloads || 0 | number}}</span>
                        <span class="stat-user" title="Minutes listened of user's uploaded sounds" (click)="showMessage('Total minutes listened for sounds by ' + user.displayName)"><mat-icon class="material-icons-dark stats-icon" fontIcon="headphones" aria-hidden="true"></mat-icon> {{user.stats.minutes || 0 | number}}</span>
                    </span>
                </span>
            </div>
            <div class="user-content" *ngIf="badges && badges.length > 0">
                <br>
                <div><small><strong>MY BADGES</strong></small></div>
                <span class="nowrap">                    
                    <span *ngFor="let badge of badges">
                        <img class="badge" [src]="badge.ImageUrl" [title]="badge.Label" [alt]="badge.Label" width="32" height="32" (click)="showMessage(badge.Label + ' Badge earned by ' + user.displayName + ' (' + badge.Description + ')')">&nbsp;
                    </span>
                </span>
            </div>
            <div class="user-content" *ngIf="this.user && this.user.userId">                
                <br>
                <div><small><strong>SHARE MY PUBLIC PROFILE</strong></small></div>            
                <div>
                    <share-buttons
                        [include]="['x','facebook','pinterest','reddit','copy']"
                        title="Check out my White Noise Sounds"
                        [description]=""
                        [url]="this.marketService.userLink(user.userId)"
                        [image]="this.marketService.imageLink(user.AvatarUrl, 0)"
                        [showIcon]="true"
                        [showText]="true">
                    </share-buttons>
                </div>     
            </div>
        </mat-card-content>
    </mat-card>
    <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">rss_feed</mat-icon>
                My Feed
            </ng-template>            
            <app-sounds query="feed" empty="Follow users and categories to populate your feed."></app-sounds>                        
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">mic</mat-icon>
                My Sounds
            </ng-template>
            <app-sounds query="user" [modify]="true" [param]="this.user.userId" empty="Use White Noise and White Noise Market mobile apps to record and upload sounds.  You can also upload WNA files exported from the White Noise app or created with the White Noise Creator desktop application."></app-sounds>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">favorite</mat-icon>
                My Favorites
            </ng-template>
            <div class="padded-content">
                <h3>Categories</h3>
                <hr>
                <div class="empty" *ngIf="favTags.length == 0">
                    <span>No favorites.</span>
                </div>
                <div class="small-flex" *ngIf="favTags.length > 0">
                    <mat-card appearance="outlined" class="small" *ngFor="let tag of favTags" routerLink="/tag/{{tag.Value}}">
                        <mat-card-content>
                            <img mat-card-avatar [src]="tag.ImageUrl" [alt]="tag.Label">
                            <span class="title">{{tag.Label}}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <h3>Users</h3>
                <hr>
                <div class="empty" *ngIf="favUsers.length == 0">
                    <span>No favorites.</span>
                </div>
                <div class="small-flex" *ngIf="favUsers.length > 0">
                    <mat-card appearance="outlined" class="small" *ngFor="let u of favUsers" routerLink="/user/{{u.userId}}">
                        <mat-card-content>
                            <img mat-card-avatar [src]="u.avatarUrl" [alt]="u.displayName" (error)="onAvatarError($event.target);">
                            <span class="title">{{u.displayName}}</span>
                            <p *ngIf="u.stats">
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="diamond" aria-hidden="true"></mat-icon> {{u.stats.zen | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="upload" aria-hidden="true"></mat-icon> {{u.stats.myUploads | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="favorite" aria-hidden="true"></mat-icon> {{u.stats.hearts | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="comment" aria-hidden="true"></mat-icon> {{u.stats.comments | shortnumber:0}}</span>
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <h3>Sounds</h3>
                <hr>
                <div class="empty" *ngIf="favSounds.length == 0">
                    <span>No favorites.</span>
                </div>
                <div class="small-flex" *ngIf="favSounds.length > 0">
                    <mat-card appearance="outlined" class="small" *ngFor="let s of favSounds" (click)="goSound(s)">
                        <mat-card-content>
                            <img mat-card-avatar [src]="s.ImageUrl" [alt]="s.Label">
                            <span class="title">{{s.Label}}</span>
                            <p *ngIf="s.Stats">
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="favorite" aria-hidden="true"></mat-icon> {{s.Stats.Hearts | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="comment" aria-hidden="true"></mat-icon> {{s.Stats.Comments | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="download" aria-hidden="true"></mat-icon> {{s.Stats.Downloads | shortnumber:0}}</span>
                                <span class="stat"><mat-icon class="material-icons-dark stats-icon" fontIcon="headphones" aria-hidden="true"></mat-icon> {{s.Stats.Minutes | shortnumber:0}}</span>
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">notifications</mat-icon>
                Notifications
            </ng-template>
            <market-notifications></market-notifications>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">settings</mat-icon>
                Settings
            </ng-template>
            <account-settings [user]="this.user" [settings]="this.settings"></account-settings>
        </mat-tab>
    </mat-tab-group>                 
</div>