/* src/app/comments.component.css */
.avatar {
  width: 64px;
  position: relative;
  display: inline-block;
  padding-top: 16px;
}
.avatar-image {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  flex-shrink: 0;
}
.heart-comment-icon {
  vertical-align: middle;
}
.comments-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.comment-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.comment-top {
  padding-left: 20px;
  margin-bottom: 10px;
  flex-grow: 1;
}
.comment-bottom {
  padding-top: 10px;
  padding-right: 0px;
  flex-grow: 1;
}
.pull-right {
  float: right !important;
}
.close-button {
  background-color: #fff;
  border: #fff;
}
.stats {
  display: inline-flex;
  vertical-align: middle;
  line-height: 20px;
}
.stat {
  min-width: 30px;
  text-align: left;
  padding-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
}
.reltime {
  text-align: right;
  float: right !important;
  color: grey;
  padding-top: 10px;
}
.comment-form {
  width: 100%;
  border: 1px solid #CCC;
  padding: 10px 6px;
  margin-right: 10px;
  display: flex;
}
.comment-full-width {
  display: inline-block;
  flex: 1 1 auto;
  margin-right: 12px;
}
.reply-button {
  padding: 0px 12px 0px 4px;
  position: relative;
  top: 5px;
  width: 50px;
  height: 28px;
  font-size: 90%;
}
.reply-div span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.hide-display {
  display: none;
}
.replies {
  margin-left: 30px;
  padding-left: 5px;
  border-left: 1px solid #DDD;
}
/*# sourceMappingURL=comments.component.css.map */
