import { Component } from '@angular/core';

// services
import { MetaService } from './services/meta.service';

@Component({
    selector: 'market-home',
    templateUrl: './home.component.html',
    standalone: false
})

export class HomeComponent
{
  constructor(private metaService: MetaService) 
  {
      // meta data
      const title = "White Noise Sounds";
      const description = "Download the latest free sounds to White Noise for iOS, Android, Mac, and Windows. Purchase and stream sounds on Amazon Music, Apple Music, Spotify, and more.  Official Market for White Noise Sounds by TMSOFT.";
      const image = "";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}