// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar
{
    position: relative;
    display: inline-block;
    padding: 10%;
    line-height: normal;
}
.avatar-image
{
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
}
.avatar-badge
{
    position: absolute;
    right: 0px;
    top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/avatar/avatar.component.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;AACvB;AACA;;IAEI,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;AACA;;IAEI,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ","sourcesContent":[".avatar\n{\n    position: relative;\n    display: inline-block;\n    padding: 10%;\n    line-height: normal;\n}\n.avatar-image\n{\n    border-radius: 50%;\n    vertical-align: middle;\n    cursor: pointer;\n}\n.avatar-badge\n{\n    position: absolute;\n    right: 0px;\n    top: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
