/* src/app/store/catalog/catalog.component.css */
.store-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.store-buttons button {
  margin-left: 10px;
  min-width: 150px;
}
.content {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 20vh;
}
.center-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.table-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
table {
  width: 100%;
}
.mat-column-image {
  cursor: pointer;
  padding: 4px;
  width: 64px;
  height: 64px;
}
.item-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 1px;
  vertical-align: middle;
}
.mat-column-sound {
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}
.mat-column-preview {
  width: 80px;
  text-align: center;
  vertical-align: center;
}
.mat-column-cart {
  width: 80px;
  text-align: center;
  vertical-align: center;
}
.muted {
  font-size: small;
  color: #999;
}
.no-border {
  border-bottom-style: none;
}
audio {
  max-width: 125px;
}
.icon {
  vertical-align: middle;
  color: white;
}
.button {
  cursor: pointer;
  border: none;
  display: inline-block;
  font-size: 9pt;
  height: 35px;
  color: white;
  background-color: #3744AF;
}
/*# sourceMappingURL=catalog.component.css.map */
