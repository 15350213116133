/* src/app/map/map.component.css */
.map-container {
  width: 100%;
  height: 100%;
}
.content {
  padding: 20px;
}
.footer {
  text-align: center;
}
/*# sourceMappingURL=map.component.css.map */
