import { Component } from '@angular/core';
import { MetaService } from './meta.service';

@Component({
  selector: 'market-home',
  templateUrl: './home.component.html'
})

export class HomeComponent
{
  constructor(private metaService: MetaService) 
  {
      // meta data
      const title = "White Noise Sounds by TMSOFT - White Noise Market";
      const description = "Download the latest free sounds to White Noise for iOS, Android, Mac, and Windows. Purchase and stream sounds at Spotify, Apple Music, Google Play Music, Amazon, and more.  Official Market for White Noise Sounds by TMSOFT.";
      const image = "/assets/images/white-noise-market.png";

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }
}