<h2 mat-dialog-title *ngIf="title">
    {{title}}
</h2>
<mat-dialog-content>
    {{message}}
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-evenly">
    <button mat-raised-button color="primary" [mat-dialog-close]="true" style="margin: 4px 0">
        {{yesLabel}}
    </button>
    <button mat-raised-button color="warn" [mat-dialog-close]="false" style="margin: 4px 0">
        {{noLabel}}
    </button>
</mat-dialog-actions>
