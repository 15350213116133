import { Component, AfterViewInit } from '@angular/core'

@Component({
    selector: 'ad-adsense-header',
    template: `<ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-8934170658251200"
    data-ad-slot="1636857060"
    data-ad-format="auto"
    data-full-width-responsive="true"></ins>`,
    standalone: false
})

export class AdAdSenseHeaderComponent implements AfterViewInit 
{
    constructor() {}

    ngAfterViewInit() 
    {
        try 
        {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            console.log("Loaded ads by google for header ad");
        }
        catch (e) 
        {
            console.error("Ad Header Error", e);
        }
    }
}