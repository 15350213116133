import { Injectable, Injector } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MarketService } from './market.service';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

  // subscribe to latest message and token (behavior subject always emits on subscribe)
  currentMessage = new BehaviorSubject(null);
  currentToken = new BehaviorSubject(null);
  topics: string[]; // list of topics that are subscribed (saved in cookies)

  // api status (browser unsupported, browser blocked, refresh of token received data)
  unsupported = false;
  blocked = false;
  refreshed = false;

  constructor(
    private push: AngularFireMessaging,
    private inj: Injector) 
  {

    // load saved topics from cookies
    this.topics = this.loadTopics();
  }

  /**
   * update token in firebase database
   *
   * @param token token as a value
   */
  updateToken(token: string)
  {
    //console.log('Firebase Token Updated', userId, token);
    this.blocked = false;
    this.currentToken.next(token);
  }

  haveToken(): boolean 
  {
    let token = this.currentToken.getValue();
    return (token && token.length > 0);
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(): Promise<string>
  {
    return new Promise<string>((resolve, reject) => {
      this.push.requestToken.subscribe({
        next: (token) => {
          this.updateToken(token);
          return resolve(token);
        },
        error: (err) => {
          this.handleError("permission", err);
          return reject(err);
        },
        complete: () => {
          // Optionally handle the completion of the observable if needed
        }});
      });
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage()
  {
    this.push.messages.subscribe({
      next: (payload) => {
        this.blocked = false;
        //console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      },
      error: (err) => this.handleError("messages", err),
      complete: () => {
        // Optionally handle the completion of the observable if needed
      }
    });
  }

  /**
   * check if we already have a token
   */
  refreshToken()
  {
    //console.log("Refreshing push token");
    
    // reset refreshed and blocked
    this.refreshed = false;
    this.blocked = false;

    // try grabbing the token (with or without permission)
    this.push.getToken.subscribe({
      next: (payload) => {
        this.refreshed = true;
        this.blocked = false;
        //console.log("new token received. ", payload);
        this.currentToken.next(payload);
      },
      error: (err) => {
        this.refreshed = true;
        this.handleError("token", err);
      },
      complete: () => {
        // Optionally handle the completion of the observable if needed
      }
    });
  }

  isSubscribedToTopic(topic: string): boolean 
  {
    if (!topic || topic.length == 0) 
    {
      return false;
    }
    return this.topics.includes(topic);
  }

  showSubscribeSnack(topic: string) 
  {
    let snack = this.inj.get(MatSnackBar);
    snack.open("Enable notifications for followed categories and users?", "ENABLE", { duration: 30000, verticalPosition: "bottom" }).onAction().subscribe(action => 
    {
      this.requestPermission()
          .then( token => 
          {
            if (token && token.length > 0) 
            {
              this.subscribeTopicServer(topic);
            }
        });
    });
  }

  subscribeToTopic(topic: string) 
  {
    if (!topic || topic.length == 0) 
    {
      return;
    }

    if (this.topics.includes(topic) === false) 
    {
      console.log('Subscribing to topic: ', topic);

      // store the topic in cookies so the UI can update
      this.topics.push(topic);
      this.saveTopics(this.topics);

      // request permission if we don't have a token
      if (!this.haveToken()) 
      {
        this.showSubscribeSnack(topic);
      } 
      else 
      {
        // the server must subscribe on our behalf
        this.subscribeTopicServer(topic);
      }
    }
  }

  unsubscribeFromTopic(topic: string) 
  {
    if (!topic || topic.length == 0) 
    {
      return;
    }
    
    if (this.topics.includes(topic)) 
    {
      console.log('Unsubscribe from topic: ', topic);
      
      // store the topic in cookies so the UI can update
      this.topics = this.topics.filter( t => t != topic);
      this.saveTopics(this.topics);

      /// the server must unsubscribe on our behalf
      this.unsubscribeTopicServer(topic);
    }
  }

  private subscribeTopicServer(topic: string) 
  {
    // if we have no topic the server can't do anything
    if (!topic || topic.length == 0) 
    {
      return;
    }

      // if we have no token the server can't do anything
    let token = this.currentToken.getValue();
    if (!token || token.length == 0) 
    {
      return;
    }

    let market = this.inj.get(MarketService);
    market.subscribeTopic(token, topic)
          .then( (result) => console.log(`Firebase topic [${topic}] subscribe result: `, result))
          .catch( err => console.error(`Firebase topic [${topic}] subscribe failed: `, err));
  }

  private unsubscribeTopicServer(topic: string) 
  {
    // if we have no topic the server can't do anything
    if (!topic || topic.length == 0) 
    {
      return;
    }

    // if we have no token the server can't do anything
    let token = this.currentToken.getValue();
    if (!token || token.length == 0) 
    {
      return;
    }

    let market = this.inj.get(MarketService);
    market.unsubscribeTopic(token, topic)
          .then( (result) => console.log(`Firebase topic [${topic}] unsubscribe result: `, result))
          .catch( err => console.error(`Firebase topic [${topic}] unsubscribe failed: `, err));
  }

  private saveTopics(topics: string[]) 
  {
    if (topics) 
    {
      localStorage.setItem('messaging-topics', topics.join(','));
    }
  }

  private loadTopics(): string[] 
  {
    let topicStr = localStorage.getItem('messaging-topics');
    if (!topicStr) 
    {
      topicStr = "";
    }
    return topicStr.length > 0 ? topicStr.split(',') : [];
  }

  handleError(type: string, err : any)
  {
    // track blocked and unsupported error codes
    if (err.code == "messaging/notifications-blocked")
    {
      console.warn('Firebase messaging is blocked on ' + type + ' call.');
      this.blocked = true;
    }
    else if (err.code == "messaging/unsupported-browser")
    {
      console.warn('Firebase messaging is unsupported in this browser.');
      this.unsupported = true;
    }
    else
    {
      console.error('Firebase ' + type + ' error: ', err);
    }
  }
}