import { Injectable, Injector } from '@angular/core';
import { AngularFireRemoteConfig, Value } from '@angular/fire/compat/remote-config';
import { environment } from '../../environments/environment';

@Injectable()
export class FirebaseService 
{
    fbConfig: AngularFireRemoteConfig = null;
    configActive: boolean = false;

    constructor(private inj: Injector) 
    {
        this.fbConfig = this.inj.get(AngularFireRemoteConfig);
    }

    private async initConfig(): Promise<boolean>
    {
        if (this.configActive)
        {
            // already active
            return true;
        }

        try 
        {
            this.configActive = await this.fbConfig.fetchAndActivate();
            if (environment.logging)
            {
                console.log("Firebase: RemoteConfig fetched and activated!");
            }
            return true;
        } 
        catch (err) 
        {
            this.handleError("config", err);
            return false;
        }
    }

    private async getRemoteValue(key: string): Promise<any>
    {
        if (await this.initConfig()) 
        {
            return await this.fbConfig.getValue(key);
        }
        return null;
    }

    public async getRemoteBool(key: string, def: boolean): Promise<boolean>
    {
        let value = await this.getRemoteValue(key);
        return value?.asBoolean() || def;
    }

    public async getRemoteNumber(key: string, def: number): Promise<number>
    {
        let value = await this.getRemoteValue(key);
        return value?.asNumber() || def;
    }

    public async getRemoteString(key: string, def: string): Promise<string>
    {
        let value = await this.getRemoteValue(key);
        return value?.asString() || def;
    }

    private handleError(type: string, err : any): void
    {
        if (type === "config")
        {
            console.error("Firebase: RemoteConfig fetch failed:", err);
            this.configActive = false;
        }
    }
}