<!-- Adding Comments -->
<div>
    <form class="comment-form" [ngClass]="{ 'hide-display' : !!repliesSent[''] }" (ngSubmit)="addComment(null)">

        <mat-form-field class="comment-full-width">
            <input matInput maxlength="500" placeholder="Add Comment" name="message" [(ngModel)]="message" [disabled]="adding">
            <mat-hint align="start"><strong>Write a comment about this sound</strong> </mat-hint>
            <mat-hint align="end">{{message.trim().length}} / 500</mat-hint>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit" [disabled]="message.trim().length < 10" style="height: 36px; margin: 16px auto 0px auto;">Comment</button>
    </form>
</div>
<br><br>

<div class="comments-container">

<!-- Display Threaded Comments (with a Recursive Template) -->
    <ng-template #commentThread let-replies>
        <div *ngFor="let ct of replies">
            <div class="comment-content">
                <div>
                    <app-avatar [url]="ct.Comment.AvatarUrl" [size]=64 [uid]="ct.Comment.UserUid" [description]="ct.Comment.DisplayName" [isAdmin]="ct.Comment.IsAdmin" [isSubscriber]="ct.Comment.IsSubscriber" [isModerator]="ct.Comment.IsModerator"></app-avatar>
                </div>
                <div class="comment-top">
                    <strong>{{ct.Comment.DisplayName}}</strong>
                    <span *ngIf="marketAuth.user | async; let user">
                        <span *ngIf="!ct.Comment.IsAdmin && user.userId == ct.Comment.UserUid">
                            <i class="fa fa-user badge-user avatar-badge" title="You"></i>
                        </span>
                        <button class="pull-right close-button" title="Delete Comment" *ngIf="user.isAdmin || user.isModerator || user.userId == ct.Comment.UserUid" (click)="removeComment(ct.Comment.CommentUid)">
                            <mat-icon fontIcon="cancel" aria-hidden="true"></mat-icon>
                        </button>
                    </span>
                    <span *ngIf="ct.Comment.IsAdmin">
                        <span innerHtml=" {{ ct.Comment.Message | linkify }}"></span>
                    </span>
                    <span *ngIf="!ct.Comment.IsAdmin">
                        {{ ct.Comment.Message | linkifynone }}
                    </span>
                    <br>
                    <div class="comment-bottom">
                        <div class="nowrap">
                            <mat-icon class="material-icons heart-noselect heart-comment-icon" fontIcon="favorite_border" aria-hidden="true" *ngIf="!marketAuth.isAuthenticated() || !ct.Comment.UserHeart" (click)="toggleHeart(ct.Comment)"></mat-icon>
                            <mat-icon class="material-icons heart-select heart-comment-icon" fontIcon="favorite" aria-hidden="true" *ngIf="marketAuth.isAuthenticated() && ct.Comment.UserHeart" (click)="toggleHeart(ct.Comment)"></mat-icon>
                            <span *ngIf="ct.Comment.Hearts > 0" class="arrow_box">{{ct.Comment.Hearts}}</span>
                        </div>
                        <button mat-button color="primary" class="reply-button" [ngClass]="{ 'hide-display' : !!repliesSent[ct.Comment.CommentUid] }" (click)="showReplyComment(commentReply)">
                            <div class="reply-div">
                                <mat-icon class="material-icons" fontIcon="reply" aria-hidden="false"></mat-icon>
                                <span>Reply</span>
                            </div>
                        </button>
                        <div class="reltime">
                            <small>{{ct.Comment.RelTime}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <form #commentReply class="comment-form hide-display" (ngSubmit)="addComment(ct.Comment)">

                <mat-form-field class="comment-full-width">
                    <input matInput maxlength="500" placeholder="Reply to Comment" name="replyMessage" [(ngModel)]="replyMessage" [disabled]="adding">
                    <mat-hint align="start"><strong>Write a reply to this comment</strong> </mat-hint>
                    <mat-hint align="end">{{replyMessage.trim().length}} / 500</mat-hint>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit" [disabled]="replyMessage.trim().length < 10" style="height: 36px; margin: auto;">Comment</button>
            </form>
            <br/>
            <div *ngIf="ct.Replies.length > 0" [ngClass]="{ replies : ct.ReplyLevel < 2}">
                <ng-container *ngTemplateOutlet="commentThread; context: { $implicit: ct.Replies }"></ng-container>
            </div>
        </div>
    </ng-template>


<!-- Display Top-level Comments (using Template above) -->
    <ng-container *ngTemplateOutlet="commentThread; context: { $implicit: commentThreads }"></ng-container>
</div>