import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/fire';
import { VERSION } from '@angular/fire';
import { ɵfirebaseAppFactory, ɵcacheInstance, ɵlazySDKProxy, FIREBASE_OPTIONS, FIREBASE_APP_NAME, ɵapplyMixins } from '@angular/fire/compat';
import { isSupported } from 'firebase/messaging';
import { EMPTY, of, Observable, concat } from 'rxjs';
import { subscribeOn, observeOn, switchMap, map, shareReplay, switchMapTo, catchError, mergeMap, defaultIfEmpty } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/messaging, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
    deleteToken: null,
    getToken: null,
    onMessage: null,
    onBackgroundMessage: null,
};

const VAPID_KEY = new InjectionToken('angularfire2.messaging.vapid-key');
const SERVICE_WORKER = new InjectionToken('angularfire2.messaging.service-worker-registeration');
class AngularFireMessaging {
    requestPermission;
    getToken;
    tokenChanges;
    messages;
    requestToken;
    deleteToken;
    constructor(options, name, 
    // eslint-disable-next-line @typescript-eslint/ban-types
    platformId, zone, schedulers, vapidKey, _serviceWorker) {
        const serviceWorker = _serviceWorker;
        const messaging = of(undefined).pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(isSupported), switchMap(supported => supported ? import('firebase/compat/messaging') : EMPTY), map(() => ɵfirebaseAppFactory(options, zone, name)), switchMap(app => ɵcacheInstance(`${app.name}.messaging`, 'AngularFireMessaging', app.name, () => {
            return of(app.messaging());
        }, [])), shareReplay({ bufferSize: 1, refCount: false }));
        this.requestPermission = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => Notification.requestPermission()));
        this.getToken = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(async (messaging) => {
            if (Notification.permission === 'granted') {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                const serviceWorkerRegistration = serviceWorker ? await serviceWorker : null;
                return await messaging.getToken({ vapidKey, serviceWorkerRegistration });
            }
            else {
                return null;
            }
        }));
        const notificationPermission$ = new Observable(emitter => {
            navigator.permissions.query({ name: 'notifications' }).then(notificationPerm => {
                notificationPerm.onchange = () => emitter.next();
            });
        });
        const tokenChange$ = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMapTo(notificationPermission$), switchMapTo(this.getToken));
        this.tokenChanges = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => concat(this.getToken, tokenChange$)));
        this.messages = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(messaging => new Observable(emitter => messaging.onMessage(emitter))));
        this.requestToken = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => this.requestPermission), catchError(() => of(null)), mergeMap(() => this.tokenChanges));
        this.deleteToken = () => messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(messaging => messaging.deleteToken()), defaultIfEmpty(false));
        return ɵlazySDKProxy(this, messaging, zone);
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessaging, deps: [{ token: FIREBASE_OPTIONS }, { token: FIREBASE_APP_NAME, optional: true }, { token: PLATFORM_ID }, { token: i0.NgZone }, { token: i1.ɵAngularFireSchedulers }, { token: VAPID_KEY, optional: true }, { token: SERVICE_WORKER, optional: true }], target: i0.ɵɵFactoryTarget.Injectable });
    static ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessaging, providedIn: 'any' });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessaging, decorators: [{
            type: Injectable,
            args: [{
                    providedIn: 'any'
                }]
        }], ctorParameters: () => [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [FIREBASE_OPTIONS]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [FIREBASE_APP_NAME]
                }] }, { type: Object, decorators: [{
                    type: Inject,
                    args: [PLATFORM_ID]
                }] }, { type: i0.NgZone }, { type: i1.ɵAngularFireSchedulers }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [VAPID_KEY]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [SERVICE_WORKER]
                }] }] });
ɵapplyMixins(AngularFireMessaging, [proxyPolyfillCompat]);

class AngularFireMessagingModule {
    constructor() {
        firebase.registerVersion('angularfire', VERSION.full, 'fcm-compat');
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessagingModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessagingModule });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessagingModule, providers: [AngularFireMessaging] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.6", ngImport: i0, type: AngularFireMessagingModule, decorators: [{
            type: NgModule,
            args: [{
                    providers: [AngularFireMessaging]
                }]
        }], ctorParameters: () => [] });

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFireMessaging, AngularFireMessagingModule, SERVICE_WORKER, VAPID_KEY };

