// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.header {
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

.card 
{
    width: 128px;
    max-width: 80vw;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
    outline: blue;
    cursor: pointer;
}

mat-dialog-content {
    width: 100%;
    height: 90%;
}

mat-dialog-content img {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px;
    outline: blue;
    cursor: pointer;
}
.tiles-flex
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/avatar/avatars.component.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;AACnD;;AAEA;;IAEI,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;AACA;;IAEI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":["div.header {\n    font-family: Roboto,\"Helvetica Neue\",sans-serif;\n}\n\n.card \n{\n    width: 128px;\n    max-width: 80vw;\n    display: inline-block;\n    margin: 10px;\n    vertical-align: top;\n    outline: blue;\n    cursor: pointer;\n}\n\nmat-dialog-content {\n    width: 100%;\n    height: 90%;\n}\n\nmat-dialog-content img {\n    display: inline-block;\n    width: 80px;\n    height: 80px;\n    border-radius: 50%;\n    margin: 10px;\n    outline: blue;\n    cursor: pointer;\n}\n.tiles-flex\n{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
